import { useQuery } from '@tanstack/react-query';
import { AgenciesService } from 'src/Services/API';
import { QueryKeys } from '../types';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';

export const useFetchRelatedCGC = () => {
  const agencyId = useSelector(getCurrentAgency);

  return useQuery(
    [QueryKeys.fetchRelatedCGC],
    () => {
      return AgenciesService.agenciesControllerGetRelatedCgc({ id: agencyId });
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};
