import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import {
  ArchiveATReason,
  WorkAccidentAnalysis,
  WorkAccidentAnalysisService,
} from 'src/Services/API';
import { MutationKeys, QueryKeys } from '../types';

export const useUpdateArchiveStatusWorkAccidentAnalysis = (
  extraOptions?: UseMutationOptions<
    WorkAccidentAnalysis,
    unknown,
    { archiveComment?: string; archiveReason?: ArchiveATReason; analysisId: string },
    unknown
  >
) => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.archiveWorkAccidentAnalysis],
    async (params: {
      archiveComment?: string;
      archiveReason?: ArchiveATReason;
      analysisId: string;
    }) => {
      return await WorkAccidentAnalysisService.workAccidentAnalysisControllerUpdateArchiveStatusWorkAccidentById(
        {
          id: params.analysisId,
          body: {
            archivedComment: params.archiveComment,
            archivedReason: params.archiveReason,
          },
        }
      );
    },
    {
      ...extraOptions,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchWorkAccidentById] });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchWorkAccidents] });
      },
    }
  );
};
