import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ExternalDemandsState } from './Types';
import { AppelMedicalEventSourceDto } from '../../Services/API';

const initialState: ExternalDemandsState = {
  selectedSource: AppelMedicalEventSourceDto.HUBLO,
};

export const { reducer: externalDemandsReducer, actions: externalDemandsActions } = createSlice({
  name: 'externalDemands',
  initialState,
  reducers: {
    setSelectedSource: (state, action: PayloadAction<AppelMedicalEventSourceDto>) => {
      state.selectedSource = action.payload;
    },
  },
});
