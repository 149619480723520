import styles from './TopBar.module.scss';
import { TopBarProps } from './TopBar.types';

import { GoBackArrow } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { PropsWithChildren, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PerimeterSelectionModal } from 'src/Modals/PerimeterSelectionModal';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';

export const TopBar = ({
  children,
  title,
  goBackTo,
  disablePerimeterSelection,
}: PropsWithChildren<TopBarProps>) => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentBrand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency);

  const [isPerimeterModalOpen, setIsPerimeterModalOpen] = useState(false);

  return (
    <div className={styles.container}>
      {goBackTo && (
        <div className={styles.goBackContainer}>
          <button
            className={styles.goBack}
            onClick={() => {
              if (location.key === 'default') navigate(goBackTo);
              else navigate(-1);
            }}
          >
            <GoBackArrow />
          </button>
        </div>
      )}
      <div className={styles.titleContainer}>
        {!disablePerimeterSelection && (
          <div className={styles.brandName}>
            flash /{' '}
            <button className={styles.agencySelector} onClick={() => setIsPerimeterModalOpen(true)}>
              {currentBrand.name} - {currentAgency}
            </button>
            <PerimeterSelectionModal
              open={isPerimeterModalOpen}
              onClose={() => setIsPerimeterModalOpen(false)}
            />
          </div>
        )}
        <div className={styles.title}>{title}</div>
      </div>
      {children}
    </div>
  );
};
