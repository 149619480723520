import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { ThunkAPI } from 'src/Redux/RootReducer';
import { CompaniesService } from 'src/Services/API';
import { ServiceState } from './Types';

export const fetchServices = createAsyncThunk<ServiceState['services'], string, ThunkAPI>(
  'services/fetch',
  async (companyId, thunkAPI) => {
    const state = thunkAPI.getState();

    const brandCode = getCurrentBrandCode(state);

    return CompaniesService.companiesControllerGetServices({ id: companyId, brandCode });
  }
);
