import { FETCH_STATUS } from 'src/Redux/Types';

/**
 * @deprecated If you need to merge fetch statuses you are doing something wrong
 */
export function mergeFetchStatus(...statusArray: FETCH_STATUS[]): FETCH_STATUS {
  if (statusArray.includes(FETCH_STATUS.REJECTED)) {
    return FETCH_STATUS.REJECTED;
  }
  if (statusArray.includes(FETCH_STATUS.PENDING)) {
    return FETCH_STATUS.PENDING;
  }
  return FETCH_STATUS.FULFILLED;
}

/**
 * @deprecated use `queryResult.isLoading` or something else
 */
export function toFetchStatus(query: { isSuccess: boolean; isError: boolean; isLoading: boolean }) {
  if (query.isSuccess) {
    return FETCH_STATUS.FULFILLED;
  }
  if (query.isLoading) {
    return FETCH_STATUS.PENDING;
  }
  if (query.isError) {
    return FETCH_STATUS.REJECTED;
  }
  return FETCH_STATUS.FULFILLED;
}

/**
 * @deprecated If you need to merge fetch statuses you are doing something wrong
 */
export function mergeSeveralFetchStatus(statusArray: FETCH_STATUS[]): FETCH_STATUS {
  if (statusArray.includes(FETCH_STATUS.REJECTED)) {
    return FETCH_STATUS.REJECTED;
  }
  if (statusArray.includes(FETCH_STATUS.PENDING)) {
    return FETCH_STATUS.PENDING;
  }
  return FETCH_STATUS.FULFILLED;
}
