import { useQuery } from '@tanstack/react-query';
import { RepositoryService } from 'src/Services/API';

import { QueryKeys } from '../types';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import invariant from 'invariant';

export const useFetchInjuryLocationDetails = (injuryLocationId?: string) => {
  const brandCode = useSelector(getCurrentBrandCode);

  return useQuery(
    [QueryKeys.fetchInjuryLocationDetails, injuryLocationId],
    async () => {
      invariant(injuryLocationId, 'injuryLocationId is missing');
      return await RepositoryService.repositoryControllerGetInjuryLocationDetails({
        brandCode,
        injuryLocationId,
      });
    },
    {
      enabled: Boolean(injuryLocationId),
    }
  );
};
