import {
  Auth0ContextInterface,
  GetTokenSilentlyOptions,
  IdToken,
  RedirectLoginOptions,
} from '@auth0/auth0-react';
import React, { createContext, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/Routes/Routes.types';

const initialContext = {
  isAuthenticated: true,
  isLoading: false,
  user: {
    given_name: process.env.REACT_APP_FAKE_AUTH_USER_GIVEN_NAME,
    family_name: process.env.REACT_APP_FAKE_AUTH_USER_GIVEN_FAMILY_NAME,
    profile: process.env.REACT_APP_FAKE_AUTH_USER_GIVEN_SIID,
    email: process.env.REACT_APP_FAKE_AUTH_USER_GIVEN_EMAIL,
  },
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => {
    const response = {
      id_token: 'id_token',
      access_token: 'access_token',
      expires_in: 1234,
    };

    // we return Promise<any> as seen on the real implementation of the original getAccessTokenSilently
    // as overloading type is too tricky for typescript
    // (cf. getAccessTokenSilently type in Auth0ContextInterface for more info)
    return Promise.resolve(options?.detailedResponse ? response : 'token') as Promise<any>;
  },
  logout: async () => {
    console.log('logout');
    return Promise.resolve();
  },
  loginWithRedirect: async (options?: RedirectLoginOptions) => {
    console.log('loginWithRedirect', options);
    return Promise.resolve();
  },
  getIdTokenClaims: () => Promise.resolve({ __raw: 'fake_id_token' } as IdToken),
};

export const MockedAuthContext = createContext<
  Pick<
    Auth0ContextInterface,
    | 'getAccessTokenSilently'
    | 'getIdTokenClaims'
    | 'logout'
    | 'isAuthenticated'
    | 'isLoading'
    | 'loginWithRedirect'
    | 'user'
    | 'error'
  >
>(initialContext);

export const MockedAuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const logout = useCallback(async () => {
    setIsAuthenticated(false);
    navigate(ROUTES.ROOT.buildPath({}));
  }, [navigate]);

  const loginWithRedirect = useCallback(async (options?: RedirectLoginOptions) => {
    setIsAuthenticated(true);
  }, []);

  return (
    <MockedAuthContext.Provider
      value={{ ...initialContext, isAuthenticated, logout, loginWithRedirect }}
    >
      {children}
    </MockedAuthContext.Provider>
  );
};
