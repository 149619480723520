import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { useInfiniteQuery } from '@tanstack/react-query';
import { QueryKeys } from '../types';
import { CandidatesService, FetchTemporaryWorkersRegionalParams } from 'src/Services/API';

export const useFetchRegionalTemporaryWorkers = (
  params: Omit<FetchTemporaryWorkersRegionalParams, 'page' | 'brandCode' | 'agency'>
) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);

  return useInfiniteQuery(
    [QueryKeys.fetchRegionalTemporaryWorkers, brandCode, agencyId, params],
    async ({ pageParam = 0 }) => {
      return await CandidatesService.candidatesControllerFetchRegionalTemporaryWorkers({
        body: { brandCode, agency: agencyId, page: pageParam, ...params },
      });
    },
    {
      getNextPageParam: page => page.nextPage,
    }
  );
};
