import moment from 'moment';
import { WithMoment } from 'src/Redux/Types';

export function convertPropertyToMoment<T extends Record<string, any>, K extends keyof T>(
  obj: T,
  ...keys: K[]
): WithMoment<T, K> {
  const copy = {
    ...obj,
  } as any;
  keys.forEach(key => {
    copy[key] = copy[key] && moment(copy[key]);
  });
  return copy as WithMoment<T, K>;
}
