import { Marker, NavigateDown } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Button,
  Chip,
  Popover,
  PopoverActions,
  Slider,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useRef, useState } from 'react';
import styles from './DistancePopover.module.scss';
import { DISTANCE_VALUES, Props } from './DistancePopover.types';

export const DistancePopover = ({ distance, setDistance }: Props) => {
  const [localDistance, setLocalDistance] = useState(
    DISTANCE_VALUES.includes(distance) ? DISTANCE_VALUES.findIndex(d => d === distance) : 4
  );

  const ref = useRef<PopoverActions>(null);

  return (
    <Popover
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      trigger={
        <Chip size="large" rounded={false} selected>
          <Marker variant="fill" />
          rayon -{' '}
          {distance?.toString() ?? Math.max(...DISTANCE_VALUES.filter(Boolean)).toString() + '+ '}
          km
          <NavigateDown />
        </Chip>
      }
      className={styles.popover}
      onOpen={() =>
        setLocalDistance(
          DISTANCE_VALUES.includes(distance) ? DISTANCE_VALUES.findIndex(d => d === distance) : 4
        )
      }
      ref={ref}
    >
      <WithLightTitle title="dans un rayon de">
        <Slider
          min={-0.5}
          max={7.5}
          defaultValue={0}
          value={localDistance}
          onChange={(_, value) => {
            setLocalDistance(value as number);
          }}
          marks={DISTANCE_VALUES.map((value, index) => ({
            value: index,
            label:
              value?.toString() ?? Math.max(...DISTANCE_VALUES.filter(Boolean)).toString() + '+',
          }))}
          step={null}
        />
      </WithLightTitle>
      <div className={styles.actions}>
        <Button variant="secondary" size="medium" onClick={() => ref.current?.close()}>
          annuler
        </Button>
        <Button
          size="medium"
          onClick={() => {
            setDistance(DISTANCE_VALUES[localDistance]);
            ref.current?.close();
          }}
        >
          valider
        </Button>
      </div>
    </Popover>
  );
};
