import { BadgeDeprecated, Checkbox } from '@randstad-lean-mobile-factory/react-components-core';
import { IllusEscalier } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useQuery } from '@tanstack/react-query';
import classnames from 'classnames';
import ContentLoader from 'react-content-loader';
import { RepositoryService, Salesphase } from 'src/Services/API';
import { getStatusLabel, getStatusStyle } from 'src/Services/Missions';
import styles from './SelectSalesphase.module.scss';
import { Props } from './SelectSalesphase.types';

const SelectSalesphase = ({ selectedPhases, onSelectionChange }: Props) => {
  const { data, isLoading, isError } = useQuery<Salesphase[]>(
    ['fetchSalesphases'],
    async () => await RepositoryService.repositoryControllerGetSalesphases(),
    { retry: false }
  );

  return (
    <div className={styles.container}>
      {isLoading && (
        <ContentLoader uniqueKey="loadingCard" height="4.75rem" width="100%">
          {[
            { width: '1.5rem', height: '1.5rem', x: '0' },
            { width: '6.9rem', height: '1.5rem', x: '2rem', rx: '9', ry: '9' },
            { width: '1.5rem', height: '1.5rem', x: '10.9rem' },
            { width: '4.2rem', height: '1.5rem', x: '12.9rem', rx: '9', ry: '9' },
            { width: '1.5rem', height: '1.5rem', x: '0', y: '2.5rem' },
            { width: '4.2rem', height: '1.5rem', x: '2rem', rx: '9', ry: '9', y: '2.5rem' },
            { width: '1.5rem', height: '1.5rem', x: '8.2rem', y: '2.5rem' },
            { width: '6.9rem', height: '1.5rem', x: '10.2rem', rx: '9', ry: '9', y: '2.5rem' },
          ].map((props, idx) => (
            <rect key={idx} rx="4" ry="4" {...props} />
          ))}
        </ContentLoader>
      )}
      {isError && (
        <div className={styles.errorContainer}>
          <div className={styles.errorIcon}>
            <IllusEscalier />
          </div>
          impossible de récupérer le référentiel des statuts de commande !
        </div>
      )}
      {data
        ?.filter(salesphase => salesphase.id !== 'F')
        .map(salesphase => (
          <div key={salesphase.id} className={styles.checkbox}>
            <Checkbox
              checked={selectedPhases.findIndex(item => item.id === salesphase.id) > -1}
              onChange={event => {
                const checked = event.target.checked;
                if (checked) {
                  return onSelectionChange([...selectedPhases, salesphase]);
                }
                return onSelectionChange(selectedPhases.filter(item => item.id !== salesphase.id));
              }}
            >
              <BadgeDeprecated
                value={getStatusLabel(salesphase.id)}
                className={classnames(getStatusStyle(salesphase.id))}
              />
            </Checkbox>
          </div>
        ))}
    </div>
  );
};

export default SelectSalesphase;
