import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { CandidateMemo, CandidatesService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchCandidateMemos = (
  candidateId: string,
  extraOptions?: UseQueryOptions<CandidateMemo[], unknown, CandidateMemo[], (string | QueryKeys)[]>
) => {
  return useQuery(
    [QueryKeys.fetchCandidateMemos, candidateId],
    async () => {
      return await CandidatesService.candidatesControllerGetCandidateMemos({ candidateId });
    },
    {
      enabled: Boolean(candidateId),
      ...extraOptions,
    }
  );
};
