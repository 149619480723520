import styles from './AgencyCard.module.scss';
import { Button, Checkbox, Table } from '@randstad-lean-mobile-factory/react-components-core';
import { Props } from './AgencyCard.types';
import { CGCAgencyPropositionModal } from '../CGCAgencyPropositionModal';
import { useState } from 'react';
import { AgencyDetails } from 'src/Services/API';

/** @deprecated we should use a DataTable instead */
export const AgencyCard = ({
  agenciesDetails,
  candidateData,
  selectedAgencies,
  setSelectedAgencies,
  agenciesDistances,
}: Props) => {
  const ppaHeaders = [
    { label: '' },
    { label: "nom de l'unité" },
    { label: 'qualification potentielle' },
    { label: 'adresse unité' },
    { label: 'actions' },
  ];

  const [contactedAgency, setContactedAgency] = useState<AgencyDetails>();

  return (
    <div>
      <Table
        headers={ppaHeaders}
        className={styles.table}
        data={(agenciesDetails ?? [])?.map(agency => {
          const selectedAgencyIds = selectedAgencies.map(agencyItem => agencyItem.id);
          const isSelected = selectedAgencyIds.includes(agency.id);
          const distance = agenciesDistances.find(
            agencyDistance => agencyDistance.agencyId === agency.id
          )?.distance;
          return [
            <div className={styles.checkbox}>
              <Checkbox
                checked={isSelected}
                onClick={() => {
                  isSelected
                    ? setSelectedAgencies(
                        selectedAgencies.filter(agencyItem => agency.id !== agencyItem.id)
                      )
                    : setSelectedAgencies([...selectedAgencies, agency]);
                }}
              />
            </div>,
            <div className={styles.columnCell} key={`${agency.id}1`}>
              <p className={styles.textContainerGrey} key={`${agency.id}1`}>
                {`${agency.brandName ?? ''}`}
              </p>
              <p className={styles.textContainer}>{`${agency.idDecrypted ?? 'non précisé'}`}</p>
            </div>,
            <p className={styles.textContainerQualification} key={`${agency.id}2`}>
              {`pas de qualification pour le moment`}
            </p>,
            <div className={styles.columnCell} key={`${agency.id}3`}>
              <p className={styles.addressContainer}>
                {distance !== undefined && (
                  <>
                    <div className={styles.textContainer}>{distance} km</div>
                    <div className={styles.verticalSeparator} />
                  </>
                )}
                <div className={styles.textContainerCapitalize}>
                  {`${agency.adress ?? 'adresse non renseignée'}`.toLocaleLowerCase()}
                </div>
              </p>
              <p className={styles.textContainer}>
                {`CP ${agency.postalCode ?? 'non renseigné'} - ${
                  agency.city ?? 'ville non renseigné'
                }`}
              </p>
            </div>,
            <Button
              size="medium"
              variant="tertiary"
              onClick={event => {
                event.stopPropagation();
                setContactedAgency(agency);
              }}
            >
              contacter l'agence
            </Button>,
          ];
        })}
      />
      <CGCAgencyPropositionModal
        candidateData={candidateData}
        selectedAgencies={contactedAgency ? [contactedAgency] : []}
        open={Boolean(contactedAgency)}
        onClose={() => setContactedAgency(undefined)}
      />
    </div>
  );
};
