import { Marker, NavigateDown } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  AutoSuggest,
  Button,
  Chip,
  Menu,
  MenuActions,
  MenuItem,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useRef, useState } from 'react';
import { Department } from 'src/Services/API';
import { pluralFormat } from 'src/Utils/pluralFormat';
import styles from './DepartmentsPopover.module.scss';
import { DepartmentsPopoverProps } from './DepartmentsPopover.types';

export const DepartmentsPopover = ({
  allDepartments,
  departmentsFetchStatus,
  selectedDepartments,
  setSelectedDepartments,
}: DepartmentsPopoverProps) => {
  const [localDepartments, setLocalDepartments] = useState(selectedDepartments);
  const [searchResults, setSearchResults] = useState<Department[]>([]);

  const ref = useRef<MenuActions>(null);

  return (
    <Menu
      className={styles.menu}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      trigger={
        <Chip size="large" rounded={false} selected={selectedDepartments.length > 0}>
          <Marker variant="fill" />
          {selectedDepartments.length
            ? pluralFormat(selectedDepartments.length, 'département')
            : 'départements'}
          <NavigateDown />
        </Chip>
      }
      onOpen={() => setLocalDepartments(selectedDepartments)}
      ref={ref}
    >
      <WithLightTitle title="sur le département">
        <AutoSuggest
          id="departmentSearch"
          value={localDepartments}
          searchResults={searchResults}
          fetchStatus={departmentsFetchStatus}
          keyValueExtractor={(departments: Department) => ({
            key: departments.id ?? '',
            value: departments.label?.toLowerCase() ?? '',
          })}
          placeholder="ajouter un département"
          onChange={(departments: Department[]) => setLocalDepartments(departments)}
          onSearch={search =>
            setSearchResults(
              allDepartments.filter(
                item =>
                  item.label?.toLowerCase().includes(search.toLowerCase()) &&
                  !selectedDepartments.includes(item)
              )
            )
          }
          disabled={localDepartments.length >= 10}
        />
      </WithLightTitle>
      {localDepartments.map(department => (
        <MenuItem
          keepOpenOnClick
          onClick={() =>
            setLocalDepartments(localDepartments.filter(({ id }) => id !== department.id))
          }
          selected
        >
          {department.label?.toLowerCase()}
        </MenuItem>
      ))}
      <div className={styles.separator} />
      <div className={styles.lightTitle}>raccourcis</div>
      <MenuItem
        keepOpenOnClick
        onClick={() => setLocalDepartments([])}
        selected={localDepartments.length === 0}
      >
        <Marker variant="fill" />
        toute la france
      </MenuItem>
      <div className={styles.actions}>
        <Button variant="secondary" size="medium" onClick={() => ref.current?.close()}>
          annuler
        </Button>
        <Button
          size="medium"
          onClick={() => {
            setSelectedDepartments(localDepartments);
            ref.current?.close();
          }}
        >
          valider
        </Button>
      </div>
    </Menu>
  );
};
