import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { YouplanMissionActionSummaryResponse, YouplanMissionsService } from 'src/Services/API';

export const useUpdateYouplanRelatedMission = (
  extraOptions?: UseMutationOptions<YouplanMissionActionSummaryResponse, unknown, string>
) => {
  const brandCode = useSelector(getCurrentBrandCode);

  return useMutation(async (id: string) => {
    return await YouplanMissionsService.youplanMissionsControllerUpdateRelatedMission({
      id,
      brandCode,
    });
  }, extraOptions);
};
