import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { CandidatesService } from 'src/Services/API';
import { MutationKeys, QueryKeys } from '../types';

interface AddBlacklistedCompanyParams {
  candidateId: string;
  companyId: string;
}

export const useAddBlacklistedCompanyByCandidate = (
  extraOptions?: UseMutationOptions<string, unknown, AddBlacklistedCompanyParams, unknown>
) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MutationKeys.addBlacklistedCompany],
    async (params: AddBlacklistedCompanyParams) => {
      return await CandidatesService.candidatesControllerAddBlacklistedCompany({
        id: params.candidateId,
        body: { companyId: params.companyId },
      });
    },
    {
      ...extraOptions,
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.fetchBlacklistedCompanies]);
      },
    }
  );
};
