import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { CompaniesService, CompanyContact } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCompanyContacts = (
  companyId: string,
  extraOptions?: UseQueryOptions<CompanyContact[], unknown, CompanyContact[], string[]>
) => {
  const brandCode = useSelector(getCurrentBrandCode);

  return useQuery(
    [QueryKeys.fetchCompanyContacts, companyId],
    async () => {
      return CompaniesService.companiesControllerGetContacts({
        id: companyId,
        brandCode,
      });
    },
    {
      enabled: Boolean(companyId),
      ...extraOptions,
    }
  );
};
