import React from 'react';
import classnames from 'classnames';
import ContentLoader from 'react-content-loader';

import { Props } from './Card.types';
import styles from './Card.module.scss';
import Dot from 'src/Components/Dot';
import { DotColor } from 'src/Routes/Activity/Contracts/ListContracts/ListContracts.types';

export const LoadingCard = () => (
  <div className={styles.container}>
    <ContentLoader uniqueKey="loadingCard" height="4.75rem" width="100%">
      {[
        { width: '80%', height: '1.5rem', y: '0' },
        { width: '60%', height: '0.65rem', y: '1.8rem' },
        { width: '60%', height: '0.65rem', y: '2.7rem' },
        { width: '70%', height: '0.75rem', y: '4rem' },
      ].map((props, idx) => (
        <rect key={idx} x="0" rx="4" ry="4" {...props} />
      ))}
    </ContentLoader>
  </div>
);

const Card = ({
  title,
  dotColor,
  borderLeftColor,
  firstSubtitle,
  secondSubtitle,
  description,
  disabled,
  topRightComponent,
  className,
  flag,
}: Props) => (
  <div
    className={classnames(
      styles.container,
      {
        [styles.disabled]: disabled,
        [styles.active]: !disabled,
      },
      className
    )}
    style={borderLeftColor ? { borderLeftWidth: '0.25rem', borderLeftColor } : undefined}
  >
    <div className={styles.titleContainer}>
      <div
        className={classnames(styles.title, {
          [styles.cutName]: flag?.count !== undefined && flag.count > 0,
        })}
      >
        {title}
      </div>
      {topRightComponent
        ? topRightComponent
        : dotColor && (
            <Dot
              className={classnames(
                { [styles.greenDot]: dotColor === DotColor.GREEN },
                { [styles.orangeDot]: dotColor === DotColor.ORANGE },
                { [styles.greyDot]: dotColor === DotColor.GREY }
              )}
            />
          )}
    </div>
    <p className={styles.subtitle}>{firstSubtitle}</p>
    <p className={styles.subtitle}>{secondSubtitle}</p>
    <p className={styles.description}>{description}</p>
    {flag?.count !== undefined && flag.count > 0 && (
      <div className={classnames(styles.flag, flag.className)}>{`${flag.count} ${flag.label}`}</div>
    )}
  </div>
);

export default Card;
