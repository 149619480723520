import { Props } from './CdiiResidencePermit.types';
import styles from './CdiiResidencePermit.module.scss';
import moment from 'moment';
import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { CircleCheck, Danger } from '@randstad-lean-mobile-factory/react-assets/dist/icons';

export const CdiiResidencePermit = ({ residencePermit }: Props) => {
  const expirationDate = residencePermit.expirationDate && moment(residencePermit.expirationDate);
  const formattedDate = expirationDate?.format('DD/MM/YYYY') ?? 'inconnue';
  const today = moment().startOf('day');

  const isFutureDate = expirationDate?.isAfter(today) ?? false;

  return (
    <WithLightTitle title="titre de séjour">
      <div className={styles.detailsContainer}>
        <div>
          {isFutureDate ? (
            <CircleCheck variant="fill" color="success" />
          ) : (
            <Danger variant="fill" color="danger" />
          )}
        </div>
        <div className={styles.residencePermitDetails}>
          {residencePermit.residencePermitTypes?.label &&
            `type : ${residencePermit.residencePermitTypes?.label?.toLowerCase()} - `}
          exp : {formattedDate}
        </div>
      </div>
    </WithLightTitle>
  );
};

export default CdiiResidencePermit;
