import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { CandidatesService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchCandidateCurrentCDIContract = (candidateId: string) => {
  const brandCode = useSelector(getCurrentBrandCode);

  return useQuery([QueryKeys.fetchCandidateCurrentCDIContract, candidateId], async () => {
    return await CandidatesService.candidatesControllerGetCdiContractDetails({
      id: candidateId,
      brandCode,
    });
  });
};
