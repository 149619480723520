import { createAsyncThunk } from '@reduxjs/toolkit';
import { YouplanCompaniesState } from './Types';
import { ThunkAPI } from 'src/Redux/RootReducer';
import { getCurrentBrandCode, getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { CompaniesService } from 'src/Services/API';

export const searchYouplanCompanies = createAsyncThunk<
  YouplanCompaniesState['youplanCompanies'],
  undefined,
  ThunkAPI
>('companies/youplan/search', async (_, thunkAPI) => {
  const state = thunkAPI.getState();

  const brandCode = getCurrentBrandCode(state);
  const agencyId = getCurrentAgency(state);

  const youplanCompanies = await CompaniesService.companiesControllerGetYouplanCompanies({
    body: { agencies: [agencyId], brandCode },
  });

  return youplanCompanies;
});
