import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { MutationKeys, QueryKeys } from '../types';
import { CandidatesService } from 'src/Services/API';
import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import ReactGA from 'react-ga4';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { useSelector } from 'react-redux';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import { isInternEmails } from 'src/Utils/emails';

export interface SendSuggestionToAgenciesParams {
  cgcId: string;
  cc: string[];
  comment: string;
  suggestedMissions: {
    missionNumber: string;
    clientName: string;
    agencyId: string;
    qualificationName: string;
    startDate: string;
  }[];
  candidateFullName: string;
  candidateMainQualification: string;
  lastContractEndDate: string;
  lastMedicalVisit: string;
}

// TODO clarify the difference with useSuggestCandidateToAgencies
export const useSendSuggestionToAgencies = (
  extraOptions?: UseMutationOptions<void, unknown, SendSuggestionToAgenciesParams, MutationKeys[]>
) => {
  const queryContext = useQueryClient();
  const userInfo = useSelector(getAnalyticsUserInfo);

  return useMutation(
    [MutationKeys.sendSuggestionToAgencies],
    async ({
      cgcId,
      cc,
      comment,
      candidateId,
      suggestedMissions,
      candidateFullName,
      candidateMainQualification,
      lastContractEndDate,
      lastMedicalVisit,
    }: SendSuggestionToAgenciesParams & { candidateId: string }) => {
      if (cc[0] !== '' && !isInternEmails(cc)) {
        throw new Error('Invalid CC email address');
      }
      return await CandidatesService.candidatesControllerSendSuggestionToAgencies({
        id: candidateId,
        body: {
          cgcId,
          cc,
          comment,
          suggestedMissions,
          candidateFullName,
          candidateMainQualification,
          lastContractEndDate,
          lastMedicalVisit,
        },
      });
    },
    {
      onSuccess: () => {
        ReactGA.event(ANALYTICS_EVENT.CLICK_ORDER_SUGGEST_CONFIRMATION, {
          ...userInfo,
        });
        queryContext.invalidateQueries({ queryKey: [QueryKeys.fetchCandidatesActivityPlanning] });
        queryContext.invalidateQueries({ queryKey: [QueryKeys.fetchCandidateActivityPlanning] });
      },
      onError: (error: any, ...args) => {
        if (error.message === 'Invalid CC email address') {
          toast.error('Certains mails en cc ne sont pas valides');
          return;
        }

        toast.error('Une erreur est survenue');
      },
      ...extraOptions,
    }
  );
};
