import { useQuery } from '@tanstack/react-query';
import { AdminService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useIsAdmin = () => {
  return useQuery<boolean>(
    [QueryKeys.isAdmin],
    async () => {
      return await AdminService.adminControllerGetIsAdmin();
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
};
