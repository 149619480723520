import { TableCell } from '@mui/material';
import {
  Button,
  DatePickerInput,
  ModalContent,
  StyledTable,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  ToggleMenu,
  ToggleMenuItem,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';
import { RegionDetailsProps } from './RegionDetails.types';

export const RegionDetails = ({ item: region, close }: RegionDetailsProps) => {
  const [localActivationDateEnabled, setLocalActivationDateEnabled] = useState(
    Boolean(region.activationDate)
  );
  const [localActivationDate, setLocalActivationDate] = useState(region.activationDate);

  return (
    <ModalContent title={region.regionId + ' - ' + region.regionTitle}>
      <WithLightTitle title="date d'activation">
        <ToggleMenu
          value={localActivationDateEnabled.toString()}
          onChange={value => setLocalActivationDateEnabled(value === 'true')}
        >
          <ToggleMenuItem value={'false'}>activer maintenant</ToggleMenuItem>
          <ToggleMenuItem value={'true'}>activer dans le futur</ToggleMenuItem>
        </ToggleMenu>
      </WithLightTitle>
      <DatePickerInput
        disabled={!localActivationDateEnabled}
        selected={localActivationDate}
        onChange={setLocalActivationDate}
        placeholderText="date d'activation"
        showTimeSelect
        minDate={new Date()}
      />
      <Button
        size="medium"
        onClick={() => {
          region.setActivationDate(localActivationDateEnabled ? localActivationDate : undefined);
          close();
        }}
      >
        valider
      </Button>
      <StyledTable size="small">
        <TableHeader>
          <TableRow>
            <TableHead>agence</TableHead>
            <TableHead>nom</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {region.zones
            .flatMap(zone => zone.agencies)
            .sort((agencyA, agencyB) => (agencyA.agencyId < agencyB.agencyId ? -1 : 1))
            .map(agency => {
              return (
                <TableRow key={agency.agencyId}>
                  <TableCell>{agency.agencyId}</TableCell>
                  <TableCell>{agency.agencyTitle}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </StyledTable>
    </ModalContent>
  );
};
