import { z } from 'zod';
import {
  FetchTemporaryWorkersAgencyFilters,
  FetchTemporaryWorkersRegionalFilters,
  FetchTemporaryWorkersSort,
  FetchTemporaryWorkersSortCriteria,
  SortTypeEnum,
  TemporaryWorkerStatus,
} from 'src/Services/API';
import { AvailabilityDateInterval } from './TemporaryWorkersTopBarContent/TemporaryWorkersAgencyFilters/TemporaryWorkersAgencyFiltersModalContent/TemporaryWorkersAgencyFiltersModalContent.types';

export enum TemporaryWorkersSource {
  agency = 'agency',
  regional = 'regional',
}

export const temporaryWorkersAgencyFiltersSchema: z.Schema<FetchTemporaryWorkersAgencyFilters> = z.object(
  {
    qualifications: z.array(z.string()),
    availabilityDateInterval: z.nativeEnum(AvailabilityDateInterval),
    status: z.array(z.nativeEnum(TemporaryWorkerStatus)),
  }
);

export const defaultAgencyFilters: z.infer<typeof temporaryWorkersAgencyFiltersSchema> = {
  qualifications: [],
  availabilityDateInterval: AvailabilityDateInterval.SEVEN,
  status: Object.values(TemporaryWorkerStatus),
};

export const temporaryWorkersAgencySortSchema: z.Schema<FetchTemporaryWorkersSort> = z.object({
  sortCriteria: z.nativeEnum(FetchTemporaryWorkersSortCriteria),
  sortType: z.nativeEnum(SortTypeEnum),
});

export const defaultAgencySort: z.infer<typeof temporaryWorkersAgencySortSchema> = {
  sortCriteria: FetchTemporaryWorkersSortCriteria.AvailabilityForConsultantDate,
  sortType: SortTypeEnum.ASC,
};

export const temporaryWorkersRegionalFiltersSchema: z.Schema<FetchTemporaryWorkersRegionalFilters> = z.object(
  {
    qualifications: z.array(z.string()),
    distanceInKm: z.number().optional(),
  }
);

export const defaultRegionalFilters: z.infer<typeof temporaryWorkersRegionalFiltersSchema> = {
  qualifications: [],
  distanceInKm: 60,
};

export const temporaryWorkersRegionalSortSchema: z.Schema<FetchTemporaryWorkersSort> = z.object({
  sortCriteria: z.nativeEnum(FetchTemporaryWorkersSortCriteria),
  sortType: z.nativeEnum(SortTypeEnum),
});

export const defaultRegionalSort: z.infer<typeof temporaryWorkersRegionalSortSchema> = {
  sortCriteria: FetchTemporaryWorkersSortCriteria.AvailabilityForConsultantDate,
  sortType: SortTypeEnum.ASC,
};
