import { SearchInput } from '@randstad-lean-mobile-factory/react-components-core';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { youplanCompaniesActions } from 'src/Redux/YouplanCompanies/Slice';

export const YouplanTopBarContent = () => {
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    dispatch(youplanCompaniesActions.setYouplanSearchInput(searchValue));
  }, [dispatch, searchValue]);

  return (
    <SearchInput
      value={searchValue}
      onValueChange={setSearchValue}
      placeholder="rechercher"
      collapsed
    />
  );
};
