import { createAsyncThunk } from '@reduxjs/toolkit';
import { CompanyState } from './Types';
import { ThunkAPI } from 'src/Redux/RootReducer';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { CompaniesService } from 'src/Services/API';

export interface FetchCompanyInfoParams {
  id: string;
  brandCode: string;
}

export const getCompanyRequestReasons = createAsyncThunk<
  CompanyState['requestReasons'],
  string,
  ThunkAPI
>('companies/requestreasons', async (companyId, thunkAPI) => {
  const state = thunkAPI.getState();
  const brandCode = getCurrentBrandCode(state);

  return CompaniesService.companiesControllerGetRequestReasons({ id: companyId, brandCode });
});
