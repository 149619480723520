import {
  ServerErrorRed,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  DataTable,
  Loader,
  SearchInput,
} from '@randstad-lean-mobile-factory/react-components-core';
import { ColumnDef } from '@tanstack/table-core';
import { useMemo, useState } from 'react';

import { TopBar } from 'src/Components/TopBar';
import { useFetchEstablishments } from 'src/Hooks/Establishments/useFetchEstablishments';
import styles from './Establishments.module.scss';

export const EstablishmentsRoute = () => {
  const { data: establishments, isLoading } = useFetchEstablishments();

  const [globalFilter, setGlobalFilter] = useState('');

  const agencies = useMemo(
    () =>
      establishments?.flatMap(region =>
        region.zones.flatMap(zone =>
          zone.agencies.flatMap(agency => ({
            agencyId: agency.agencyId,
            agencyTitle: agency.agencyTitle,
            zoneId: zone.zoneId,
            zoneTitle: zone.zoneTitle,
            regionId: region.regionId,
            regionTitle: region.regionTitle,
          }))
        )
      ),
    [establishments]
  );

  const columns = useMemo(
    (): ColumnDef<Exclude<typeof agencies, undefined>[number]>[] => [
      {
        id: 'agencyId',
        accessorKey: 'agencyId',
        header: 'agence',
        filterFn: 'arrIncludes',
      },
      {
        id: 'agencyTitle',
        accessorKey: 'agencyTitle',
        header: "nom de l'agence",
        filterFn: 'arrIncludes',
      },
      {
        id: 'zoneId',
        accessorKey: 'zoneId',
        header: 'zone',
        filterFn: 'arrIncludes',
      },
      {
        id: 'zoneTitle',
        accessorKey: 'zoneTitle',
        header: 'nom de la zone',
        filterFn: 'arrIncludes',
      },
      {
        id: 'regionId',
        accessorKey: 'regionId',
        header: 'région',
        filterFn: 'arrIncludes',
      },
      {
        id: 'regionTitle',
        accessorKey: 'regionTitle',
        header: 'nom de la région',
        filterFn: 'arrIncludes',
      },
    ],
    []
  );

  return (
    <div className={styles.container}>
      <TopBar disablePerimeterSelection title="fichier établissements">
        <SearchInput
          value={globalFilter}
          onValueChange={setGlobalFilter}
          placeholder="rechercher"
          collapsed
        />
      </TopBar>
      <div className={styles.content}>
        {isLoading ? (
          <div className={styles.illustration}>
            <Loader size="large" />
            chargement du fichier établissement
          </div>
        ) : !agencies ? (
          <div className={styles.illustration}>
            <ServerErrorRed />
            désolé, une erreur s'est produite
          </div>
        ) : agencies.length === 0 ? (
          <div className={styles.illustration}>
            <VidePasteque />
            le fichier établissement est vide
          </div>
        ) : (
          <DataTable size="small" data={agencies} columns={columns} state={{ globalFilter }} />
        )}
      </div>
    </div>
  );
};
