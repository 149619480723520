import { Check, Danger } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Badge } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import { AppelMedicalEventStatusDto, ExternalDemandStatusDto } from 'src/Services/API';
import { isDemandDto, isDemandEvent } from '../ExternalDemandsList/ExternalDemandsList.types';
import styles from './ExternalDemandStatusBadge.module.scss';
import { ExternalDemandStatusBadgeProps } from './ExternalDemandStatusBadge.types';

const completeEnums = [
  AppelMedicalEventStatusDto['Commande créée'],
  AppelMedicalEventStatusDto['Commande mise à jour'],
  AppelMedicalEventStatusDto['Commande Annulée'],
  AppelMedicalEventStatusDto['Commande confirmée'],
  ExternalDemandStatusDto.COMPLETE,
];

export const ExternalDemandStatusBadge = ({ element }: ExternalDemandStatusBadgeProps) => {
  const isComplete = completeEnums.includes(element.status);
  const isCancelError =
    isDemandEvent(element) &&
    element.cause === 'demande annulée par le client mais des intérimaires y sont rattachés';

  return (
    <Badge
      size="large"
      className={classnames({ [styles.demand]: isDemandDto(element) })}
      color={
        isComplete && !isCancelError
          ? 'success'
          : element.status === AppelMedicalEventStatusDto['Ignorée']
          ? 'information'
          : element.status === ExternalDemandStatusDto.MIXED || isCancelError
          ? 'warning'
          : 'error'
      }
    >
      {isComplete || element.status === AppelMedicalEventStatusDto['Ignorée'] ? (
        <Check />
      ) : (
        <Danger />
      )}
    </Badge>
  );
};
