import { IllusEdpFormationHabilitation } from 'src/Assets_DEPRECATED';
import HabilitationCard from 'src/Components/HabilitationCard';
import styles from './TalentDetailsHabilitation.module.scss';
import { Props } from './TalentDetails.types';
import { BadgeDeprecated } from '@randstad-lean-mobile-factory/react-components-core';

export const TalentDetailsHabilitations = ({ habilitations }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>habilitations</div>
        <BadgeDeprecated className={styles.habilitationsBadge} value={`${habilitations.length}`} />
      </div>
      {habilitations.length !== 0
        ? habilitations.map(habilitation => (
            <HabilitationCard
              key={habilitation.id}
              className={styles.multiCriteriaCard}
              icon={<IllusEdpFormationHabilitation className={styles.habilitationIcon} />}
              title={habilitation.label}
            />
          ))
        : "aucune habilitation n'a été renseignée"}
    </div>
  );
};

export default TalentDetailsHabilitations;
