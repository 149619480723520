import { FeatureFlagConfig } from 'src/Services/API';

export interface FeatureFlagDetailsProps {
  featureFlagConfig: FeatureFlagConfig;
}

export const allBrands = [
  { brandTitle: 'Appel Médical', brandCode: '286AM' },
  { brandTitle: 'Appel Médical', brandCode: '307AM' },
  { brandTitle: 'Appel Médical Search', brandCode: '286AMS' },
  { brandTitle: 'Appel Médical Search', brandCode: '307AMS' },
  { brandTitle: 'Expectra', brandCode: '286EE' },
  { brandTitle: 'Expectra', brandCode: '307EE' },
  { brandTitle: 'Expectra Executive', brandCode: '286EXE' },
  { brandTitle: 'Expectra Executive', brandCode: '307EXE' },
  { brandTitle: 'Expectra Search', brandCode: '286EES' },
  { brandTitle: 'Expectra Search', brandCode: '307EES' },
  { brandTitle: 'JBM', brandCode: '054JBM' },
  { brandTitle: 'JBM', brandCode: '307JBM' },
  { brandTitle: 'On Site', brandCode: '001ROS' },
  { brandTitle: 'Randstad', brandCode: '001VB' },
  { brandTitle: 'Randstad Direct', brandCode: '001RDI' },
  { brandTitle: 'Randstad Inhouse', brandCode: '001RIS' },
  { brandTitle: 'Randstad Inhouse', brandCode: '306RIS' },
  { brandTitle: 'Ranstad Search', brandCode: '001RSS' },
];
