import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCompany } from 'src/Redux/Companies/Selectors';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { getCurrentQualification } from 'src/Redux/Qualifications/Selectors';
import { getEndDate, getStartDate } from 'src/Redux/Search/Selectors';
import { getCurrentService } from 'src/Redux/Services/Selectors';
import EmptyDetail from './EmptyDetail.component';
import moment from 'moment';

const MemoEmptyDetail = React.memo(EmptyDetail);

const EnhancedEmptyDetail = () => {
  const agencyId = useSelector(getCurrentAgency);
  const startDate = useSelector(getStartDate);
  const endDate = useSelector(getEndDate);
  const qualification = useSelector(getCurrentQualification);
  const company = useSelector(getCurrentCompany);
  const service = useSelector(getCurrentService);

  return (
    <MemoEmptyDetail
      agencyId={agencyId}
      startDate={moment(startDate)}
      endDate={endDate}
      qualification={qualification?.label}
      company={company?.companyName}
      service={service?.name}
    />
  );
};

export default EnhancedEmptyDetail;
