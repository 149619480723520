import React from 'react';
import styles from './SingleDatePickerButton.module.scss';
import { TextInput } from '@randstad-lean-mobile-factory/react-components-core';
import { useDispatch, useSelector } from 'react-redux';
import { getStartDate } from 'src/Redux/Search/Selectors';
import { Calendrier, CloseOld } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { searchActions } from 'src/Redux/Search/Slice';
import moment from 'moment';
import { Props } from './SingleDatePickerButton.types';
import classnames from 'classnames';

const SingleDatePickerButton = ({ icon, isDatePickerOpen, setIsDatePickerOpen }: Props) => {
  const dispatch = useDispatch();
  const startDate = useSelector(getStartDate);
  return (
    <div
      className={classnames({ [styles.container]: !icon }, { [styles.containerCalendar]: icon })}
    >
      <TextInput
        value={`${moment(startDate).format('DD/MM/YYYY')}`}
        className={classnames({ [styles.textInput]: icon })}
        rightIcon={
          icon && setIsDatePickerOpen ? (
            <Calendrier
              onClick={event => {
                event.stopPropagation();
                setIsDatePickerOpen(!isDatePickerOpen);
              }}
            />
          ) : (
            <CloseOld
              onClick={() => {
                dispatch(searchActions.setStartDate(moment().startOf('week').toISOString()));
              }}
            />
          )
        }
      />
    </div>
  );
};

export default SingleDatePickerButton;
