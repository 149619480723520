import {
  Modal,
  ModalContent,
  ModalActions,
  Button,
} from '@randstad-lean-mobile-factory/react-components-core';
import { DeleteSignatureModalProps } from './DeleteSignatureModal.types';
import { Attention } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import { useUpdateWorkAccidentConclusionSignature } from 'src/Hooks/WorkAccidentAnalysis/useUpdateWorkAccidentConclusionSignature';

const DeleteSignatureModalContent = ({
  onClose,
  analysisId,
  signatory,
  personName,
}: DeleteSignatureModalProps) => {
  const updateWorkAccidentConclusionSignatoryMutation = useUpdateWorkAccidentConclusionSignature(
    analysisId,
    signatory,
    {
      onSuccess: () => {
        setTimeout(() => onClose?.(), 500);
      },
      onError: () => {
        setTimeout(() => updateWorkAccidentConclusionSignatoryMutation.reset(), 500);
      },
    }
  );

  return (
    <>
      <ModalContent header={<Attention />} title="attention">
        Êtes-vous sûr de vouloir supprimer la signature de {personName ?? 'nom inconnu'} ?
      </ModalContent>
      <ModalActions side="right">
        <>
          <Button variant="secondary" onClick={onClose}>
            annuler
          </Button>
          <Button
            variant="primary"
            onClick={() => updateWorkAccidentConclusionSignatoryMutation.mutate(undefined)}
            mutationStatus={updateWorkAccidentConclusionSignatoryMutation.status}
          >
            supprimer la signature
          </Button>
        </>
      </ModalActions>
    </>
  );
};

export const DeleteSignatureModal = ({
  open,
  onClose,
  analysisId,
  signatory,
  personName,
}: DeleteSignatureModalProps) => {
  return (
    <Modal size="medium" open={open} onClose={onClose}>
      <DeleteSignatureModalContent
        open={open}
        onClose={onClose}
        analysisId={analysisId}
        signatory={signatory}
        personName={personName}
      />
    </Modal>
  );
};
