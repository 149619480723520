import { useRef, useState } from 'react';

import { Badge, NavigateDown } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Button,
  Chip,
  Menu,
  MenuActions,
  MenuItem,
} from '@randstad-lean-mobile-factory/react-components-core';
import { pluralFormat } from 'src/Utils/pluralFormat';
import styles from './QualificationsPopover.module.scss';
import { QualificationsPopoverProps } from './QualificationsPopover.types';

export const QualificationsPopover = ({
  allQualifications,
  selectedQualifications,
  setSelectedQualifications,
}: QualificationsPopoverProps) => {
  const [localQualifications, setLocalQualifications] = useState(selectedQualifications);

  const ref = useRef<MenuActions>(null);

  return (
    <Menu
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      trigger={
        <Chip size="large" rounded={false} selected={selectedQualifications.length > 0}>
          <Badge variant="fill" />
          {selectedQualifications.length
            ? pluralFormat(selectedQualifications.length, 'qualification')
            : 'qualifications'}
          <NavigateDown />
        </Chip>
      }
      onOpen={() => setLocalQualifications(selectedQualifications)}
      ref={ref}
    >
      <MenuItem
        selected={localQualifications.length === 0}
        onClick={() => setLocalQualifications([])}
        keepOpenOnClick
      >
        ouvrir à toutes les qualifications
      </MenuItem>
      {allQualifications.map(qualification => (
        <MenuItem
          key={qualification.id}
          selected={localQualifications.includes(qualification.id ?? '')}
          onClick={() =>
            localQualifications?.includes(qualification.id ?? '')
              ? setLocalQualifications(
                  localQualifications.filter(
                    qualificationItem => qualification.id !== qualificationItem
                  )
                )
              : setLocalQualifications(localQualifications.concat([qualification.id ?? '']))
          }
          keepOpenOnClick
        >
          {qualification.label?.toLowerCase()}
        </MenuItem>
      ))}
      <div className={styles.actions}>
        <Button variant="secondary" size="medium" onClick={() => ref.current?.close()}>
          annuler
        </Button>
        <Button
          size="medium"
          onClick={() => {
            setSelectedQualifications(localQualifications);
            ref.current?.close();
          }}
        >
          valider
        </Button>
      </div>
    </Menu>
  );
};
