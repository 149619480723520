import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { CandidatesService, ChangeRepoStatusParams } from 'src/Services/API';
import { MutationKeys, QueryKeys } from '../types';

export const useChangeManuallyCandidateRepoStatus = (
  extraOptions?: UseMutationOptions<
    void,
    unknown,
    Omit<ChangeRepoStatusParams, 'agencyId'>,
    MutationKeys[]
  >
) => {
  const queryClient = useQueryClient();

  const agencyId = useSelector(getCurrentAgency);

  return useMutation(
    [MutationKeys.changeManuallyRepoStatus],
    async params => {
      return await CandidatesService.candidatesControllerChangeManuallyRepoStatus({
        body: {
          ...params,
          agencyId,
        },
      });
    },
    {
      ...extraOptions,
      onSuccess: () => {
        queryClient.resetQueries({ queryKey: [QueryKeys.fetchCandidatesActivityPlanning] });
        queryClient.resetQueries({ queryKey: [QueryKeys.fetchCandidateActivityPlanning] });
      },
    }
  );
};
