import { Validation } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import {
  Button,
  FetchButton,
  Modal,
  ModalActions,
  ModalContent,
  PopupActions,
  TextArea,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSuggestCandidateToAgencies } from 'src/Hooks/Candidate/useSuggestCandidateToAgencies';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { toFetchStatus } from 'src/Services/Async';
import { REPORT_MAX_LENGTH } from 'src/Utils/constants';
import { isInternEmails } from 'src/Utils/emails';
import { parseEmails } from 'src/Utils/parseEmails';
import styles from './CGCAgencyPropositionModal.module.scss';
import { CGCAgencyPropositionMissionProps } from './CGCAgencyPropositionModal.types';
import classnames from 'classnames';

export const CGCAgencyPropositionModal = ({
  candidateData,
  selectedAgencies,
  open,
  onClose,
}: CGCAgencyPropositionMissionProps) => {
  const ref = useRef<PopupActions>(null);
  const userInfo = useSelector(getAnalyticsUserInfo);
  const navigate = useNavigate();

  const suggestCandidateToAgencies = useSuggestCandidateToAgencies();
  const suggestCandidateToAgenciesFetchStatus = toFetchStatus(suggestCandidateToAgencies);

  const [cc, setCc] = useState<string>('');

  const [message, setMessage] = useState<string>(
    candidateData.sosComment
      ? `message de l'agence ${candidateData.sosAgencyId} lors du sos :
  ${candidateData.sosComment}`
      : ''
  );

  const ccMailsAreValid = useMemo(() => {
    const emails = parseEmails(cc);
    if (emails[0] === '') return true;
    return isInternEmails(emails);
  }, [cc]);

  return (
    <>
      <Modal open={open} onClose={onClose} size="medium">
        <ModalContent
          header={<Validation />}
          title={selectedAgencies.length > 1 ? 'suggestions aux agences' : "suggestion à l'agence"}
        >
          <p className={styles.text}>
            Je m'apprête à transmettre le talent{' '}
            {selectedAgencies.length > 1 ? 'aux agences suivantes' : "à l'agence suivante"} :
            <br />
            {selectedAgencies.map(agency => (
              <p className={styles.agencyText} key={agency.idDecrypted}>
                • {agency.name} <br />
              </p>
            ))}
          </p>
          <div>
            <WithLightTitle title="cc (mails séparés d'une virgule)" className={styles.checkText}>
              <TextInput value={cc} error={!ccMailsAreValid} onValueChange={setCc} />
            </WithLightTitle>
            {!ccMailsAreValid && (
              <div className={styles.warningMessage}>
                une ou plusieurs adresses mails sont invalides
              </div>
            )}
          </div>
          <WithLightTitle title="votre message" className={styles.checkText}>
            <TextArea
              className={classnames(styles.reportTextArea, {
                [styles.errorTextArea]: message.length < 10,
              })}
              value={message}
              onChange={event => setMessage((event.target as HTMLTextAreaElement).value)}
              maxLength={REPORT_MAX_LENGTH}
              placeholder={'en quelques mots...'}
            />
            {message.length < 10 && (
              <div className={styles.warningMessage}>10 caractères minimum</div>
            )}
          </WithLightTitle>
        </ModalContent>
        <ModalActions side="right">
          <Button variant="secondary" onClick={() => ref.current?.close()}>
            annuler
          </Button>
          <FetchButton
            fetchStatus={suggestCandidateToAgenciesFetchStatus}
            key="sendButton"
            title="envoyer"
            onClick={() => {
              return suggestCandidateToAgencies.mutate({
                candidateId: candidateData.id ?? '',
                params: {
                  cc: parseEmails(cc),
                  suggestedAgencyIds: selectedAgencies.map(agency => agency.id),
                  comment: message,
                },
              });
            }}
            disabled={message.length < 10 || !ccMailsAreValid}
            onSuccess={() => {
              ref.current?.close();
              ReactGA.event(ANALYTICS_EVENT.PPA_INTERN_SUGGESTION, {
                ...userInfo,
                suggestionCount: selectedAgencies.length,
              });
              navigate(ROUTES.TALENTS.CDII.buildPath({}));
            }}
          />
        </ModalActions>
      </Modal>
    </>
  );
};
