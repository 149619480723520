import { Validation } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFetchPerimeter } from 'src/Hooks/Consultant/useFetchPerimeter';
import { perimeterActions } from 'src/Redux/Perimeter/Slice';
import { ROUTES } from 'src/Routes/Routes.types';
import { Props } from './RepositioningValidationModal.types';

const RepositioningValidationModal = ({
  open,
  setOpen,
  selectedMissionNumber,
  missionId,
  missionAgencyId,
  isRepositioningTalent,
}: Props) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { data: perimeter } = useFetchPerimeter();

  const consultantAgencies = perimeter?.companies?.flatMap(company => company.agencies) ?? [];

  return (
    <Modal size="medium" open={open} onClose={() => setOpen(false)}>
      <ModalContent header={<Validation />} title="Validation du rattachement">
        Le candidat a bien été rattaché
        {selectedMissionNumber !== undefined && selectedMissionNumber > 1
          ? ' aux missions sélectionnées'
          : ' à la mission sélectionnée'}
        .
      </ModalContent>
      <ModalActions side="left">
        <>
          {selectedMissionNumber === 1 &&
          missionAgencyId &&
          consultantAgencies.includes(missionAgencyId) ? (
            <Button
              variant="secondary"
              onClick={() => {
                const missionBrand = missionAgencyId
                  ? perimeter?.companies?.find(company =>
                      company.agencies?.includes(missionAgencyId)
                    )
                  : undefined;
                if (missionBrand && missionAgencyId) {
                  dispatch(
                    perimeterActions.setSelectedPerimeter({
                      brand: missionBrand,
                      agencyId: missionAgencyId,
                    })
                  );
                }

                if (!missionId) return;
                navigate(
                  ROUTES.ACTIVITY.MISSIONS.MISSION_DETAIL.buildPath({
                    missionId: missionId,
                  })
                );
              }}
            >
              voir la commande
            </Button>
          ) : undefined}
        </>
      </ModalActions>
      <ModalActions side="right">
        <Button variant="primary" onClick={() => setOpen(false)}>
          retour à la liste
        </Button>
      </ModalActions>
    </Modal>
  );
};
export default RepositioningValidationModal;
