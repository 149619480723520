import { ModalProps } from '@randstad-lean-mobile-factory/react-components-core/dist/Modals/Modal/Modal.types';
import { TemporaryWorkersAgencyFiltersProps } from '../TemporaryWorkersAgencyFilters.types';

export interface TemporaryWorkersAgencyFiltersModalContentProps
  extends Pick<ModalProps, 'onClose'>,
    TemporaryWorkersAgencyFiltersProps {}

export enum AvailabilityDateInterval {
  SEVEN = 7,
  FIFTEEN = 15,
}
