import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import invariant from 'invariant';
import { CandidateTesting, CandidatesService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchCandidateSensibilisationTesting = (
  candidateId?: string,
  extraOptions?: UseQueryOptions<
    CandidateTesting[],
    unknown,
    CandidateTesting[],
    (string | undefined)[]
  >
) => {
  return useQuery(
    [QueryKeys.fetchCandidateTesting, candidateId],
    async () => {
      invariant(candidateId, 'candidateId is missing');
      return await CandidatesService.candidatesControllerGetCandidateSensibilisationTesting({
        id: candidateId,
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(candidateId),
      staleTime: 3600000,
      ...extraOptions,
    }
  );
};
