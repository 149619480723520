import { useRef } from 'react';

import { CalendarFocus, Undo } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button, DatePicker, Popover } from '@randstad-lean-mobile-factory/react-components-core';
import { DatePickerActions } from '@randstad-lean-mobile-factory/react-components-core/dist/Calendars/DatePicker/DatePicker.types';
import { PivotDatePickerProps } from './PivotDatePicker.types';

import styles from './PivotDatePicker.module.scss';
import moment from 'moment';

export const PivotDatePicker = ({ trigger, pivotDate, setPivotDate }: PivotDatePickerProps) => {
  const ref = useRef<DatePickerActions>(null);

  return (
    <Popover
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      trigger={trigger}
    >
      <DatePicker
        selected={pivotDate}
        onChange={date => setPivotDate(date ?? new Date())}
        showYearDropdown
        ref={ref}
      />
      <div className={styles.footer}>
        <Button variant="tertiary" size="medium" onClick={() => setPivotDate(new Date())}>
          <Undo />
          réinitialiser
        </Button>
        <Button variant="tertiary" size="medium" onClick={() => ref.current?.goToToday()}>
          <CalendarFocus />
          {moment().format('MMM YYYY')}
        </Button>
      </div>
    </Popover>
  );
};
