import {
  ServerErrorYellow,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import { Loader, SegmentedControl } from '@randstad-lean-mobile-factory/react-components-core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTypedHash, useTypedState } from 'react-router-typesafe-routes/dom';
import { TopBar } from 'src/Components/TopBar';
import { useFetchAgencyTemporaryWorkers } from 'src/Hooks/Candidate/useFetchAgencyTemporaryWorkers';
import { useFetchRegionalTemporaryWorkers } from 'src/Hooks/Candidate/useFetchRegionalTalents';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import styles from './TemporaryWorkers.module.scss';
import {
  defaultAgencyFilters,
  defaultAgencySort,
  defaultRegionalFilters,
  defaultRegionalSort,
  TemporaryWorkersSource,
} from './TemporaryWorkers.types';
import { TemporaryWorkersList } from './TemporaryWorkersList';
import { TemporaryWorkersTopBarContent } from './TemporaryWorkersTopBarContent';

export const TemporaryWorkersRoute = () => {
  const navigate = useNavigate();
  const {
    agencyFilters: initialAgencyFilters,
    agencySort: initialAgencySort,
    regionalFilters: initialRegionalFilters,
    regionalSort: initialRegionalSort,
  } = useTypedState(ROUTES.TALENTS.TEMPORARY_WORKERS);

  const userInfo = useSelector(getAnalyticsUserInfo);

  const [agencyFilters, setAgencyFilters] = useState(initialAgencyFilters ?? defaultAgencyFilters);
  const [agencySort, setAgencySort] = useState(initialAgencySort ?? defaultAgencySort);
  const [regionalFilters, setRegionalFilters] = useState(
    initialRegionalFilters ?? defaultRegionalFilters
  );
  const [regionalSort, setRegionalSort] = useState(initialRegionalSort ?? defaultRegionalSort);

  const {
    data: agencyData,
    isLoading: agencyIsLoading,
    isFetching: agencyIsFetching,
    hasNextPage: agencyHasNextPage,
    fetchNextPage: agencyFetchNextPage,
  } = useFetchAgencyTemporaryWorkers({ filters: agencyFilters, sort: agencySort });

  const {
    data: regionalData,
    isFetching: regionalIsFetching,
    isLoading: regionalIsLoading,
    hasNextPage: regionalHasNextPage,
    fetchNextPage: regionalFetchNextPage,
  } = useFetchRegionalTemporaryWorkers({ filters: regionalFilters, sort: regionalSort });

  const [selectedSource, setSelectedSource] = useState(
    useTypedHash(ROUTES.TALENTS.TEMPORARY_WORKERS) ?? TemporaryWorkersSource.agency
  );

  useEffect(() => {
    navigate(ROUTES.TALENTS.TEMPORARY_WORKERS.buildPath({}, {}, selectedSource), {
      replace: true,
      state: ROUTES.TALENTS.TEMPORARY_WORKERS.buildState({
        agencyFilters,
        agencySort,
        regionalFilters,
        regionalSort,
      }),
    });
  }, [navigate, selectedSource, regionalFilters, agencyFilters, agencySort, regionalSort]);

  const data = useMemo(() => {
    switch (selectedSource) {
      case TemporaryWorkersSource.agency: {
        return agencyData?.pages.flatMap(page => page.temporaryWorkers);
      }
      case TemporaryWorkersSource.regional: {
        return regionalData?.pages.flatMap(page => page.temporaryWorkers);
      }
    }
  }, [agencyData, regionalData, selectedSource]);

  const fetchNextPage = useMemo(() => {
    switch (selectedSource) {
      case TemporaryWorkersSource.agency: {
        return agencyFetchNextPage;
      }
      case TemporaryWorkersSource.regional: {
        return regionalFetchNextPage;
      }
    }
  }, [agencyFetchNextPage, regionalFetchNextPage, selectedSource]);

  const isLoading = useMemo(() => {
    switch (selectedSource) {
      case TemporaryWorkersSource.agency: {
        return agencyIsLoading;
      }
      case TemporaryWorkersSource.regional: {
        return regionalIsLoading;
      }
    }
  }, [agencyIsLoading, regionalIsLoading, selectedSource]);

  const isFetching = useMemo(() => {
    switch (selectedSource) {
      case TemporaryWorkersSource.agency: {
        return agencyIsFetching;
      }
      case TemporaryWorkersSource.regional: {
        return regionalIsFetching;
      }
    }
  }, [agencyIsFetching, regionalIsFetching, selectedSource]);

  const hasNextPage = useMemo(() => {
    switch (selectedSource) {
      case TemporaryWorkersSource.agency: {
        return agencyHasNextPage;
      }
      case TemporaryWorkersSource.regional: {
        return regionalHasNextPage;
      }
    }
  }, [agencyHasNextPage, regionalHasNextPage, selectedSource]);

  const scrollViewRef = useRef<HTMLDivElement>(null);
  const handleScroll = useCallback(() => {
    if (!scrollViewRef.current) return;
    const scrollBottom =
      scrollViewRef.current.scrollHeight -
      scrollViewRef.current.clientHeight -
      scrollViewRef.current.scrollTop;
    if (scrollBottom < 100 && !isFetching && hasNextPage) fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetching]);
  useEffect(handleScroll, [handleScroll]);

  useEffect(() => {
    if (data?.length === 0 && hasNextPage) fetchNextPage();
  }, [selectedSource, data, hasNextPage, fetchNextPage]);

  return (
    <>
      <TopBar
        title={
          <SegmentedControl
            className={styles.segmentedControl}
            controls={Object.values(TemporaryWorkersSource)}
            selected={selectedSource}
            getValue={source =>
              ({
                [TemporaryWorkersSource.agency]: 'intérimaires de mon unité',
                [TemporaryWorkersSource.regional]: 'intérimaires de ma région',
              }[source])
            }
            onSelectionChange={source => {
              setSelectedSource(source);
              if (source === TemporaryWorkersSource.agency) {
                ReactGA.event(ANALYTICS_EVENT.CLICK_TOGGLE_MYTT, {
                  ...userInfo,
                });
              }
              if (source === TemporaryWorkersSource.regional) {
                ReactGA.event(ANALYTICS_EVENT.CLICK_TOGGLE_TTMYREGION, {
                  ...userInfo,
                });
              }
            }}
          />
        }
      >
        <TemporaryWorkersTopBarContent
          selectedSource={selectedSource}
          agencyFilters={agencyFilters}
          setAgencyFilters={setAgencyFilters}
          agencySort={agencySort}
          setAgencySort={setAgencySort}
          regionalFilters={regionalFilters}
          setRegionalFilters={setRegionalFilters}
          regionalSort={regionalSort}
          setRegionalSort={setRegionalSort}
        />
      </TopBar>
      <div className={styles.container}>
        {isLoading || (data?.length === 0 && hasNextPage) ? (
          <div className={styles.loadingScreen}>
            <Loader heightInRem={4} className={styles.loader} />
            chargement des intérimaires, cela peut prendre du temps
          </div>
        ) : !data ? (
          <div className={styles.error}>
            <ServerErrorYellow />
            Une erreur s'est produite lors du chargement des intérimaires
          </div>
        ) : data.length === 0 ? (
          <div className={styles.empty}>
            <VidePasteque />
            Aucun intérimaire ne correspond à votre recherche
          </div>
        ) : (
          <div className={styles.table} onScroll={handleScroll} ref={scrollViewRef}>
            <TemporaryWorkersList source={selectedSource} temporaryWorkers={data} />
            {isFetching && <Loader heightInRem={2} className={styles.loader} />}
          </div>
        )}
      </div>
    </>
  );
};
