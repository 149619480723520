import { useInfiniteQuery } from '@tanstack/react-query';
import invariant from 'invariant';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { SEARCH_LIMIT } from 'src/Redux/Search/Types';
import { EnumSearchMissionsBodySortBy, YouplanMissionsService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchYouplanMissions = ({ companyId }: { companyId?: string }) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);

  return useInfiniteQuery(
    [QueryKeys.fetchYouplanMissions, companyId],
    ({ pageParam: offset = 0 }) => {
      invariant(companyId, 'companyId is missing');
      return YouplanMissionsService.youplanMissionsControllerGetYouplanMissions({
        agencyId,
        companyId,
        sortBy: EnumSearchMissionsBodySortBy['date de début'],
        limit: SEARCH_LIMIT,
        offset,
        brandCode,
      });
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.length === SEARCH_LIMIT ? pages.length * SEARCH_LIMIT : undefined;
      },
      enabled: Boolean(companyId),
    }
  );
};
