import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  SignatureWithChoice,
} from '@randstad-lean-mobile-factory/react-components-core';
import { CreateSignatureModalProps } from './CreateSignatureModal.types';
import { Signature } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import { useUpdateWorkAccidentConclusionSignature } from 'src/Hooks/WorkAccidentAnalysis/useUpdateWorkAccidentConclusionSignature';
import { useState } from 'react';

const CreateSignatureModalContent = ({
  open,
  onClose,
  analysisId,
  signatory,
}: CreateSignatureModalProps) => {
  const updateWorkAccidentConclusionSignatoryMutation = useUpdateWorkAccidentConclusionSignature(
    analysisId,
    signatory,
    {
      onSuccess: () => {
        setTimeout(() => onClose?.(), 500);
      },
      onError: () => {
        setTimeout(() => updateWorkAccidentConclusionSignatoryMutation.reset(), 500);
      },
    }
  );

  const [signature, setSignature] = useState<string>();

  const handleSaveAndValidate = async () => {
    updateWorkAccidentConclusionSignatoryMutation.mutate(signature);
  };

  return (
    <Modal size="medium" open={open} onClose={onClose}>
      <ModalContent header={<Signature />} title="signature">
        <SignatureWithChoice
          size={{ width: 525, height: 200 }}
          onSignChange={setSignature}
          onClear={() => {
            setSignature(undefined);
          }}
        />
      </ModalContent>
      <ModalActions side="right">
        <Button variant="secondary" onClick={() => onClose?.()}>
          annuler
        </Button>
        <Button
          variant="primary"
          onClick={handleSaveAndValidate}
          mutationStatus={updateWorkAccidentConclusionSignatoryMutation.status}
          disabled={!signature}
        >
          valider
        </Button>
      </ModalActions>
    </Modal>
  );
};

export const CreateSignatureModal = ({
  open,
  onClose,
  analysisId,
  signatory,
}: CreateSignatureModalProps) => {
  return (
    <Modal size="medium" open={open} onClose={onClose}>
      <CreateSignatureModalContent
        open={open}
        onClose={onClose}
        analysisId={analysisId}
        signatory={signatory}
      />
    </Modal>
  );
};
