import { Danger } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Flash } from '@randstad-lean-mobile-factory/react-assets/dist/logos';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../Routes.types';
import styles from './NotFound.module.scss';

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container} data-theme="dark">
      <div className={styles.content}>
        <Flash size="large" />
        <div className={styles.title}>
          Bienvenue
          <br />
          sur la nouvelle version flash
        </div>
        <div className={styles.description}>votre service online de suivi de votre activité</div>
        <div className={styles.error}>
          <Danger size="xlarge" />
          <div>La page recherchée est introuvable</div>
        </div>
        <Button className={styles.button} onClick={() => navigate(ROUTES.ROOT.buildPath({}))}>
          retour à la page d'accueil
        </Button>
      </div>
    </div>
  );
};
