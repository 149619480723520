import { useInfiniteQuery } from '@tanstack/react-query';
import invariant from 'invariant';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { QualificationsService } from 'src/Services/API';

import { QueryKeys } from '../types';

const GET_POTENTIAL_QUALIFICATIONS_CLIENTS_LIMIT = 20;

export const useFetchPotentialQualificationsClientsForRepositioning = ({
  mainQualifications,
  departmentIds,
}: {
  mainQualifications?: string[];
  departmentIds?: string[];
}) => {
  const agencyId = useSelector(getCurrentAgency);

  return useInfiniteQuery(
    [
      QueryKeys.fetchPotentialQualificationsClientsForRepositioning,
      mainQualifications,
      departmentIds,
    ],

    ({ pageParam: offset = 0 }) => {
      invariant(mainQualifications, 'mainQualifications is missing');
      return QualificationsService.qualificationsControllerGetPotentialQualificationsClients({
        body: {
          agencyId,
          // TODO check if we need this ?
          brandCode: '001VB',
          mainQualifications,
          departmentIds,
          offset,
          limit: GET_POTENTIAL_QUALIFICATIONS_CLIENTS_LIMIT,
        },
      });
    },
    {
      enabled: departmentIds !== undefined && mainQualifications !== undefined,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) =>
        lastPage.length === GET_POTENTIAL_QUALIFICATIONS_CLIENTS_LIMIT
          ? (pages.length - 1) * GET_POTENTIAL_QUALIFICATIONS_CLIENTS_LIMIT + lastPage.length
          : undefined,
    }
  );
};
