import {
  DatePickerInputDeprecated,
  TextArea,
  TimePicker,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  IllusEscalier,
  MoinsCircle,
  People,
  PlusCircle,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import moment from 'moment';
import DayTimePicker from 'src/Components/DayTimePicker';
import { DayTimes } from 'src/Components/DayTimePicker/DayTimePicker.types';
import NumberPicker from 'src/Components/NumberPicker';
import SelectContact from 'src/Components/Selects/SelectContact';
import SelectRequestReason from 'src/Components/Selects/SelectRequestReason';
import SelectService from 'src/Components/Selects/SelectService';
import { FETCH_STATUS } from 'src/Redux/Types';
import { StructuredMissionHours } from 'src/Services/API';
import { OSMToTimeInput, timeInputToOSM } from 'src/Utils/datetime';
import MissionCreationLoader from '../MissionCreationLoader';
import styles from './SecondContent.module.scss';
import { Props } from './SecondContent.types';
import WorkLocation from './WorkLocation';

const defaultStartHour = 12;

const FromWorkingTimesToDayTimes = (workingTime: StructuredMissionHours): DayTimes => {
  return {
    startTime: OSMToTimeInput(workingTime.startTime!),
    endTime: OSMToTimeInput(workingTime.endTime!),
    selectedDays: workingTime.days!,
  };
};

const SecondContent = ({
  selectedCompany,
  missionDataFetchStatus,
  mission,
  addWorkingTime,
  eraseWorkingTime,
  isFromYouplan,
  onMissionUpdate,
  onWorkingTimesUpdate,
  setSelectedContact,
  setSelectedRequestReason,
  setSelectedService,
  setNumberPositions,
  numberPositions,
}: Props) => {
  return (
    <div className={styles.container}>
      {missionDataFetchStatus === FETCH_STATUS.PENDING && <MissionCreationLoader />}
      {missionDataFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          <div className={styles.header}>
            <p>Pour passer une commande, merci de vérifier les informations suivantes :</p>
          </div>

          <WorkLocation selectedCompany={selectedCompany} mission={mission} />

          <WithLightTitle title={'nombre de TT à servir'}>
            <NumberPicker
              entityName="TT"
              entityIcon={<People />}
              value={numberPositions}
              maxPreset={5}
              onSelect={selected => {
                setNumberPositions(selected);
              }}
            />
          </WithLightTitle>
          <div className={styles.timeSelection}>
            <WithLightTitle title="période début">
              <DatePickerInputDeprecated
                date={mission.startDate}
                minDate={isFromYouplan ? undefined : moment().subtract(2, 'day').toDate()}
                onSelectDate={date => {
                  onMissionUpdate({ ...mission, startDate: date });
                }}
                warning={!mission.startDate}
              />
              {!mission.startDate && <div className={styles.warningMessage}>obligatoire</div>}
            </WithLightTitle>
            <WithLightTitle title="à" className={styles.timePicker}>
              <TimePicker
                value={OSMToTimeInput(mission.startHour ?? defaultStartHour)}
                onChange={event => {
                  onMissionUpdate({
                    ...mission,
                    startHour: timeInputToOSM((event.target as HTMLInputElement).value),
                  });
                }}
              />
            </WithLightTitle>
            <WithLightTitle title="période fin">
              <DatePickerInputDeprecated
                date={mission.endDate}
                minDate={moment().subtract(2, 'day').toDate()}
                onSelectDate={date => {
                  onMissionUpdate({ ...mission, endDate: date });
                }}
                warning={!mission.endDate}
              />
              {!mission.endDate && <div className={styles.warningMessage}>obligatoire</div>}
            </WithLightTitle>
          </div>

          {!isFromYouplan && (
            <>
              {mission.structuredMissionHours?.map((workingTime, index) => (
                <div className={styles.workingTimePicker}>
                  <DayTimePicker
                    key={index}
                    onUpdate={dayTime => {
                      onWorkingTimesUpdate(dayTime, index);
                    }}
                    dayTimes={FromWorkingTimesToDayTimes(workingTime)}
                  />
                  {index !== 0 && (
                    <MoinsCircle
                      className={styles.eraseIcon}
                      onClick={() => eraseWorkingTime(index)}
                    />
                  )}
                </div>
              ))}

              <div
                className={styles.addMore}
                onClick={() => {
                  addWorkingTime();
                }}
              >
                <PlusCircle className={styles.addIcon} />
                <>{'ajouter un créneau horaire'}</>
              </div>
            </>
          )}
          <WithLightTitle title="motif (optionnel)" className={styles.filter}>
            <SelectRequestReason
              selectedRequestReason={
                mission.reasonId
                  ? {
                      label: mission.reasonLabel,
                      id: mission.reasonId,
                    }
                  : undefined
              }
              onRequestReasonSelect={selectedRequestReason => {
                setSelectedRequestReason(selectedRequestReason);
              }}
              canBeReset
            />
          </WithLightTitle>
          <WithLightTitle
            title="interlocuteur (si aucun interlocuteur, veuillez le créer en amont sur Osmose)"
            className={styles.filter}
          >
            <SelectContact
              selectedContact={mission.contact}
              setSelectedContact={selectedContact => {
                selectedContact && setSelectedContact(selectedContact);
              }}
              canBeReset={true}
              defaultValue="définir un interlocuteur"
              companyId={selectedCompany?.companyId ?? ''}
            />
            {!mission.contact && <div className={styles.warningMessage}>obligatoire</div>}
          </WithLightTitle>
          {/* TODO: enlever la margin bottom quand le nouveau search input sera compatible avec la ModalDeprecated */}
          <WithLightTitle title="service (optionnel)" className={styles.filter}>
            <SelectService
              defaultValue="aucun service choisi"
              selectedService={
                mission.service
                  ? {
                      ...mission.service,
                      active: true,
                    }
                  : undefined
              }
              setSelectedService={selectedService => {
                setSelectedService(selectedService);
              }}
            />
          </WithLightTitle>
          <WithLightTitle title="commentaire public">
            <TextArea
              className={styles.comment}
              placeholder="aucun commentaire renseigné"
              value={mission.comment ?? ''}
              onChange={event =>
                onMissionUpdate({
                  ...mission,
                  comment: (event.target as HTMLTextAreaElement).value,
                })
              }
            />
          </WithLightTitle>
        </>
      )}
      {missionDataFetchStatus === FETCH_STATUS.REJECTED && (
        <div className={styles.noResult}>
          <IllusEscalier />
          <div className={styles.noResultText}>Une erreur est survenue</div>
        </div>
      )}
    </div>
  );
};

export default SecondContent;
