import { useQuery } from '@tanstack/react-query';
import { RepositoryService } from 'src/Services/API';

import { QueryKeys } from '../types';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';

export const useFetchInjuryNatures = () => {
  const brandCode = useSelector(getCurrentBrandCode);

  return useQuery([QueryKeys.fetchInjuryNature], async () => {
    return await RepositoryService.repositoryControllerGetInjuryNatures({ brandCode });
  });
};
