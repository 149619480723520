import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { MutationKeys, QueryKeys } from '../types';
import { CandidatesService, SuggestCandidateToAgenciesParams } from 'src/Services/API';
import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { isInternEmails } from 'src/Utils/emails';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';

export const useSuggestCandidateToAgencies = (
  extraOptions?: UseMutationOptions<
    void,
    unknown,
    { candidateId: string; params: Omit<SuggestCandidateToAgenciesParams, 'cgcId'> },
    any
  >
) => {
  const queryContext = useQueryClient();

  const agencyId = useSelector(getCurrentAgency);

  return useMutation(
    [MutationKeys.suggestCandidateToAgencies],
    async ({ candidateId, params }) => {
      if (params.cc[0] !== '' && !isInternEmails(params.cc)) {
        throw new Error('Invalid CC email address');
      }
      return await CandidatesService.candidatesControllerSuggestCandidateToAgencies({
        id: candidateId,
        body: { ...params, cgcId: agencyId },
      });
    },
    {
      onSuccess: () => {
        queryContext.invalidateQueries({ queryKey: [QueryKeys.fetchCandidatesActivityPlanning] });
        queryContext.invalidateQueries({ queryKey: [QueryKeys.fetchCandidateActivityPlanning] });
      },
      onError: (error: any, ...args) => {
        if (error.message === 'Invalid CC email address') {
          toast.error('Certains mails en cc ne sont pas valides');
          return;
        }

        toast.error('une erreur est survenue');
      },
      ...extraOptions,
    }
  );
};
