import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { ExternalDemandsService, MailParameterToUpdateDto } from 'src/Services/API';
import { MutationKeys } from '../types';
import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';

export const useUpdateMailParameters = (
  extraOptions?: UseMutationOptions<
    void,
    unknown,
    Omit<MailParameterToUpdateDto, 'agencyId'>,
    unknown
  >
) => {
  const agencyId = useSelector(getCurrentAgency);

  return useMutation(
    [MutationKeys.updateMailParameters],
    async (mailParameter: Omit<MailParameterToUpdateDto, 'agencyId'>) => {
      return await ExternalDemandsService.externalDemandsControllerUpdateMailParameter({
        body: {
          agencyId,
          source: mailParameter.source,
          mailParametersToUpdate: [{ ...mailParameter, agencyId }],
        },
      });
    },
    {
      ...extraOptions,
      onSuccess: () => {
        toast.success('correspondance mise à jour');
      },
      onError: () => {
        toast.error('erreur lors de la mise à jour de la correspondance');
      },
      retry: 5,
    }
  );
};
