import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { Qualification, RepositoryService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchQualificationsDetails = (
  qualificationIds: string[],
  extraOptions?: Omit<
    UseQueryOptions<Qualification[], unknown, Qualification[], any>,
    'queryFn' | 'queryKey'
  >
) => {
  return useQuery(
    [QueryKeys.fetchQualificationsDetails, qualificationIds],
    async () => {
      if (qualificationIds.length === 0) {
        return [];
      }
      return await RepositoryService.repositoryControllerGetQualificationsDetails({
        body: { qualificationIds },
      });
    },
    {
      staleTime: 86400000,
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};
