import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import invariant from 'invariant';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { Action, ActionsService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchActionDetail = (
  actionId?: string,
  extraOptions?: UseQueryOptions<Action, unknown, Action, any>
) => {
  const brandCode = useSelector(getCurrentBrandCode);

  return useQuery(
    [QueryKeys.fetchActionDetail, actionId],
    async () => {
      invariant(actionId, 'actionId is missing');
      return await ActionsService.actionsControllerGetAction({
        id: actionId,
        brandCode,
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: actionId !== undefined,
      staleTime: 3600000,
      ...extraOptions,
    }
  );
};
