import { Radio, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';

import { Circle } from '@randstad-lean-mobile-factory/react-assets/dist/icons';

import { teaCup } from 'src/Assets_DEPRECATED';
import SelectCompany from 'src/Components/Selects/SelectCompany';
import SelectQualification from 'src/Components/Selects/SelectQualification';
import { FETCH_STATUS } from 'src/Redux/Types';
import MissionCreationLoader from '../MissionCreationLoader';
import styles from './PreviousPositionStudies.module.scss';
import { Props } from './PreviousPositionStudies.types';
import { positionStudyStatusToColor } from '../../../Utils/positionStudies.helper';
import { EnumPositionStudyCompletionStatus } from '../../../Services/API';
import React, { useEffect, useState } from 'react';
import { ServerErrorYellow } from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import { pluralFormat } from 'src/Utils/pluralFormat';

const PreviousPositionStudies = (props: Props) => {
  const [count, setCount] = useState(5);
  const isNonePreviousStudiesCompleted =
    props.positionStudiesCount === 0 ||
    (props.previousPositionStudies &&
      props.previousPositionStudies.length > 0 &&
      props.previousPositionStudies.every(study => study.resumeKeywords !== 'UP_TO_DATE'));
  useEffect(() => {
    if (count <= 0 && props.isPilotUnit && isNonePreviousStudiesCompleted) {
      window.open(
        `${process.env.REACT_APP_PUBLIC_EDP_URL}/company/${props.selectedCompany?.companyId}`
      );
      return;
    }
    const timer = setInterval(() => {
      setCount(prevCount => prevCount - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [
    count,
    isNonePreviousStudiesCompleted,
    props.isPilotUnit,
    props.positionStudiesCount,
    props.selectedCompany,
  ]);

  return props.isPilotUnit && isNonePreviousStudiesCompleted ? (
    <div className={styles.timer}>
      <p>{count !== 0 ? `${pluralFormat(count, 'seconde')}` : '...Redirection'}</p>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.searchFieldCompany}>
          <WithLightTitle title="compte">
            <SelectCompany
              selectedCompany={props.selectedCompany}
              setSelectedCompany={selectedCompany => {
                props.setSelectedCompany(selectedCompany);
              }}
              canBeReset={false}
              defaultValue={props.selectedCompany?.companyName}
            />
          </WithLightTitle>
        </div>
        <div className={styles.searchFieldQualification}>
          <WithLightTitle title="qualification">
            <SelectQualification
              selectedQualification={props.selectedQualification}
              setSelectedQualification={selectedQualification => {
                props.setSelectedQualification(selectedQualification);
              }}
              // We specify no society / brand to have the same behavior as OSM
              canBeReset={false}
              defaultValue={props.selectedQualification?.label}
            />
          </WithLightTitle>
        </div>
      </div>
      {props.previousPositionStudiesFetchStatus === FETCH_STATUS.PENDING && (
        <MissionCreationLoader />
      )}
      {props.previousPositionStudiesFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          {props.previousPositionStudies?.length !== 0 ? (
            <>
              <div className={styles.title}>
                <p></p>
                <p>nom de l'edp</p>
                <p>dernière modification</p>
                <p>statut</p>
              </div>
              <div className={styles.separator} />
              {props.previousPositionStudies?.map(positionStudy => {
                return (
                  <div key={`positionStudyLine_${positionStudy.positionStudyId}`}>
                    <div className={styles.positionStudyLine}>
                      <Radio
                        checked={
                          props.selectedPreviousPositionStudy?.positionStudyId ===
                          positionStudy.positionStudyId
                        }
                        onChange={checked =>
                          checked
                            ? props.setSelectedPreviousPositionStudy(positionStudy)
                            : props.setSelectedPreviousPositionStudy(undefined)
                        }
                      />

                      <p> {positionStudy.positionStudyName}</p>
                      <p>
                        {positionStudy.modificationDate
                          ? moment(positionStudy.modificationDate).format('DD/MM/Y')
                          : '--/--/--'}
                      </p>
                      <p>
                        <Circle
                          variant="fill"
                          color={positionStudyStatusToColor(
                            positionStudy.completionStatus ??
                              (positionStudy.resumeKeywords
                                ? EnumPositionStudyCompletionStatus[
                                    positionStudy.resumeKeywords as keyof typeof EnumPositionStudyCompletionStatus
                                  ]
                                : EnumPositionStudyCompletionStatus.MISSING_INFO)
                          )}
                        />
                      </p>
                    </div>
                    <div className={styles.separator} />
                  </div>
                );
              })}
            </>
          ) : (
            <div className={styles.error}>
              <img src={teaCup} alt="teacup" />
              <p className={styles.noResultTitle}>aucune étude de poste pour :</p>
              <p className={styles.noResultText}>
                - le compte {props.selectedCompany?.companyName}
              </p>
              <p className={styles.noResultText}>
                - la qualification {props.selectedQualification?.label}
              </p>
            </div>
          )}
        </>
      )}
      {props.previousPositionStudiesFetchStatus === FETCH_STATUS.REJECTED && (
        <div className={styles.noResult}>
          <ServerErrorYellow />
          <div className={styles.noResultText}>Une erreur est survenue</div>
        </div>
      )}
    </div>
  );
};

export default PreviousPositionStudies;
