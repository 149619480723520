import { Check, Danger } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Badge, Link, MenuItem } from '@randstad-lean-mobile-factory/react-components-core';
import { ExternalDemandStatusDto } from 'src/Services/API';

import styles from './StatusFilter.module.scss';
import { StatusFilterProps } from './StatusFilter.types';

export const StatusFilter = ({ filterValue, setFilterValue }: StatusFilterProps) => {
  return (
    <>
      <div className={styles.buttons}>
        <Link.XSmall
          text="tout sélectionner"
          onClick={() => setFilterValue(Object.values(ExternalDemandStatusDto))}
        />
        <Link.XSmall text="effacer" onClick={() => setFilterValue(undefined)} />
      </div>
      <div className={styles.items}>
        <MenuItem
          keepOpenOnClick
          selected={filterValue?.includes(ExternalDemandStatusDto.COMPLETE)}
          onClick={() => {
            setFilterValue((filterValue: (string | number)[] | undefined) => {
              if (!filterValue) return [ExternalDemandStatusDto.COMPLETE];
              if (!filterValue.includes(ExternalDemandStatusDto.COMPLETE))
                return [...filterValue, ExternalDemandStatusDto.COMPLETE];
              if (filterValue.length === 1) return undefined;
              else return filterValue.filter(v => v !== ExternalDemandStatusDto.COMPLETE);
            });
          }}
        >
          <Badge size="large" color="success">
            <Check />
          </Badge>
          créee
        </MenuItem>
        <MenuItem
          keepOpenOnClick
          selected={filterValue?.includes(ExternalDemandStatusDto.MIXED)}
          onClick={() => {
            setFilterValue((filterValue: (string | number)[] | undefined) => {
              if (!filterValue) return [ExternalDemandStatusDto.MIXED];
              if (!filterValue.includes(ExternalDemandStatusDto.MIXED))
                return [...filterValue, ExternalDemandStatusDto.MIXED];
              if (filterValue.length === 1) return undefined;
              else return filterValue.filter(v => v !== ExternalDemandStatusDto.MIXED);
            });
          }}
        >
          <Badge size="large" color="warning">
            <Danger />
          </Badge>
          alerte
        </MenuItem>
        <MenuItem
          keepOpenOnClick
          selected={filterValue?.includes(ExternalDemandStatusDto.ERROR)}
          onClick={() => {
            setFilterValue((filterValue: (string | number)[] | undefined) => {
              if (!filterValue) return [ExternalDemandStatusDto.ERROR];
              if (!filterValue.includes(ExternalDemandStatusDto.ERROR))
                return [...filterValue, ExternalDemandStatusDto.ERROR];
              if (filterValue.length === 1) return undefined;
              else return filterValue.filter(v => v !== ExternalDemandStatusDto.ERROR);
            });
          }}
        >
          <Badge size="large" color="error">
            <Danger />
          </Badge>
          en erreur
        </MenuItem>
      </div>
    </>
  );
};
