import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { QueryKeys } from '../types';
import { ExternalDemandsService, FetchExternalDemandsParams } from 'src/Services/API';

export const useFetchExternalDemands = (
  params: Omit<FetchExternalDemandsParams, 'agencyId'> & { page: number }
) => {
  const agencyId = useSelector(getCurrentAgency);

  const queryClient = useQueryClient();

  const query = useQuery(
    [QueryKeys.fetchExternalDemands, agencyId, params],
    async () => {
      return ExternalDemandsService.externalDemandsControllerFetchExternalDemands({
        agencyId,
        body: params,
      });
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  if (query.isSuccess) {
    queryClient.prefetchQuery({
      queryKey: [QueryKeys.fetchExternalDemands, agencyId, { ...params, page: params.page + 1 }],
      queryFn: async () => {
        return ExternalDemandsService.externalDemandsControllerFetchExternalDemands({
          agencyId,
          body: {
            ...params,
            page: params.page + 1,
          },
        });
      },
    });
  }

  return query;
};
