import { capitalizeNames } from './capitalizeNames';

export function formatName(person: undefined): undefined;
export function formatName(person: { firstName: string; name?: string; lastName?: string }): string;
export function formatName(person: { firstName?: string; name: string; lastName?: string }): string;
export function formatName(person: { firstName?: string; name?: string; lastName: string }): string;
export function formatName(person: {
  firstName?: string;
  name?: string;
  lastName?: string;
}): string | undefined;
export function formatName(
  person:
    | {
        firstName?: string;
        name?: string;
        lastName?: string;
      }
    | undefined
): string | undefined;
export function formatName(
  person:
    | {
        firstName?: string;
        name?: string;
        lastName?: string;
      }
    | undefined
): string | undefined {
  if (!person) return;
  if (!person.firstName && !person.name && !person.lastName) return;
  return capitalizeNames(
    [person.firstName, person.name || person.lastName].filter(Boolean).join(' ')
  );
}
