import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { YouplanMissionsService } from 'src/Services/API';

export const useCancelYouplanRelatedMission = (
  opts?: UseMutationOptions<unknown, unknown, string>
) => {
  const brandCode = useSelector(getCurrentBrandCode);

  return useMutation(async (missionId: string) => {
    return YouplanMissionsService.youplanMissionsControllerCancelRelatedMission({
      id: missionId,
      brandCode,
    });
  }, opts);
};
