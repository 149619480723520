import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { CompanySearchResult, CompaniesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCompanies = (
  keyword: string,
  extraOptions?: UseQueryOptions<CompanySearchResult[], unknown, CompanySearchResult[], string[]>
) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);

  return useQuery(
    [QueryKeys.fetchCompanies, keyword],
    async () => {
      return CompaniesService.companiesControllerGetCompanies({
        body: {
          keyword,
          brandCode,
          agencies: [agencyId],
        },
      });
    },
    {
      enabled: keyword !== '',
      ...extraOptions,
    }
  );
};
