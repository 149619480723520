import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { QueryKeys } from '../types';
import { AppelMedicalEventSourceDto, ExternalDemandsService } from 'src/Services/API';

export const useFetchMailParametersBySiret = (
  source: AppelMedicalEventSourceDto,
  siret: string,
  enabled: boolean
) => {
  const agencyId = useSelector(getCurrentAgency);

  return useQuery(
    [QueryKeys.fetchMailParametersBySiret, source, siret],
    async () => {
      return await ExternalDemandsService.externalDemandsControllerGetMailParametersBySiret({
        source,
        agencyId,
        siret: encodeURIComponent(siret),
      });
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    }
  );
};
