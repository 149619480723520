import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import invariant from 'invariant';

import { CandidateQualification, CandidatesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCandidateQualifications = (
  candidateId?: string,
  extraOptions?: UseQueryOptions<
    CandidateQualification[],
    unknown,
    CandidateQualification[],
    (string | undefined)[]
  >
) => {
  return useQuery(
    [QueryKeys.fetchCandidateQualifications, candidateId],
    async () => {
      invariant(candidateId, 'candidateId is missing');
      return await CandidatesService.candidatesControllerGetCandidateQualifications({
        id: candidateId,
      });
    },
    {
      enabled: Boolean(candidateId),
      staleTime: Infinity,
      ...extraOptions,
    }
  );
};
