import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import useAuth from 'src/Hooks/Authentication/useAuth';
import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { perimeterActions } from 'src/Redux/Perimeter/Slice';
import { ConsultantPerimeter, ConsultantsService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchPerimeter = (
  extraOptions?: Omit<
    UseQueryOptions<ConsultantPerimeter, AxiosError, ConsultantPerimeter, [QueryKeys]>,
    'queryKey' | 'queryFn'
  >
) => {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();

  const currentBrand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency);

  return useQuery(
    [QueryKeys.fetchPerimeter],
    async () => {
      const perimeter = await ConsultantsService.consultantsControllerGetPerimeter();

      dispatch(perimeterActions.setUserInfo(perimeter));

      const company = perimeter.companies.find(
        company => company.brandCodeApiHeader === currentBrand?.brandCodeApiHeader
      );
      const agency = company?.agencies.find(agency => agency === currentAgency);
      if (company && agency)
        dispatch(perimeterActions.setSelectedPerimeter({ brand: company, agencyId: agency }));
      else dispatch(perimeterActions.clearPerimeter());

      return perimeter;
    },
    {
      retry: false,
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      ...extraOptions,
    }
  );
};
