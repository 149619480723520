import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { AgencyCandidatesWithCount, CandidatesService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchAgencyCandidates = (
  keyword?: string,
  extraOptions?: UseQueryOptions<
    AgencyCandidatesWithCount,
    unknown,
    AgencyCandidatesWithCount,
    (string | undefined)[]
  >
) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);

  return useQuery(
    [QueryKeys.fetchAgencyCandidates, keyword],
    async () => {
      return CandidatesService.candidatesControllerGetAgencyCandidates({
        keyword: keyword,
        brandCode,
        agencyId,
      });
    },
    { enabled: keyword !== '', ...extraOptions }
  );
};
