import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { QueryKeys } from 'src/Hooks/types';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { Action } from 'src/Services/API';
import { MutationKeys } from '../types';
import { ActionsService } from './../../Services/API/index';

export const useUpdateAction = (extraOptions?: UseMutationOptions<void, unknown, Action>) => {
  const brandCode = useSelector(getCurrentBrandCode);

  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.updateAction],
    async (action: Action) => {
      return ActionsService.actionsControllerUpdateAction({
        id: action.actionId ?? '',
        body: { report: action.report ?? '', brandCode },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchActionDetail] });
      },
      ...extraOptions,
    }
  );
};
