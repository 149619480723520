import {
  Button,
  Checkbox,
  ModalActions,
  ModalContent,
  TextArea,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';
import { useEditCandidateMemo } from 'src/Hooks/Candidate/useEditCandidateMemo';
import { CreateCandidateMemoModalProps } from './EditCandidateMemoModal.types';
import styles from './EditCandidateMemoModal.module.scss';

export const EditCandidateMemoModal = ({ item: memo, close }: CreateCandidateMemoModalProps) => {
  const [comment, setComment] = useState(memo.comment);
  const [isBehaviorMemo, setIsBehaviorMemo] = useState(memo.isBehaviorMemo);
  const editCandidateMemo = useEditCandidateMemo(memo.memoId, memo.candidateId, {
    onSuccess: () => {
      setTimeout(() => close(), 500);
    },
    onError: () => {
      setTimeout(() => editCandidateMemo.reset(), 500);
    },
  });

  return (
    <>
      <ModalContent
        title="Modifier un mémo"
        description={
          <a
            href="https://drive.google.com/file/d/1SDuEoSZdM5I50EIUm1vGjfXIHqBYJMBr/view"
            target="_blank"
            rel="noreferrer"
            className={styles.memoGuideRedirect}
          >
            Ouvrir le guide de saisie des mémos
          </a>
        }
      >
        <WithLightTitle title="commentaire (1 caractère min)" className={styles.textAreaTitle}>
          <TextArea
            placeholder="aucun commentaire renseigné"
            className={styles.textArea}
            value={comment}
            minLength={1}
            maxLength={200}
            onChange={event => setComment((event.target as HTMLTextAreaElement).value)}
          />
        </WithLightTitle>
        <Checkbox
          label="le mémo est à caractère disciplinaire"
          checked={isBehaviorMemo}
          onClick={() => {
            setIsBehaviorMemo(!isBehaviorMemo);
          }}
        />
      </ModalContent>
      <ModalActions side="right">
        <Button variant="secondary" onClick={close}>
          annuler
        </Button>
        <Button
          disabled={comment.length < 1}
          onClick={() => editCandidateMemo.mutate({ comment, isBehaviorMemo })}
          mutationStatus={editCandidateMemo.status}
        >
          envoyer
        </Button>
      </ModalActions>
    </>
  );
};
