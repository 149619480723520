import { ThunkDispatch } from '@reduxjs/toolkit';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import {
  getPreviousMissions,
  getPreviousMissionsFetchStatus,
  getSelectedCompany,
  getSelectedPreviousMission,
  getSelectedQualification,
} from 'src/Redux/MissionCreation/Selectors';
import { missionCreationActions } from 'src/Redux/MissionCreation/Slice';
import { searchPreviousMissions } from 'src/Redux/MissionCreation/Thunks';
import { RootState } from 'src/Redux/RootReducer';
import { CompanySearchResult, Mission, Qualification } from 'src/Services/API';
import PreviousMissions from './PreviousMissions.component';

const MemoPreviousMissions = React.memo(PreviousMissions);

const EnhancedPreviousMissions = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const selectedCompany = useSelector(getSelectedCompany);
  const selectedQualification = useSelector(getSelectedQualification);
  const selectedPreviousMission = useSelector(getSelectedPreviousMission);
  const previousMissions = useSelector(getPreviousMissions);
  const previousMissionsFetchStatus = useSelector(getPreviousMissionsFetchStatus);

  const setSelectedCompany = useCallback(
    (company?: CompanySearchResult) => {
      dispatch(missionCreationActions.setSelectedCompany(company));
      dispatch(missionCreationActions.resetSelectedPreviousMission());
      dispatch(searchPreviousMissions());
    },
    [dispatch]
  );
  const setSelectedQualification = useCallback(
    (qualification?: Qualification) => {
      dispatch(missionCreationActions.setSelectedQualification(qualification));
      dispatch(missionCreationActions.resetSelectedPreviousMission());
      dispatch(searchPreviousMissions());
    },
    [dispatch]
  );
  const setSelectedPreviousMission = useCallback(
    (previousMission?: Mission) => {
      dispatch(missionCreationActions.setSelectedPreviousMission(previousMission));
    },
    [dispatch]
  );
  return (
    <MemoPreviousMissions
      selectedCompany={selectedCompany}
      selectedQualification={selectedQualification}
      previousMissions={previousMissions}
      selectedPreviousMission={selectedPreviousMission}
      previousMissionsFetchStatus={previousMissionsFetchStatus}
      setSelectedCompany={setSelectedCompany}
      setSelectedQualification={setSelectedQualification}
      setSelectedPreviousMission={setSelectedPreviousMission}
    />
  );
};

export default EnhancedPreviousMissions;
