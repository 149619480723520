import { useNavigate } from 'react-router-dom';

import { Nouveaute } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import {
  Button,
  ModalActions,
  ModalContent,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { useUpdateExternalDemands } from 'src/Hooks/ExternalDemands/useUpdateExternalDemands';
import { useFetchMissionDetails } from 'src/Hooks/Missions/useFetchMissionDetails';
import { ROUTES } from 'src/Routes/Routes.types';
import { DisplayStatusDto } from 'src/Services/API';
import { formatStatus } from '../ExternalDemandsList/utils';
import { ExternalDemandStatusBadge } from '../ExternalDemandStatusBadge';
import styles from './DemandDetails.module.scss';
import { DemandDetailsProps } from './DemandDetails.types';
import { useMemo } from 'react';
import { formatName } from 'src/Utils/formatName';

export const DemandDetails = ({ item: externalDemand, close }: DemandDetailsProps) => {
  const navigate = useNavigate();

  const updateExternalDemandsMutation = useUpdateExternalDemands();

  const { data } = useFetchMissionDetails(externalDemand.commandId);

  const dateMission = useMemo(() => {
    const dates = externalDemand.events.map(event => moment(event.dateMission));
    const firstDate = moment.min(dates).startOf('day');
    const lastDate = moment.max(dates).startOf('day');
    if (firstDate.isSame(lastDate)) return firstDate.tz('CET').format('[le] DD/MM');
    return `du ${firstDate.tz('CET').format('DD/MM')} au ${lastDate.tz('CET').format('DD/MM')}`;
  }, [externalDemand.events]);

  const limitDate = useMemo(() => {
    const dates = externalDemand.events
      .map(event => event.limitDate && moment(event.limitDate))
      .filter(Boolean);
    const lastDate = moment.max(dates);
    return lastDate.tz('UTC').format('DD MMMM YYYY [à] HH:mm');
  }, [externalDemand.events]);

  return (
    <>
      <ModalContent
        header={<Nouveaute />}
        title={`détails de la demande ${externalDemand.commandId}`}
      >
        <div className={styles.grid}>
          <WithLightTitle title="statut" className={styles.status}>
            <div>
              <ExternalDemandStatusBadge element={externalDemand} />
              <div>{formatStatus(externalDemand)}</div>
            </div>
          </WithLightTitle>
          <WithLightTitle title="établissement">
            {externalDemand.clientName ?? 'information inconnue'}
          </WithLightTitle>
          <WithLightTitle title="siret">
            {externalDemand.siret ?? 'information inconnue'}
          </WithLightTitle>
          <WithLightTitle title="domaine">
            {externalDemand.domain ?? 'information inconnue'}
          </WithLightTitle>
          <WithLightTitle title="qualification">
            {externalDemand.qualification ?? 'information inconnue'}
          </WithLightTitle>
          <WithLightTitle title="service">
            {externalDemand.service ?? 'information inconnue'}
          </WithLightTitle>
          {externalDemand.events.length === 1 ? (
            <WithLightTitle title="date et heure">
              <div>{moment(externalDemand.events[0].dateMission).format('DD MMMM YYYY')}</div>
              <div>{externalDemand.events[0].schedule}</div>
            </WithLightTitle>
          ) : (
            <WithLightTitle title="dates et roulements">
              {dateMission} - {externalDemand.events.length} roulements
            </WithLightTitle>
          )}
          <WithLightTitle title="admin">
            {formatName(data?.contact) ?? 'information inconnue'}
          </WithLightTitle>
          <WithLightTitle title="numéro commande">{externalDemand.commandId}</WithLightTitle>
          <WithLightTitle title="email">
            {data?.contact?.email ?? 'information inconnue'}
          </WithLightTitle>
          <WithLightTitle title="date limite de traitement">{limitDate}</WithLightTitle>
        </div>
      </ModalContent>
      <ModalActions side="left">
        <Button variant="tertiary" size="medium" onClick={close}>
          fermer
        </Button>
      </ModalActions>
      <ModalActions side="right">
        <Button
          variant="secondary"
          size="medium"
          onClick={() =>
            navigate(ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES.buildPath({}), {
              state: ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES.buildState({
                siret: externalDemand.siret,
              }),
            })
          }
        >
          voir les correspondances
        </Button>
        <Button
          variant="primary"
          size="medium"
          onClick={() =>
            updateExternalDemandsMutation.mutate({
              commandStatusToUpdate: [
                {
                  commandId: externalDemand.commandId,
                  displayStatus: DisplayStatusDto.archived,
                },
              ],
            })
          }
          mutationStatus={updateExternalDemandsMutation.status}
        >
          archiver la demande
        </Button>
      </ModalActions>
    </>
  );
};
