import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import invariant from 'invariant';
import { useSelector } from 'react-redux';

import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { CompaniesService, CompanyAction } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCompanyActions = (
  companyId?: string,
  agencyId?: string,
  extraOptions?: UseQueryOptions<CompanyAction[], unknown, CompanyAction[], any>
) => {
  const brandCode = useSelector(getCurrentBrandCode);

  return useQuery(
    [QueryKeys.fetchCompanyActions, companyId, agencyId],
    async () => {
      invariant(companyId, 'companyId is missing');
      invariant(agencyId, 'agencyId is missing');
      return await CompaniesService.companiesControllerGetCompanyLastActions({
        companyId,
        body: { agency: agencyId, brandCode },
      });
    },
    { ...extraOptions, enabled: Boolean(companyId && agencyId) }
  );
};
