import { useEffect, useState } from 'react';

import {
  BadgeDeprecated,
  HorizontalCheckboxCard,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { LeftArrow, RightArrow } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment';
import ContentLoader from 'react-content-loader';
import { useDispatch, useSelector } from 'react-redux';
import { binocular } from 'src/Assets_DEPRECATED';
import { Loading } from 'src/Components/Card/Card.stories';
import { useFetchCandidateDetails } from 'src/Hooks/Candidate/useFetchCandidateDetails';
import { useFetchSuggestedCandidates } from 'src/Hooks/Candidate/useFetchSuggestedCandidates';
import { getCandidateId, getYouplanMissionCandidates } from 'src/Redux/MissionCreation/Selectors';
import { missionCreationActions } from 'src/Redux/MissionCreation/Slice';
import { FETCH_STATUS } from 'src/Redux/Types';
import {
  CandidateSearchActivityPlaning,
  CoreMissionCandidate,
  MissionDetail,
} from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { formatAddress } from 'src/Utils/address';
import { OSMToTimeDisplay } from 'src/Utils/datetime';
import styles from './MissionCreationValidation.module.scss';
import { Props } from './MissionCreationValidation.types';
import { formatName } from 'src/Utils/formatName';

const weekDays = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

const additionalInformation = (processedMission: MissionDetail) => {
  return [
    {
      title: 'formation diplôme',
      information: processedMission.diplomas?.map(diploma => diploma.label),
    },
    {
      title: 'tests métier / sécurité',
      information: processedMission.mase?.securityBusinessTest
        ? processedMission.structuredSecurityBusinessTest
        : undefined,
    },
    {
      title: 'permis de conduire',
      information: processedMission.mase?.drivingLicenseList?.map(
        license => `${license.id} - ${license.label}`
      ),
    },
    {
      title: 'habilitations CACES / autorisations nécessaires',
      information: processedMission.habilitations?.map(habilitation => habilitation.label),
    },
    {
      title: 'équipements de protection individuelle',
      information: processedMission.mase?.otherEquipment
        ? processedMission.structuredIndividualEquipement
        : undefined,
    },
    {
      title: 'équipements de protection collective',
      information: processedMission.mase?.collectiveProtectionEquipment
        ? processedMission.structuredCollectiveEquipment
        : undefined,
    },
  ];
};

const hasAdditionalInformations = (mission: MissionDetail) => {
  const infos = additionalInformation(mission);
  return infos.some(info => {
    return (
      info.information !== undefined &&
      Array.isArray(info.information) &&
      info.information.length > 0
    );
  });
};

const MissionCreationValidation = (props: Props) => {
  const [page, setPage] = useState(1);
  const { data: cdiiCandidates, isLoading } = useFetchSuggestedCandidates({
    qualificationId: props.selectedQualification?.id ?? '',
    zipCode: props.processedMission.workLocation?.zipCode ?? '',
  });
  const dispatch = useDispatch();

  const candidateId = useSelector(getCandidateId);
  const candidateDetails = useFetchCandidateDetails(candidateId);
  const youPlanCandidates = useSelector(getYouplanMissionCandidates);
  const candidateDetailsFetchStatus = toFetchStatus(candidateDetails);
  const hasSecondPage = hasAdditionalInformations(props.processedMission);
  const definedMissionHours = props.processedMission.structuredMissionHours.filter(
    workingHour => workingHour.days.length > 0
  );
  const totalamountOfPages = hasSecondPage ? 3 : 2;

  const [selectedCdiis, setSelectedCdiis] = useState<CandidateSearchActivityPlaning[]>([]);

  const fullAddress = formatAddress({
    address: [
      props.selectedCompany?.companyAddress?.address1,
      props.selectedCompany?.companyAddress?.address2,
      props.selectedCompany?.companyAddress?.address3,
    ]
      .filter(Boolean)
      .join(', '),
    cityName: props.selectedCompany?.companyAddress?.city,
    zipCode: props.selectedCompany?.companyAddress?.postalCode,
  });
  const workLocation = formatAddress(props.processedMission.workLocation);

  useEffect(() => {
    dispatch(
      missionCreationActions.setYouPlanMissionCandidates([
        ...youPlanCandidates,
        ...selectedCdiis.map(candidate => {
          return { ...candidate, candidateId: candidate.id } as CoreMissionCandidate;
        }),
      ])
    );
  }, [dispatch, selectedCdiis, youPlanCandidates]);

  return (
    <div className={styles.container}>
      <div className={styles.pageToolContainer}>
        <div className={styles.pageSituationDisplay}>
          {`page ${page}/${totalamountOfPages}`}
          <div className={styles.dotContainer}>
            {Array.from({ length: totalamountOfPages }, (_, index) => (
              <div
                key={index}
                className={classnames(styles.dot, {
                  [styles.selectedDot]: page === index + 1,
                })}
              />
            ))}
          </div>
        </div>
        <div className={styles.navigationArrows}>
          <div className={classnames(styles.arrow, { [styles.unselectedArrow]: page === 1 })}>
            <LeftArrow onClick={() => setPage(prevState => prevState - 1)} />
          </div>
          <div
            className={classnames(styles.arrow, {
              [styles.unselectedArrow]: page === totalamountOfPages,
            })}
          >
            <RightArrow onClick={() => setPage(prevState => prevState + 1)} />
          </div>
        </div>
      </div>
      {page === 1 && (
        <>
          <WithLightTitle className={styles.element} title="compte">
            {props.selectedCompany?.companyName}
          </WithLightTitle>
          <WithLightTitle className={styles.element} title="adresse compte">
            {fullAddress ?? "pas d'adresse renseignée"}
          </WithLightTitle>
          <WithLightTitle className={styles.element} title="adresse délégation">
            {workLocation || "pas d'adresse renseignée"}
          </WithLightTitle>
          <div className={styles.separator} />
          <div className={styles.element}>
            <div className={styles.lineMultipleElement}>
              <WithLightTitle className={styles.subElement} title="qualification">
                {props.selectedQualification?.label}
              </WithLightTitle>
              <WithLightTitle className={styles.subElement} title="nombre de TT à servir">
                {props.numberPositions ?? 'non précisé'}
              </WithLightTitle>
              {candidateId !== undefined && (
                <WithLightTitle title="CDII rattaché à la mission">
                  {candidateDetailsFetchStatus === FETCH_STATUS.FULFILLED ? (
                    formatName(candidateDetails.data)
                  ) : (
                    <ContentLoader className={styles.contentLoader} />
                  )}
                </WithLightTitle>
              )}
            </div>
          </div>
          <div className={styles.element}>
            <div className={styles.lineMultipleElement}>
              <WithLightTitle className={styles.subElement} title="interlocuteur">
                {formatName(props.processedMission?.contact)}
              </WithLightTitle>
              <WithLightTitle className={styles.subElement} title="suivi par">
                {formatName({ firstName: props.userFirstName, lastName: props.userLastName })}
              </WithLightTitle>
            </div>
          </div>
          <div className={styles.separator} />
          <div className={styles.element}>
            <div className={styles.lineMultipleElement}>
              <WithLightTitle className={styles.subElement} title="début">
                {`${
                  props.processedMission?.startDate
                    ? moment(props.processedMission?.startDate).locale('fr').format('LL')
                    : 'date non précisée'
                } à ${
                  props.processedMission?.startHour
                    ? OSMToTimeDisplay(props.processedMission?.startHour)
                    : 'heure non précisée'
                }`}
              </WithLightTitle>
              <WithLightTitle className={styles.subElement} title="fin">
                {props.processedMission?.endDate
                  ? moment(props.processedMission?.endDate).locale('fr').format('LL')
                  : 'date non précisée'}
              </WithLightTitle>
            </div>
            <WithLightTitle className={styles.element} title="horaires">
              {definedMissionHours.map(workingTime => (
                <div>
                  {`${workingTime.days?.map(day => weekDays[day - 1])} de ${OSMToTimeDisplay(
                    workingTime.startTime ?? 0
                  )} à ${OSMToTimeDisplay(workingTime.endTime ?? 0)}`}
                </div>
              ))}
              {definedMissionHours.length === 0 && 'horaires non précisés'}
            </WithLightTitle>
            {props.processedMission?.reasonLabel && (
              <WithLightTitle className={styles.element} title="motif">
                {props.processedMission.reasonLabel}
              </WithLightTitle>
            )}
            {props.processedMission?.comment && (
              <>
                <div className={styles.separator} />
                <WithLightTitle className={styles.element} title="commentaire public">
                  <div className={styles.multiLine}>{props.processedMission.comment}</div>
                </WithLightTitle>
              </>
            )}
          </div>
        </>
      )}
      {page === 2 && hasSecondPage && (
        <>
          {additionalInformation(props.processedMission).map(
            section =>
              section.information &&
              section.information.length > 0 && (
                <WithLightTitle
                  key={section.title}
                  title={section.title}
                  className={styles.element}
                >
                  <ul>
                    {section.information.map((information, idx) => (
                      <li key={`${idx}-${information}`} className={styles.listElement}>
                        {information}
                      </li>
                    ))}
                  </ul>
                </WithLightTitle>
              )
          )}
        </>
      )}
      {page === totalamountOfPages && (
        <>
          {!isLoading ? (
            <div className={styles.cdiiSuggestionMainContainer}>
              <div className={styles.cdiiSuggestionText}>
                voici une liste des cdii disponibles pour cette commande. Sélectionnez les profils
                qui pourraient répondre à la commande.
              </div>
              {cdiiCandidates?.length !== 0 ? (
                <div className={styles.cdiiSuggestionContainer}>
                  {cdiiCandidates?.map(candidate => {
                    const isSelected = !selectedCdiis.every(cdii => cdii.id !== candidate.id);
                    return (
                      <HorizontalCheckboxCard
                        variant="beige"
                        selected={isSelected}
                        onSelectionChange={() => {
                          isSelected
                            ? setSelectedCdiis(prevState =>
                                prevState.filter(cdii => cdii.id !== candidate.id)
                              )
                            : setSelectedCdiis(prevState => [...prevState, candidate]);
                        }}
                        title=""
                        subtitles={[
                          candidate.mainAgency?.name ?? "pas d'unité",
                          formatName(candidate),
                          candidate.mainQualification?.label ?? 'pas de qualification principale',
                          [
                            [
                              candidate.candidateAddress?.candidatePostalCode,
                              candidate.candidateAddress?.candidateCityName,
                            ]
                              .filter(Boolean)
                              .join(' '),
                            candidate.candidateAddress?.distance
                              ? `${candidate.candidateAddress.distance.toFixed()} km`
                              : undefined,
                          ]
                            .filter(Boolean)
                            .join(' - '),
                        ].filter(Boolean)}
                        rightActions={
                          <BadgeDeprecated
                            className={styles.cdiiCardLabelChip}
                            value={`dispo le ${moment().format('DD/MM/YYYY')}`}
                          />
                        }
                        leftSideClassName={styles.cdiiCardLeftSide}
                        titleClassName={styles.cdiiCardTitle}
                      />
                    );
                  })}
                </div>
              ) : (
                <div className={styles.cdiiSuggestionNoResults}>
                  <img src={binocular} className={styles.noResultImage} alt="no result" />
                  aucun profil ne correspond à cette commande
                </div>
              )}
            </div>
          ) : (
            <div className={styles.cdiiSuggestionContainerLoading}>
              <Loading />
              <Loading />
              <Loading />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MissionCreationValidation;
