import {
  Badge,
  BadgeDeprecated,
  BadgeDropdown,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useQueryClient } from '@tanstack/react-query';
import classnames from 'classnames';
import { startOfWeek } from 'date-fns';
import moment from 'moment';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFetchRelatedCGC } from 'src/Hooks/Agencies/useFetchRelatedCGC';
import { useChangeManuallyCandidateRepoStatus } from 'src/Hooks/Candidate/useChangeManuallyCandidateRepoStatus';
import { useFetchCandidateActivityPlanning } from 'src/Hooks/Candidate/useFetchCandidateActivityPlanning';
import { useFetchCandidateDetails } from 'src/Hooks/Candidate/useFetchCandidateDetails';
import { useIsCGC } from 'src/Hooks/SkillsManagementCenters/useIsCGC';
import { QueryKeys } from 'src/Hooks/types';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { CDII_ACTIONS, CDII_STATUS } from 'src/Routes/Talents/Cdii/Cdii.types';
import { getEndMission, getRepositionedStatus } from 'src/Routes/Talents/Cdii/utils';
import { formatName } from 'src/Utils/formatName';
import styles from './CdiiDetail.module.scss';
import { CdiiDetailProps } from './CdiiDetail.types';
import { CdiiDetailSection } from './CdiiDetailSection';

export const getRepositioningStatusColor = (status: CDII_STATUS | CDII_ACTIONS) => {
  switch (status) {
    case CDII_STATUS.AVAILABLE:
      return 'blue';
    case CDII_STATUS.SUGGESTED:
      return 'greenish';
    case CDII_STATUS.PROCESSING:
      return 'information';
    default:
      return 'blue';
  }
};

export const CdiiDetailScreen = ({ candidateId, children }: PropsWithChildren<CdiiDetailProps>) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: isCGC } = useIsCGC();
  const [planningStartDate, setPlanningStartDate] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [fetchingStartDate, setFetchingStartDate] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const relatedCGCQuery = useFetchRelatedCGC();
  const agencyId = useSelector(getCurrentAgency);
  const changeManuallyCandidateRepoStatusMutation = useChangeManuallyCandidateRepoStatus();

  const { data: candidateActivityPlanning, isLoading } = useFetchCandidateActivityPlanning({
    candidateId,
    startDate: fetchingStartDate,
  });
  const { data: detailedCandidate } = useFetchCandidateDetails(candidateId);
  const repositionedCdiiStatus = getRepositionedStatus(candidateActivityPlanning?.cdiiStatus);
  const listStatus = [
    { key: CDII_STATUS.AVAILABLE, className: styles.badgeAvailable },
    { key: CDII_STATUS.SUGGESTED, className: styles.badgeSuggested },
    { key: CDII_STATUS.PROCESSING, className: styles.badgeProcessing },
    { key: CDII_ACTIONS.EMPTY_STATUS, className: styles.badgeEmpty },
    { key: CDII_ACTIONS.DENY, className: styles.badgeEmpty },
  ];

  const [repoStatus, setRepoStatus] = useState(
    listStatus.find(item => item.key === repositionedCdiiStatus)
  );

  const suggestedMissionsIds = candidateActivityPlanning?.suggestedMissionIds ?? [];
  const cdiiStatus = useMemo(
    () =>
      getEndMission(
        candidateActivityPlanning?.lastContract?.endDate,
        candidateActivityPlanning?.activityPlanning?.find(
          day => moment(day.date)?.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
        )?.status
      ),
    [candidateActivityPlanning]
  );

  useEffect(() => {
    setRepoStatus(listStatus.find(item => item.key === repositionedCdiiStatus));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repositionedCdiiStatus]);

  useEffect(() => {
    if (
      moment(planningStartDate).isBefore(moment(fetchingStartDate)) ||
      moment(planningStartDate).isAfter(
        moment(fetchingStartDate).add(3, 'weeks').subtract(1, 'day')
      )
    ) {
      setFetchingStartDate(planningStartDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planningStartDate]);

  useEffect(() => {
    if (candidateActivityPlanning && moment(planningStartDate).isAfter(moment(fetchingStartDate))) {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchCandidateActivityPlanning] });
      setFetchingStartDate(planningStartDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planningStartDate]);

  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        <div className={styles.titleContainer}>
          {isLoading ? (
            <ContentLoader height="4rem" width="100%">
              <rect x="0" y="10" rx="4" ry="4" width="150" height="24" />
              <rect x="0" y="48" rx="4" ry="4" width="100" height="8" />
            </ContentLoader>
          ) : !candidateActivityPlanning ? (
            <div>une erreur s'est produite</div>
          ) : (
            <>
              <div className={styles.name}>{formatName(detailedCandidate)}</div>
              <div className={styles.agency}>
                {candidateActivityPlanning.lastContract?.agency?.id &&
                  candidateActivityPlanning.lastContract.agency.id + ' |'}
                {cdiiStatus && (
                  <BadgeDeprecated
                    value={cdiiStatus}
                    className={classnames({
                      [styles.badgeInMission]: cdiiStatus === CDII_STATUS.MISSION,
                      [styles.badgeSoonAvailable]: cdiiStatus === CDII_STATUS.SOON_AVAILABLE,
                      [styles.badgeIntermission]: cdiiStatus === CDII_STATUS.INTERMISSION,
                      [styles.badgeAbsent]: cdiiStatus === CDII_STATUS.ABSENT,
                    })}
                  />
                )}
                {repoStatus !== undefined &&
                  (isCGC ? (
                    <BadgeDropdown
                      items={listStatus
                        .filter(status => {
                          if (
                            status.key !== CDII_STATUS.AVAILABLE ||
                            status.key === repoStatus.key
                          ) {
                            switch (repoStatus.key) {
                              case CDII_STATUS.AVAILABLE:
                                return (
                                  status.key !== CDII_STATUS.PROCESSING &&
                                  status.key !== CDII_STATUS.SUGGESTED
                                );
                              default:
                                return true;
                            }
                          }
                          return false;
                        })
                        .filter(status => {
                          if (
                            status.key === CDII_ACTIONS.DENY &&
                            (!candidateActivityPlanning.suggestedAgencyIds?.[0] || isCGC)
                          ) {
                            return false;
                          }
                          return !(!isCGC && status.key !== CDII_ACTIONS.DENY);
                        })}
                      width={180}
                      keyValueExtractor={(searchresult: {
                        key: CDII_STATUS | string;
                        className: string;
                      }) => {
                        return {
                          key: searchresult.key,
                          value: searchresult.key,
                          className: searchresult.className,
                        };
                      }}
                      selectedItem={repoStatus}
                      onSelectItem={(searchresult: {
                        key: CDII_STATUS | string;
                        className: string;
                      }) => {
                        searchresult.key === CDII_STATUS.PROCESSING &&
                        repoStatus.key !== CDII_STATUS.PROCESSING
                          ? navigate(MODAL_ROUTES.ATTACH_SUGGESTED_CANDIDATE.route.buildPath({}), {
                              state: {
                                background: location,
                                modalParameters: {
                                  missionIds: candidateActivityPlanning?.suggestedMissionIds,
                                  candidate: candidateActivityPlanning,
                                },
                              },
                            })
                          : searchresult.key === CDII_ACTIONS.DENY
                          ? navigate(MODAL_ROUTES.REFUSE_SUGGESTED_CANDIDATE.route.buildPath({}), {
                              state: {
                                background: location,
                                modalParameters: {
                                  ...candidateActivityPlanning,
                                  ...detailedCandidate,
                                },
                              },
                            })
                          : searchresult.key !== repoStatus.key &&
                            changeManuallyCandidateRepoStatusMutation.mutate({
                              candidateId: candidateId,
                              firstName: candidateActivityPlanning?.firstName,
                              lastName: candidateActivityPlanning?.name,
                              cgcId: isCGC ? agencyId : relatedCGCQuery.data?.id ?? '',
                              comment: '',
                              suggestedMissionsIds: suggestedMissionsIds,
                              refuseAll: true,
                              oldStatus: repoStatus.key,
                              newStatus: searchresult.key,
                            });
                      }}
                    />
                  ) : (
                    <Badge
                      className={styles.badge}
                      color={getRepositioningStatusColor(repoStatus.key)}
                    >{`${repoStatus.key}`}</Badge>
                  ))}
              </div>
            </>
          )}
        </div>
        {children}
      </div>
      <CdiiDetailSection
        candidateId={candidateId}
        lastContractEndDate={candidateActivityPlanning?.lastContract?.endDate}
        fetchingStartDate={fetchingStartDate}
        planningStartDate={planningStartDate}
        setPlanningStartDate={setPlanningStartDate}
        activityPlanning={candidateActivityPlanning}
      />
    </div>
  );
};
