import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { Signatory } from 'src/Routes/Accidentology/WorkAccidentAnalysis/WorkAccidentAnalysisDetail/Conclusion/Conclusion.types';
import { MutationKeys, QueryKeys } from '../types';
import { WorkAccidentAnalysisService } from './../../Services/API/index';
import { signatoryToSignatureNameFile } from 'src/Routes/Accidentology/WorkAccidentAnalysis/WorkAccidentAnalysisDetail/Conclusion/SignatureModals/CreateSignatureModal/CreateSignatureModal.types';

export const useUpdateWorkAccidentConclusionSignature = (
  analysisId: string,
  signatory: Signatory,
  extraOptions?: UseMutationOptions<void, unknown, string | undefined, unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.updateWorkAccidentAnalysisConclusionSignature],
    async (signatureDataUri?: string) => {
      const file = !signatureDataUri
        ? undefined
        : await fetch(signatureDataUri)
            .then(response => response.blob())
            .then(
              blob =>
                new File([blob], signatoryToSignatureNameFile[signatory], {
                  type: 'image/png',
                })
            );

      await WorkAccidentAnalysisService.workAccidentAnalysisControllerUpdateWorkAccidentConclusionSignature(
        {
          id: analysisId,
          file,
          signatory,
        }
      );
    },
    {
      ...extraOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchWorkAccidentById] });
        extraOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};
