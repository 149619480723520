import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { QueryKeys } from '../types';
import { ExternalDemandsService } from 'src/Services/API';

export const useFetchUniqueSirets = () => {
  const agencyId = useSelector(getCurrentAgency);

  return useQuery(
    [QueryKeys.fetchUniqueSirets],
    async () => {
      return await ExternalDemandsService.externalDemandsControllerFetchUniqueSirets({
        agencyId,
      });
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};
