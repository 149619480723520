import { ServerErrorRed } from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import { Loader } from '@randstad-lean-mobile-factory/react-components-core';
import { Outlet } from 'react-router-dom';
import { useFetchAgencyBrandCode } from 'src/Hooks/ExternalDemands/useFetchAgencyBrandCode';
import { DemandsInit } from './DemandsInit';
import styles from './ExternalDemands.module.scss';

export const ExternalDemandsRoute = () => {
  const { data, isLoading, isError } = useFetchAgencyBrandCode();

  return (
    <>
      {isLoading ? (
        <div className={styles.loadingScreen}>
          <Loader heightInRem={4} className={styles.loader} />
          <div>chargement des demandes externes, cela peut prendre du temps</div>
        </div>
      ) : isError ? (
        <div className={styles.error}>
          <ServerErrorRed />
          désolé, une erreur s'est produite
        </div>
      ) : data === '' ? (
        <DemandsInit />
      ) : (
        <Outlet />
      )}
    </>
  );
};
