import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/Redux/RootReducer';
import { WithMoment } from 'src/Redux/Types';
import { MissionDetail } from 'src/Services/API';
import { CandidateWithMoment, ContactingInfoWithMoment, ContactWithMoment } from '../Types';
import { MissionsState } from './Types';

const getMissionsState = (state: RootState) => state.missions;

/** Remove this and pass with props */
export const getCurrentMissionId = createSelector(
  [getMissionsState],
  (state: MissionsState): string => state.currentMissionId
);

export const getRepositioningMissionsSortBy = createSelector(
  [getMissionsState],
  (state: MissionsState) => state.repositioningMissionsSortBy
);

export const getMissionsSortOrder = createSelector(
  [getMissionsState],
  (state: MissionsState) => state.sortOrder
);

export const getMissionsSortBy = createSelector(
  [getMissionsState],
  (state: MissionsState) => state.missionsSortBy
);

export interface MissionDetailWithMoment
  extends Omit<
    WithMoment<MissionDetail, 'startDate' | 'endDate'>,
    'candidates' | 'contact' | 'lastClientContact'
  > {
  candidates?: CandidateWithMoment[];
  contact: ContactWithMoment;
  lastClientContact?: ContactingInfoWithMoment;
}
