import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { YouplanMissionsService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useRefuseCandidateFromYouplanRelatedMission = (
  extraOptions?: UseMutationOptions<unknown, unknown, { missionId: string; candidateId: string }>
) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ missionId, candidateId }: { missionId: string; candidateId: string }) => {
      if (!missionId) {
        throw new Error('Invalid values');
      }
      return YouplanMissionsService.youplanMissionsControllerRefuseCandidateFromYouplanRelatedMission(
        { id: missionId, candidateId, brandCode }
      );
    },
    {
      ...extraOptions,
      onSuccess: () => {
        toast.success('le candidat a été retiré de la commande');
      },
      onError: () => {
        toast.error('impossible de retirer le candidat de cette commande');
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchYouplanMissions] });
      },
    }
  );
};
