import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';

import { Domain, RepositoryService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchDomains = (
  extraOptions?: UseQueryOptions<Domain[], unknown, Domain[], any>
) => {
  const brandCode = useSelector(getCurrentBrandCode);

  return useQuery(
    [QueryKeys.fetchDomains],
    async () => {
      const domains = await RepositoryService.repositoryControllerGetDomains({
        brandCode,
      });
      return domains.sort((a, b) =>
        // eslint-disable-next-line no-restricted-syntax
        a.shortLabel === b.shortLabel ? 0 : (a.shortLabel ?? '') < (b.shortLabel ?? '') ? -1 : 1
      );
    },
    { ...extraOptions }
  );
};
