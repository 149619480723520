import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { missionCreationActions } from 'src/Redux/MissionCreation/Slice';

import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { perimeterActions } from 'src/Redux/Perimeter/Slice';
import { RootState } from 'src/Redux/RootReducer';
import { PositionstudiesService } from 'src/Services/API';
import { positionStudyToMissionCompany } from 'src/Utils/positionStudies.helper';
import { useFetchPerimeter } from '../Consultant/useFetchPerimeter';
import { MutationKeys } from '../types';

export const useFetchMissionDetailsFromEDP = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const { data: perimeter } = useFetchPerimeter();

  const perimeterBrandCodes = perimeter?.companies?.map(company => company.brandCodeApiHeader);

  const location = useLocation();

  return useMutation(
    [MutationKeys.fetchMissionDetailsFromEDP],
    async (params: { edpId: string; edpBrandCode: string }) => {
      const positionStudy = await PositionstudiesService.positionStudiesControllerGetPositionStudyById(
        { positionStudyId: params.edpId, brandCode: params.edpBrandCode ?? '' }
      );
      const isValidAgency =
        positionStudy.agencyId &&
        perimeterBrandCodes?.includes(params.edpBrandCode) &&
        perimeter?.companies
          ?.find(brand => brand.brandCodeApiHeader === params.edpBrandCode)
          ?.agencies?.includes(positionStudy.agencyId);
      if (isValidAgency) {
        const selectedBrand = perimeter?.companies.find(
          brand => brand.brandCodeApiHeader === params.edpBrandCode
        );
        const selectedAgency = selectedBrand?.agencies.find(
          agency => agency === positionStudy.agencyId
        );
        if (selectedBrand && selectedAgency)
          dispatch(
            perimeterActions.setSelectedPerimeter({
              brand: selectedBrand,
              agencyId: selectedAgency,
            })
          );

        dispatch(missionCreationActions.setSelectedPreviousPositionStudy(positionStudy));
        dispatch(
          missionCreationActions.setSelectedCompany(
            positionStudyToMissionCompany(positionStudy.company)
          )
        );
        dispatch(missionCreationActions.setSelectedQualification(positionStudy.qualification));
        navigate(MODAL_ROUTES.MISSION_CREATION.route.buildPath({}), {
          state: {
            background: location,
          },
        });
      } else {
        navigate(MODAL_ROUTES.WRONG_AGENCY_FROM_EDP.route.buildPath({}), {
          state: {
            background: location,
          },
        });
      }
    }
  );
};
