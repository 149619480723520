import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { ExternalDemandsService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchAgencyBrandCode = () => {
  const agencyId = useSelector(getCurrentAgency);

  return useQuery([QueryKeys.fetchAgencyBrandCode], async () => {
    return ExternalDemandsService.externalDemandsControllerGetAgencyBrandCode({ agencyId });
  });
};
