import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { CandidateMission, CandidatesService } from 'src/Services/API';
import { QueryKeys } from '../types';
import moment from 'moment';

export const useFetchCandidateMissions = (
  candidateId: string,
  extraOptions?: UseQueryOptions<CandidateMission[], unknown, CandidateMission[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchMissionsCandidate, candidateId],
    async () => {
      return await CandidatesService.candidatesControllerGetMissionsCandidate({
        body: {
          candidateId: candidateId,
          startDate: moment().subtract(2, 'days').toISOString(),
          endDate: moment().add(30, 'days').toISOString(),
        },
      });
    },
    {
      enabled: Boolean(candidateId),
      ...extraOptions,
    }
  );
};
