import moment from 'moment';
import { useMemo } from 'react';
import { FeatureFlag } from 'src/Services/API';
import { useFetchFeatureFlags } from './useFetchFeatureFlags';

export const useFeatureFlag = (featureFlagId: FeatureFlag): boolean => {
  const { data: featureFlags } = useFetchFeatureFlags();

  const featureFlag = useMemo(
    () => featureFlags?.find(featureFlag => featureFlag.featureFlagId === featureFlagId),
    [featureFlagId, featureFlags]
  );
  if (!featureFlag) return process.env.REACT_APP_ENV !== 'production';
  if (featureFlag.enabled) return true;
  if (!featureFlag.enabledDate) return false;
  return moment().isAfter(featureFlag.enabledDate);
};
