import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AgencyConsultantState } from './Types';

const initialState: AgencyConsultantState = {
  currentAgencyConsultant: undefined,
};

export const { reducer: agencyConsultantReducer, actions: agencyConsultantActions } = createSlice({
  name: 'AgencyConsultant',
  initialState,
  reducers: {
    setCurrentAgencyConsultant: (
      state,
      action: PayloadAction<AgencyConsultantState['currentAgencyConsultant']>
    ) => {
      state.currentAgencyConsultant = action.payload;
    },
  },
});
