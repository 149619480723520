import { useQuery } from '@tanstack/react-query';
import invariant from 'invariant';
import { useSelector } from 'react-redux';
import { QueryKeys } from 'src/Hooks/types';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { CandidatesService } from 'src/Services/API';

export const useFetchCurrentContractOfCandidate = (candidateId?: string) => {
  const brandCode = useSelector(getCurrentBrandCode);

  return useQuery(
    [QueryKeys.fetchCurrentContractOfCandidate, candidateId],
    async () => {
      invariant(candidateId, 'candidateId is missing');
      return CandidatesService.candidatesControllerGetCandidateCurrentContract({
        id: candidateId,
        brandCode,
      });
    },
    {
      enabled: Boolean(candidateId),
    }
  );
};
