import {
  getAnalyticsUserInfo,
  getCurrentAgency,
  getCurrentBrandCode,
} from 'src/Redux/Perimeter/Selectors';
import { ActionCreationParams } from 'src/Redux/Types';
import ReactGA from 'react-ga4';
import { EnumCreateActionBodyBrandCode, MissionsService } from 'src/Services/API/index';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { MutationKeys, QueryKeys } from '../types';
import moment from 'moment';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { getLabel, getActionTargetLabel } from 'src/Services/Actions';

export const useCreateMissionAction = (
  extraOptions?: UseMutationOptions<
    void,
    unknown,
    { missionId: string; params: ActionCreationParams },
    unknown
  >
) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);
  const userInfo = useSelector(getAnalyticsUserInfo);

  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.createMissionAction],
    async ({ missionId, params }) => {
      await MissionsService.missionsControllerCreateMissionAction({
        id: missionId,
        body: {
          brandCode: brandCode as EnumCreateActionBodyBrandCode,
          agencyId,
          createdAt: moment().toISOString(),
          ...params,
        },
      });
      ReactGA.event(ANALYTICS_EVENT.ACTION_CREATION, {
        ...userInfo,
        type: getLabel(params.actionType),
        target: getActionTargetLabel(params.actionTarget),
      });
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([QueryKeys.fetchCompanyActions, variables.params.companyId]);
      },
      ...extraOptions,
    }
  );
};
