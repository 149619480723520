import {
  Badge,
  Button,
  ModalActions,
  ModalContent,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { formatName } from 'src/Utils/formatName';
import styles from './ViewCandidateMemoModal.module.scss';
import { ViewCandidateMemoModalProps } from './ViewCandidateMemoModal.types';

export const ViewCandidateMemoModal = ({ item: memo, close }: ViewCandidateMemoModalProps) => (
  <>
    <ModalContent
      title={memo.date ? `détail du mémo du ${moment(memo.date).format('L')}` : 'détail du mémo'}
      description={
        <>
          {memo.isBehaviorMemo && (
            <>
              <Badge color="error">disciplinaire</Badge>
              {' | '}
            </>
          )}
          de {formatName(memo.consultant) ?? 'consultant inconnu'}
        </>
      }
    >
      <div className={styles.comment}>{memo.comment}</div>
    </ModalContent>
    <ModalActions side="right">
      <Button onClick={close}>fermer</Button>
    </ModalActions>
  </>
);
