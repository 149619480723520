import {
  Button,
  Checkbox,
  DropDown,
  FetchButton,
  HorizontalCardWithTitle,
  ModalDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import React, { useCallback, useMemo, useState } from 'react';
import { Props } from './Action.types';
import {
  ErrorMessage,
  TextAreaWithLightTitle,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import { actionSchema, ActionSchemaType } from './Action.schema';
import { WhyActionDelayTranslation, WhyActionTranslation } from '../Actions.types';
import classNames from 'classnames';
import { REPORT_MAX_LENGTH } from 'src/Utils/constants';
import styles from './Action.module.scss';
import {
  CompanyContact,
  WhyActionActorsEnum,
  WhyActionDelayEnum,
  WhyActionsStatusEnum,
} from 'src/Services/API';
import { UseFormReturn } from 'react-hook-form';
import { formatName } from 'src/Utils/formatName';

const Action = ({
  isEditable,
  modalIcon,
  icon,
  actionKey,
  actionCategoryKey,
  actionsActorKey,
  action,
  possibleActors,
  updateAction,
  updateWorkAnalysisMutationFetchStatus,
}: Props) => {
  const { control, watch, setValue, formState, handleSubmit } = useFormWithZodResolver({
    schema: actionSchema,
    defaultValues: {
      description: action?.description ?? '',
      actor: action?.actor,
      delay: action?.delay,
      actorName: action?.actorName ?? '',
    },
  }) as UseFormReturn<ActionSchemaType>;

  const description = watch('description');
  const actor = watch('actor');
  const actorName = watch('actorName');
  const selectedDelay = watch('delay');
  const [selected, setSelected] = useState(!!action);
  const [isOpen, setIsOpen] = useState(false);
  const [actionSubmitted, setActionSubmitted] = useState(false);
  const findActorWithId = useMemo(
    () => possibleActors.find(contact => contact.contactId === actor),
    [actor, possibleActors]
  );

  const resetValues = () => {
    setValue('actor', '');
    setValue('actorName', '');
    setValue('description', '');
    setValue('delay', undefined);
  };

  const constructSubtitle = (
    actorName: string,
    delayKey: keyof typeof WhyActionDelayTranslation,
    desc: string
  ) => {
    const delayText = WhyActionDelayTranslation[delayKey];
    const truncatedDesc = desc.length > 50 ? desc.substring(0, 50) + '...' : desc;

    if (actorName) {
      return [`${actorName} | dans ${delayText} | ${truncatedDesc}`];
    } else {
      return [`dans ${delayText} | ${truncatedDesc}`];
    }
  };

  const getActorName = useCallback(
    (contactId?: string) => {
      const actorInfo = possibleActors.find(actorItem => actorItem.contactId === contactId);
      return formatName(actorInfo)?.toLowerCase() ?? '';
    },
    [possibleActors]
  );

  const actorId = action?.actor || actor;
  const actorNameValue = getActorName(actorId);
  const delayKey = action?.delay || selectedDelay;
  const descriptionText = action?.description || description;

  const subtitles =
    delayKey && descriptionText
      ? constructSubtitle(actorNameValue, delayKey, descriptionText)
      : undefined;

  const actionHandleSubmit = useCallback(
    () =>
      handleSubmit((_values: ActionSchemaType) => {
        updateAction({
          newAction: selected
            ? {
                description,
                actor: actor ?? '',
                actorName: actorName ?? '',
                delay: selectedDelay ?? WhyActionDelayEnum['1DAY'],
                name: actionKey,
                category: actionCategoryKey,
                actorType: actionsActorKey,
                status: WhyActionsStatusEnum.IN_PROGRESS,
              }
            : undefined,
          actionKeyName: actionKey,
        });
      }),
    [
      handleSubmit,
      updateAction,
      selected,
      description,
      actor,
      actorName,
      selectedDelay,
      actionKey,
      actionCategoryKey,
      actionsActorKey,
    ]
  );

  return (
    <div>
      <HorizontalCardWithTitle
        onClick={() => {
          if (isEditable || !!action) {
            setIsOpen(true);
          }
        }}
        leftActions={
          <div onClick={e => e.stopPropagation()}>
            {isEditable && (
              <Checkbox
                disabled={!isEditable}
                checked={selected}
                onChange={() => {
                  if (selected) {
                    updateAction({
                      newAction: undefined,
                      actionKeyName: actionKey,
                    });
                    setSelected(false);
                  } else {
                    if (!description || !actor || !selectedDelay) {
                      setIsOpen(true);
                    } else {
                      updateAction({
                        newAction: {
                          description: description,
                          actor: actor,
                          delay: selectedDelay,
                          name: actionKey,
                          category: actionCategoryKey,
                          actorType: actionsActorKey,
                          actorName: actorName ?? '',
                          status: WhyActionsStatusEnum.IN_PROGRESS,
                        },
                      });
                      setSelected(true);
                    }
                  }
                }}
              />
            )}
          </div>
        }
        leftIcon={icon}
        title={WhyActionTranslation[actionKey]}
        subtitles={subtitles}
        className={classNames(styles.action, {
          [styles.filledAction]: selected,
          [styles.visualisationAction]: !isEditable,
        })}
        titleClassname={classNames(styles.actionTitle, {
          [styles.filledActionTitle]: selected,
        })}
        classNameLeftIcon={styles.leftIcon}
      />
      <ModalDeprecated
        icon={modalIcon}
        open={isOpen}
        title={`détails ${WhyActionTranslation[actionKey]}`}
        onClose={() => {
          if (!action && !actionSubmitted) {
            setSelected(false);
          }
          setActionSubmitted(false);

          setIsOpen(false);
        }}
        onOpen={() => setSelected(true)}
        footerActionsLeft={[
          isEditable && (
            <Button.Tertiary
              onClick={() => {
                resetValues();
              }}
            >
              tout réinitialiser
            </Button.Tertiary>
          ),
        ]}
        footerActionsRight={[
          isEditable && (
            <Button.Secondary
              onClick={() => {
                resetValues();
                setIsOpen(false);
              }}
            >
              annuler
            </Button.Secondary>
          ),
          isEditable && (
            <FetchButton
              title="valider"
              fetchStatus={updateWorkAnalysisMutationFetchStatus}
              onSuccess={() => setIsOpen(false)}
              onClick={() => {
                setActionSubmitted(true);
                actionHandleSubmit()();
              }}
              disabled={
                actionsActorKey === WhyActionActorsEnum.CLIENT
                  ? description?.length === 0 || actor === undefined || selectedDelay === undefined
                  : description?.length === 0 || selectedDelay === undefined
              }
            />
          ),
        ]}
      >
        <div className={styles.detailsContainer}>
          {isEditable ? (
            <TextAreaWithLightTitle
              title="précisez"
              maxLength={REPORT_MAX_LENGTH}
              valueLength={description?.length}
              placeholder="détaillez l'action"
              control={control}
              name="description"
            />
          ) : (
            <WithLightTitle title="précisez">
              <div>{description}</div>
            </WithLightTitle>
          )}
          <div className={styles.row}>
            {actionsActorKey === WhyActionActorsEnum.CLIENT && (
              <div className={styles.actorSelection}>
                <WithLightTitle title="par qui ?">
                  {isEditable ? (
                    <>
                      <DropDown
                        className={classNames({
                          [styles.error]: formState.errors.actor,
                        })}
                        childrenClassName={styles.dropdownChildren}
                        items={possibleActors}
                        placeholder="choisir l'interlocuteur…"
                        canBeReset={false}
                        keyValueExtractor={(searchResult: CompanyContact) => {
                          return {
                            key: searchResult.contactId ?? '',
                            value: formatName(searchResult) ?? '',
                          };
                        }}
                        selectedItem={findActorWithId}
                        onSelectItem={(value?: CompanyContact) => {
                          if (!value) return;
                          setValue('actor', value.contactId ?? '');
                          setValue('actorName', formatName(value));
                        }}
                      />
                      <ErrorMessage error={formState.errors.actor} />
                    </>
                  ) : (
                    <div>{formatName(findActorWithId) ?? 'inconnu'}</div>
                  )}
                </WithLightTitle>
              </div>
            )}
            <WithLightTitle title="délai de réalisation">
              {isEditable ? (
                <>
                  <DropDown
                    className={classNames(
                      { [styles.delayDropdown]: actionsActorKey === WhyActionActorsEnum.CLIENT },
                      {
                        [styles.delayDropdownFull]: actionsActorKey === WhyActionActorsEnum.AGENCY,
                      },
                      {
                        [styles.error]: formState.errors.delay,
                      }
                    )}
                    childrenClassName={classNames(
                      {
                        [styles.dropdownChildren]: actionsActorKey === WhyActionActorsEnum.CLIENT,
                      },
                      {
                        [styles.dropDownChildrenFull]:
                          actionsActorKey === WhyActionActorsEnum.AGENCY,
                      }
                    )}
                    items={Object.values(WhyActionDelayEnum)}
                    placeholder="choisir…"
                    canBeReset={false}
                    keyValueExtractor={(delay: WhyActionDelayEnum) => {
                      return {
                        key: delay,
                        value:
                          WhyActionDelayTranslation[
                            delay as keyof typeof WhyActionDelayTranslation
                          ],
                      };
                    }}
                    selectedItem={selectedDelay}
                    onSelectItem={(value?: WhyActionDelayEnum) => {
                      setValue('delay', value);
                    }}
                  />
                  <ErrorMessage error={formState.errors.delay} />
                </>
              ) : (
                <div>
                  {
                    WhyActionDelayTranslation[
                      selectedDelay as keyof typeof WhyActionDelayTranslation
                    ]
                  }
                </div>
              )}
            </WithLightTitle>
          </div>
        </div>
      </ModalDeprecated>
    </div>
  );
};

export default Action;
