import {
  CompanySearchResult,
  EnumPositionStudyCompletionStatus,
  OSMMissionsWithoutScheduleModelDtoGetDtoGetCompanyDtoBase,
} from 'src/Services/API';

export const positionStudyToMissionCompany = (
  positionStudyCompany?: OSMMissionsWithoutScheduleModelDtoGetDtoGetCompanyDtoBase
): CompanySearchResult => {
  return {
    companyName: positionStudyCompany?.name,
    companyAddress: {
      city: positionStudyCompany?.location?.cityName,
      postalCode: positionStudyCompany?.location?.zipCode,
      address1: positionStudyCompany?.location?.address,
    },
    companyId: positionStudyCompany?.companyId,
  };
};

export const positionStudyStatusToColor = (
  completionStatusEnum: EnumPositionStudyCompletionStatus
) => {
  switch (completionStatusEnum) {
    case EnumPositionStudyCompletionStatus.MISSING_INFO:
      return 'warm-red';
    case EnumPositionStudyCompletionStatus.PENDING_UPDATE:
      return 'blue';
    case EnumPositionStudyCompletionStatus.UP_TO_DATE:
      return 'success';
  }
};
