import { Memo } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import { OtherHorizontal } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { ServerErrorYellowSmall } from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  Badge,
  Button,
  Card,
  CardContent,
  DetailsModal,
  Loader,
  Menu,
  MenuItem,
  Modal,
  ModalActions,
  ModalContent,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { useState } from 'react';
import { useFetchCandidateMemos } from 'src/Hooks/Candidate/useFetchCandidateMemos';
import { CandidateMemo } from 'src/Services/API';
import { formatName } from 'src/Utils/formatName';
import { pluralFormat } from 'src/Utils/pluralFormat';
import styles from './CandidateMemoModal.module.scss';
import { MemoModalProps } from './CandidateMemoModal.types';
import { CreateCandidateMemoModal } from './CreateCandidateMemoModal';
import { DeleteCandidateMemoModal } from './DeleteCandidateMemoModal';
import { EditCandidateMemoModal } from './EditCandidateMemoModal';
import { ViewCandidateMemoModal } from './ViewCandidateMemoModal';

export const CandidateMemoModal = ({ candidateId, open, onClose }: MemoModalProps) => {
  const { data: candidateMemos, isLoading, refetch } = useFetchCandidateMemos(candidateId);

  const [isCreatingMemo, setIsCreatingMemo] = useState<boolean>(false);

  const [viewedMemo, setViewedMemo] = useState<CandidateMemo>();
  const [editedMemo, setEditedMemo] = useState<CandidateMemo>();
  const [deletedMemo, setDeletedMemo] = useState<CandidateMemo>();

  return (
    <Modal size="medium" open={open} onClose={onClose}>
      <ModalContent
        header={<Memo />}
        title="liste des mémos"
        description={candidateMemos?.length && pluralFormat(candidateMemos.length, 'mémo')}
      >
        {isLoading ? (
          <div className={styles.illustration}>
            <Loader size="medium" />
            <div>chargement des mémos, cela peut prendre du temps</div>
          </div>
        ) : !candidateMemos ? (
          <div className={styles.illustration}>
            <ServerErrorYellowSmall />
            désolé, une erreur s'est produite pendant le chargement des mémos
            <Button onClick={() => refetch()}>réessayer</Button>
          </div>
        ) : candidateMemos.length === 0 ? (
          <>aucun mémo pour ce candidat</>
        ) : (
          candidateMemos?.map((memo, idx) => {
            return (
              <Card key={memo.memoId} color="beige">
                <CardContent
                  overline={memo.date ? `${moment(memo.date).format('L')}` : 'date inconnue'}
                  title={formatName(memo.consultant) ?? 'consultant inconnu'}
                  supportingLines={[memo.comment].filter(Boolean)}
                />
                {memo.isBehaviorMemo && <Badge color="error">disciplinaire</Badge>}
                <Menu
                  anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  trigger={
                    <Button variant="tertiary" size="medium">
                      <OtherHorizontal variant="fill" />
                    </Button>
                  }
                >
                  <MenuItem onClick={() => setViewedMemo(memo)}>voir le mémo</MenuItem>
                  {idx === 0 && (
                    <MenuItem onClick={() => setDeletedMemo(memo)}>supprimer le mémo</MenuItem>
                  )}
                  <MenuItem onClick={() => setEditedMemo(memo)}>modifier le mémo</MenuItem>
                </Menu>
              </Card>
            );
          })
        )}
      </ModalContent>

      <ModalActions side="left">
        <Button variant="secondary" onClick={() => setIsCreatingMemo(true)}>
          ajouter
        </Button>
      </ModalActions>

      <ModalActions side="right">
        <Button onClick={() => onClose?.()}>fermer</Button>
      </ModalActions>

      <CreateCandidateMemoModal
        candidateId={candidateId}
        open={isCreatingMemo}
        onClose={() => setIsCreatingMemo(false)}
      />

      <DetailsModal
        size="medium"
        selectedItem={viewedMemo}
        setSelectedItem={setViewedMemo}
        DetailsComponent={ViewCandidateMemoModal}
      />

      <DetailsModal
        size="medium"
        selectedItem={editedMemo}
        setSelectedItem={setEditedMemo}
        DetailsComponent={EditCandidateMemoModal}
      />

      <DetailsModal
        size="medium"
        selectedItem={deletedMemo}
        setSelectedItem={setDeletedMemo}
        DetailsComponent={DeleteCandidateMemoModal}
      />
    </Modal>
  );
};
