import {
  Badge,
  Button,
  ModalActions,
  ModalContent,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { useDeleteCandidateMemo } from 'src/Hooks/Candidate/useDeleteCandidateMemo';
import { formatName } from 'src/Utils/formatName';
import styles from './DeleteCandidateMemoModal.module.scss';
import { DeleteCandidateMemoModalProps } from './DeleteCandidateMemoModal.types';

export const DeleteCandidateMemoModal = ({ item: memo, close }: DeleteCandidateMemoModalProps) => {
  const deleteCandidateMemo = useDeleteCandidateMemo(memo.memoId, memo.candidateId, {
    onSuccess: () => {
      setTimeout(() => close(), 500);
    },
    onError: () => {
      setTimeout(() => deleteCandidateMemo.reset(), 500);
    },
  });

  return (
    <>
      <ModalContent
        title={
          memo.date
            ? `Voulez-vous vraiment supprimer ce mémo du  ${moment(memo.date).format('L')} ?`
            : 'Voulez-vous vraiment supprimer ce mémo ?'
        }
        description={
          <>
            {memo.isBehaviorMemo && (
              <>
                <Badge color="error">disciplinaire</Badge>
                {' | '}
              </>
            )}
            de {formatName(memo.consultant) ?? 'consultant inconnu'}
          </>
        }
      >
        <div className={styles.comment}>{memo.comment}</div>
      </ModalContent>
      <ModalActions side="left">
        <Button
          onClick={() => deleteCandidateMemo.mutate()}
          mutationStatus={deleteCandidateMemo.status}
        >
          supprimer
        </Button>
      </ModalActions>
      <ModalActions side="right">
        <Button onClick={close}>annuler</Button>
      </ModalActions>
    </>
  );
};
