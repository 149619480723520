import { ReactElement } from 'react';
import { YouplanMission } from 'src/Services/API';

export interface OwnProps {
  mission: YouplanMission;
  candidateId: string;
  trigger: ReactElement;
}

export interface Props extends OwnProps {
  onInfoClick: (missionId: string, candidateId: string) => void;
  onCreateMission: () => void;
}

export enum MenuItems {
  'MISSIONCREATION' = 'créer une commande',
  'PROLONGATION' = 'prolonger le contrat',
  'TREATED' = 'marquer traité',
  'DETAILS' = 'voir détails',
  MISSION_CANCELLATIION = 'annuler la commande',
  CONFLICT_RESOLUTION = 'marquer comme résolu',
  MISSION_EDITION = 'mettre à jour la commande',
  REMOVE_TT_FROM_MISSION = 'retirer le candidat de la commande',
}
