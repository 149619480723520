import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { getAnalyticsUserInfo, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { CandidatesService, CreateMemoParams } from 'src/Services/API';
import { MutationKeys, QueryKeys } from '../types';

export const useCreateCandidateMemo = (
  candidateId: string,
  extraOptions?: UseMutationOptions<
    string,
    AxiosError,
    Omit<CreateMemoParams, 'brandCode'>,
    unknown
  >
) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const userInfo = useSelector(getAnalyticsUserInfo);

  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.createCandidateMemo],
    async (params: Omit<CreateMemoParams, 'brandCode'>) => {
      return await CandidatesService.candidatesControllerCreateCandidateMemo({
        candidateId,
        body: {
          brandCode,
          ...params,
        },
      });
    },
    {
      ...extraOptions,
      onSuccess: (data, variables, context) => {
        ReactGA.event(ANALYTICS_EVENT.MEMO_CREATION, {
          ...userInfo,
        });
        if (variables.isBehaviorMemo) {
          ReactGA.event(ANALYTICS_EVENT.DISCIPLINARY_MEMO_CREATION, {
            ...userInfo,
          });
        }

        queryClient.invalidateQueries([QueryKeys.fetchCandidateMemos, candidateId]);
        extraOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};
