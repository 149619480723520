import { TemporaryWorkerStatusBadge } from '../TemporaryWorkerStatusBadge';
import styles from './TalentDetailsHeader.module.scss';
import { CandidateType, Props } from './TalentDetailsHeader.types';
import { formatName } from 'src/Utils/formatName';

export const TalentDetailsHeader = ({ talent }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.talentName}>{formatName(talent)}</div>
      <div className={styles.agencyAndBadge}>
        <div className={styles.unity}>
          {(talent?.mainAgencyCode ?? 'non précisé') +
            ' | ' +
            (talent?.candidateType === CandidateType.INTERIMAIRE ? 'INT' : 'non précisé') +
            ' | '}
        </div>
        <div className={styles.badge}>
          <TemporaryWorkerStatusBadge status={talent.temporaryWorkerStatus} />
        </div>
      </div>
    </div>
  );
};

export default TalentDetailsHeader;
