import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { CandidatesService, EditMemoParams } from 'src/Services/API';
import { MutationKeys, QueryKeys } from '../types';

export const useEditCandidateMemo = (
  memoId: string,
  candidateId: string,
  extraOptions?: UseMutationOptions<
    string,
    unknown,
    Omit<EditMemoParams, 'brandCode' | 'memoId' | 'candidateId'>,
    unknown
  >
) => {
  const brandCode = useSelector(getCurrentBrandCode);

  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.editCandidateMemo],
    async (params: Omit<EditMemoParams, 'brandCode' | 'memoId' | 'candidateId'>) => {
      return await CandidatesService.candidatesControllerEditCandidateMemo({
        candidateId,
        memoId,
        body: {
          brandCode,
          ...params,
        },
      });
    },
    {
      ...extraOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([QueryKeys.fetchCandidateMemos, candidateId]);
        extraOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};
