import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { useInfiniteQuery } from '@tanstack/react-query';
import { QueryKeys } from '../types';
import { CandidatesService, FetchTemporaryWorkersAgencyParams } from 'src/Services/API';

export const useFetchAgencyTemporaryWorkers = (
  params: Omit<FetchTemporaryWorkersAgencyParams, 'page' | 'brandCode' | 'agency'>
) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);

  return useInfiniteQuery(
    [QueryKeys.fetchAgencyTalents, params],
    async ({ pageParam = 0 }) => {
      return await CandidatesService.candidatesControllerFetchAgencyTemporaryWorkers({
        body: {
          brandCode,
          agency: agencyId,
          page: pageParam,
          ...params,
        },
      });
    },
    {
      getNextPageParam: page => page.nextPage,
    }
  );
};
