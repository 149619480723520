import { useEffect, useState } from 'react';

import {
  CircleAdd,
  CircleCheck,
  Dustbin,
  Eye,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Button,
  DatePickerInputDeprecated,
  DropDown,
  RadioButtonGroup,
  TextArea,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import moment from 'moment/moment';
import { useController, UseFormReturn } from 'react-hook-form';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { useFetchAgencyConsultants } from 'src/Hooks/Agencies/useFetchAgencyConsultants';
import { useAuthenticatedUser } from 'src/Hooks/Authentication/useAuthenticatedUser';
import { useFetchCompanyContacts } from 'src/Hooks/Companies/useFetchCompanyContacts';
import { useFetchWorkAccidentByIds } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidentByIds';
import { useFinaliseWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useFinaliseWorkAccidentAnalysis';
import { useUpdateWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useUpdateWorkAccidentAnalysis';
import { FETCH_STATUS } from 'src/Redux/Types';
import { ROUTES } from 'src/Routes/Routes.types';
import {
  AnalysisStatus,
  EnumWorkAccidentAnalysisStatus,
  SigningStatusEnum,
} from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { capitalizeNames } from 'src/Utils/capitalizeNames';
import { REPORT_MAX_LENGTH } from 'src/Utils/constants';
import { openFile } from 'src/Utils/openFile';
import ClosingWorkAccidentAnalysisModal from './ClosingWorkAccidentAnalysisModal';
import styles from './Conclusion.module.scss';
import { conclusionSchema, ConclusionSchemaType } from './Conclusion.schema';
import {
  ClientSigningStatusRadioButtons,
  Signatory,
  VictimSigningStatusRadioButtons,
} from './Conclusion.types';
import { CreateSignatureModal } from './SignatureModals/CreateSignatureModal';
import { DeleteSignatureModal } from './SignatureModals/DeleteSignatureModal';
import { formatName } from 'src/Utils/formatName';

export const ConclusionRoute = () => {
  const { analysisId } = useTypedParams(
    ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.CONCLUSION
  );
  const currentAnalysis = useFetchWorkAccidentByIds({ analyseIds: [analysisId] });
  const isEditable =
    currentAnalysis.data?.[0].status === EnumWorkAccidentAnalysisStatus.IN_PROGRESS;
  const currentAnalysisFetchStatus = toFetchStatus(currentAnalysis);
  const updateWorkAnalysisMutation = useUpdateWorkAccidentAnalysis();
  const [hasAdditionalSigningPerson, setHasAdditionalSigningPerson] = useState(false);
  const [additionalSignataireName, setAdditionalSignataireName] = useState('');
  const [additionalSignataireRole, setAdditionalSignataireRole] = useState('');
  const [isCreateConsultantSignatureModalOpen, setIsCreateConsultantSignatureModalOpen] = useState(
    false
  );
  const [isCreateClientSignatureModalOpen, setIsCreateClientSignatureModalOpen] = useState(false);
  const [isCreateVictimSignatureModalOpen, setIsCreateVictimSignatureModalOpen] = useState(false);
  const [isCreateAdditionalSignatureModalOpen, setIsCreateAdditionalSignatureModalOpen] = useState(
    false
  );
  const [isDeleteConsultantSignatureModalOpen, setIsDeleteConsultantSignatureModalOpen] = useState(
    false
  );
  const [isDeleteClientSignatureModalOpen, setIsDeleteClientSignatureModalOpen] = useState(false);
  const [isDeleteVictimSignatureModalOpen, setIsDeleteVictimSignatureModalOpen] = useState(false);
  const [isDeleteAdditionalSignatureModalOpen, setIsDeleteAdditionalSignatureModalOpen] = useState(
    false
  );

  const user = useAuthenticatedUser();

  const randstadContacts = useFetchAgencyConsultants(
    currentAnalysis.data?.[0].agencyId ?? '',
    currentAnalysis.data?.[0].brandCode
  );
  const clientContacts = useFetchCompanyContacts(currentAnalysis.data?.[0].company?.id ?? '');

  const [closeAnalysis, setCloseAnalysis] = useState<AnalysisStatus.DONE | undefined>(undefined);
  const finaliseWorkAccidentAnalysisMutation = useFinaliseWorkAccidentAnalysis(setCloseAnalysis);

  const { control, setValue, handleSubmit } = useFormWithZodResolver({
    schema: conclusionSchema,
    defaultValues: {
      comments: undefined,
      dateOfSignature: undefined,
      victimSigningPerson: undefined,
      randstadSigningPerson: undefined,
      clientSigningPerson: undefined,
      additionalSigningPerson: undefined,
    },
  }) as UseFormReturn<ConclusionSchemaType>;

  const comments = useController({ control, name: 'comments' });
  const dateOfSignature = useController({ control, name: 'dateOfSignature' });
  const victimSigningPerson = useController({ control, name: 'victimSigningPerson' });
  const randstadSigningPerson = useController({ control, name: 'randstadSigningPerson' });
  const clientSigningPerson = useController({ control, name: 'clientSigningPerson' });
  const additionalSigningPerson = useController({ control, name: 'additionalSigningPerson' });

  const hasDateOfSignature = !!dateOfSignature.field.value;
  const hasValidConsultantSignature = !!currentAnalysis?.data?.[0]?.randstadConsultantSignature;
  const hasValidClientSignature = !!currentAnalysis?.data?.[0]?.clientSignature;
  const hasValidVictimSignature = !!currentAnalysis?.data?.[0]?.victimSignature;
  const hasValidAdditionalSignature =
    !hasAdditionalSigningPerson || !!currentAnalysis?.data?.[0]?.additionalSignature;
  const hasUnsigningClient =
    clientSigningPerson.field.value?.clientSigningStatus === SigningStatusEnum.CANNOT_SIGN ||
    clientSigningPerson.field.value?.clientSigningStatus ===
      SigningStatusEnum.DOES_NOT_WANT_TO_SIGN;
  const hasUnsigningVictim =
    victimSigningPerson.field.value?.victimSigningStatus === SigningStatusEnum.CANNOT_SIGN ||
    victimSigningPerson.field.value?.victimSigningStatus ===
      SigningStatusEnum.DOES_NOT_WANT_TO_SIGN;
  const isDisabled = !(
    hasDateOfSignature &&
    hasValidAdditionalSignature &&
    (hasValidVictimSignature || hasUnsigningVictim) &&
    hasValidConsultantSignature &&
    (hasValidClientSignature || hasUnsigningClient)
  );

  useEffect(
    () => {
      if (
        currentAnalysisFetchStatus === FETCH_STATUS.FULFILLED &&
        currentAnalysis.data?.[0].conclusion !== undefined
      ) {
        setValue('comments', currentAnalysis.data?.[0].conclusion.comments);
        setValue(
          'dateOfSignature',
          currentAnalysis.data?.[0].conclusion?.dateOfSignature
            ? new Date(currentAnalysis.data?.[0].conclusion.dateOfSignature)
            : new Date()
        );

        setValue('randstadSigningPerson', {
          personName:
            currentAnalysis.data?.[0].conclusion?.randstadSigningPerson?.personName ?? undefined,
          id: currentAnalysis.data?.[0].conclusion?.randstadSigningPerson?.id ?? undefined,
          moment: currentAnalysis.data?.[0].conclusion?.randstadSigningPerson?.moment
            ? new Date(currentAnalysis.data?.[0].conclusion.randstadSigningPerson.moment)
            : undefined,
          signed: !!currentAnalysis.data?.[0].conclusion?.randstadSigningPerson?.moment,
        });
        setValue('additionalSigningPerson', {
          personName:
            currentAnalysis.data?.[0].conclusion?.additionalSigningPerson?.personName ?? undefined,
          moment: currentAnalysis.data?.[0].conclusion?.additionalSigningPerson?.moment
            ? new Date(currentAnalysis.data?.[0].conclusion.additionalSigningPerson.moment)
            : undefined,
          signed: !!currentAnalysis.data?.[0].conclusion?.additionalSigningPerson?.moment,
        });
        setValue('clientSigningPerson', {
          personName:
            currentAnalysis.data?.[0].conclusion?.clientSigningPerson?.personName ?? undefined,
          id: currentAnalysis.data?.[0].conclusion?.clientSigningPerson?.id ?? undefined,
          moment: currentAnalysis.data?.[0].conclusion?.clientSigningPerson?.moment
            ? new Date(currentAnalysis.data?.[0].conclusion.clientSigningPerson.moment)
            : undefined,
          signed: !!currentAnalysis.data?.[0].conclusion?.clientSigningPerson?.moment,
          clientSigningStatus:
            currentAnalysis.data[0].conclusion.clientSigningPerson?.clientSigningStatus,
        });
        setValue('victimSigningPerson', {
          moment: currentAnalysis.data?.[0].conclusion?.victimSigningPerson?.moment
            ? new Date(currentAnalysis.data?.[0].conclusion.victimSigningPerson.moment)
            : undefined,
          signed: !!currentAnalysis.data?.[0].conclusion?.victimSigningPerson?.moment,
          victimSigningStatus:
            currentAnalysis.data[0].conclusion.victimSigningPerson?.victimSigningStatus,
        });
      } else if (
        currentAnalysisFetchStatus === FETCH_STATUS.FULFILLED &&
        currentAnalysis.data?.[0].conclusion === undefined
      ) {
        setValue('dateOfSignature', new Date());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAnalysisFetchStatus]
  );

  const conclusionHandleSubmit = () =>
    handleSubmit(async (values: ConclusionSchemaType) => {
      if (isEditable) {
        updateWorkAnalysisMutation.mutate({
          analyseId: analysisId,
          conclusion: {
            comments: values.comments,
            dateOfSignature: values.dateOfSignature,
            victimSigningPerson: values.victimSigningPerson,
            randstadSigningPerson: values.randstadSigningPerson,
            clientSigningPerson: values.clientSigningPerson,
            additionalSigningPerson: values.additionalSigningPerson,
          },
          status: closeAnalysis,
        });
      }
    });
  useEffect(
    () => {
      window.addEventListener('beforeunload', conclusionHandleSubmit);
      return () => {
        window.removeEventListener('beforeunload', conclusionHandleSubmit);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (currentAnalysisFetchStatus === FETCH_STATUS.FULFILLED) conclusionHandleSubmit()();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dateOfSignature.field.value,
      victimSigningPerson.field.value,
      randstadSigningPerson.field.value,
      clientSigningPerson.field.value,
      additionalSigningPerson.field.value,
      closeAnalysis,
    ]
  );

  return (
    <div className={styles.page}>
      <div className={styles.titleContainer}>5.</div>
      <div className={styles.content}>
        <div className={styles.title}>conclusion</div>
        <div className={styles.subTitle}>observations, commentaires (EU, TT, agence)</div>
        <WithLightTitle
          title="ajouter toutes observations ou commentaires nécessaires des différentes parties"
          className={styles.lightTitle}
          rightTitleComponent={
            isEditable ? (
              <div className={styles.numberOfChar}>
                {comments.field.value?.length ?? 0}/{REPORT_MAX_LENGTH} caractères
              </div>
            ) : (
              <></>
            )
          }
          children={
            isEditable ? (
              <TextArea
                value={comments.field.value}
                className={styles.textArea}
                placeholder={'en quelques mots…'}
                onChange={(event: any) =>
                  comments.field.onChange((event.target as HTMLTextAreaElement).value)
                }
                onBlurCapture={conclusionHandleSubmit()}
              />
            ) : (
              <div>{comments.field.value ?? 'non renseigné'}</div>
            )
          }
        />
        <div className={styles.divider} />
        <div className={styles.subTitle}>signatures</div>
        <WithLightTitle
          title="date"
          className={styles.lightTitle}
          children={
            isEditable ? (
              <DatePickerInputDeprecated
                date={dateOfSignature.field.value}
                onSelectDate={date => dateOfSignature.field.onChange(date)}
                minDate={moment().subtract(1, 'month').toDate()}
                maxDate={moment().add(1, 'month').toDate()}
              />
            ) : (
              <div>
                {dateOfSignature.field.value
                  ? moment(dateOfSignature.field.value).format('DD/MM/YYYY')
                  : 'non renseigné'}
              </div>
            )
          }
        />
        <WithLightTitle title="signataire randstad" className={styles.lightTitle}>
          {currentAnalysis?.data?.[0]?.randstadConsultantSignature ? (
            <div className={styles.signatureValidated}>
              <div className={styles.greenTick}>
                <CircleCheck variant="fill" color="success" />
                {currentAnalysis.data[0].conclusion?.randstadSigningPerson?.moment
                  ? moment(
                      currentAnalysis.data[0].conclusion?.randstadSigningPerson?.moment
                    ).format('[signé le] DD/MM/YYYY [par] ')
                  : 'signé à une date inconnue par '}
                {currentAnalysis.data[0].conclusion?.randstadSigningPerson?.personName
                  ? capitalizeNames(
                      currentAnalysis.data[0].conclusion?.randstadSigningPerson?.personName
                    )
                  : 'une personne inconnue'}
              </div>
              <Button
                variant="tertiary"
                onClick={() => {
                  const data = currentAnalysis.data[0].randstadConsultantSignature?.buffer as
                    | string
                    | undefined;
                  if (!data) return;
                  openFile(Buffer.from(data, 'base64'), 'image/png');
                }}
              >
                <Eye />
              </Button>
              {isEditable && (
                <Button
                  variant="tertiary"
                  onClick={() => setIsDeleteConsultantSignatureModalOpen(true)}
                >
                  <Dustbin variant="line" color="blue" />
                </Button>
              )}
            </div>
          ) : (
            <>
              <DropDown
                items={randstadContacts.data?.map(formatName).filter(Boolean) ?? []}
                placeholder="nom d'un consultant Randstad"
                canBeReset={true}
                className={styles.dropdown}
                childrenClassName={styles.childDropdown}
                keyValueExtractor={item => ({ key: item, value: item })}
                selectedItem={randstadSigningPerson.field.value?.personName}
                onSelectItem={(contactName?: string) =>
                  randstadSigningPerson.field.onChange({
                    ...randstadSigningPerson.field.value,
                    personName: contactName,
                    id: randstadContacts.data?.find(contact => formatName(contact) === contactName)
                      ?.consultantId,
                  })
                }
              />
              <Button
                variant="primary"
                onClick={() => setIsCreateConsultantSignatureModalOpen(true)}
                disabled={!currentAnalysis.data?.at(0)?.conclusion?.randstadSigningPerson?.id}
              >
                signature consultant
              </Button>
            </>
          )}
          <CreateSignatureModal
            open={isCreateConsultantSignatureModalOpen}
            onClose={() => setIsCreateConsultantSignatureModalOpen(false)}
            analysisId={analysisId}
            signatory={Signatory.CONSULTANT}
          />
          <DeleteSignatureModal
            open={isDeleteConsultantSignatureModalOpen}
            onClose={() => setIsDeleteConsultantSignatureModalOpen(false)}
            analysisId={analysisId}
            signatory={Signatory.CONSULTANT}
            personName={currentAnalysis.data?.at(0)?.conclusion?.randstadSigningPerson?.personName}
          />
        </WithLightTitle>

        <WithLightTitle title="signataire côté client" className={styles.lightTitle}>
          {currentAnalysis.data?.[0]?.clientSignature ? (
            <div className={styles.signatureValidated}>
              <div className={styles.greenTick}>
                <CircleCheck variant="fill" color="success" />
                {currentAnalysis.data[0].conclusion?.clientSigningPerson?.moment
                  ? moment(currentAnalysis.data[0].conclusion?.clientSigningPerson?.moment).format(
                      '[signé le] DD/MM/YYYY [par] '
                    )
                  : 'signé à une date inconnue par '}
                {currentAnalysis.data[0].conclusion?.clientSigningPerson?.personName !== undefined
                  ? capitalizeNames(
                      currentAnalysis.data[0].conclusion?.clientSigningPerson.personName
                    )
                  : 'une personne inconnue'}
              </div>
              <Button
                variant="tertiary"
                onClick={() => {
                  const data = currentAnalysis.data[0].clientSignature?.buffer as
                    | string
                    | undefined;
                  if (!data) return;
                  openFile(Buffer.from(data, 'base64'), 'image/png');
                }}
              >
                <Eye />
              </Button>
              {isEditable && (
                <Button
                  variant="tertiary"
                  onClick={() => {
                    setIsDeleteClientSignatureModalOpen(true);
                  }}
                >
                  <Dustbin variant="line" color="blue" />
                </Button>
              )}
            </div>
          ) : clientSigningPerson.field.value?.clientSigningStatus ===
              SigningStatusEnum.CANNOT_SIGN && !isEditable ? (
            <div>Le client ne peut pas signer.</div>
          ) : clientSigningPerson.field.value?.clientSigningStatus ===
              SigningStatusEnum.DOES_NOT_WANT_TO_SIGN && !isEditable ? (
            <div>Le client ne veut pas signer.</div>
          ) : (
            <>
              <RadioButtonGroup
                name="clientSigningStatus"
                radioButtonsInfo={ClientSigningStatusRadioButtons}
                onChange={(_, id) =>
                  clientSigningPerson.field.onChange({
                    ...clientSigningPerson.field.value,
                    clientSigningStatus:
                      ClientSigningStatusRadioButtons.find(button => button.id === id)?.value ??
                      undefined,
                  })
                }
                selected={clientSigningPerson.field.value?.clientSigningStatus}
              />
              {clientSigningPerson.field.value?.clientSigningStatus === SigningStatusEnum.SIGNS && (
                <>
                  <DropDown
                    items={clientContacts.data?.map(formatName).filter(Boolean) ?? []}
                    placeholder="nom d'un interlocuteur"
                    canBeReset={true}
                    className={styles.dropdown}
                    childrenClassName={styles.childDropdown}
                    keyValueExtractor={item => ({ key: item, value: item })}
                    selectedItem={clientSigningPerson.field.value?.personName}
                    onSelectItem={(item?: string) =>
                      clientSigningPerson.field.onChange({
                        ...clientSigningPerson.field.value,
                        personName: item,
                        id: clientContacts.data?.find(contact => formatName(contact) === item)
                          ?.contactId,
                      })
                    }
                  />
                  <Button
                    variant="primary"
                    onClick={() => setIsCreateClientSignatureModalOpen(true)}
                    disabled={!currentAnalysis.data?.[0]?.conclusion?.clientSigningPerson?.id}
                  >
                    signature client
                  </Button>
                </>
              )}
            </>
          )}
          <CreateSignatureModal
            open={isCreateClientSignatureModalOpen}
            onClose={() => setIsCreateClientSignatureModalOpen(false)}
            analysisId={analysisId}
            signatory={Signatory.CLIENT}
          />
          <DeleteSignatureModal
            open={isDeleteClientSignatureModalOpen}
            onClose={() => setIsDeleteClientSignatureModalOpen(false)}
            analysisId={analysisId}
            signatory={Signatory.CLIENT}
            personName={currentAnalysis.data?.at(0)?.conclusion?.clientSigningPerson?.personName}
          />
        </WithLightTitle>

        <WithLightTitle title="signataire intérimaire" className={styles.lightTitle}>
          {currentAnalysis.data?.[0]?.victimSignature ? (
            <div className={styles.signatureValidated}>
              <div className={styles.greenTick}>
                <CircleCheck variant="fill" color="success" />
                {currentAnalysis.data[0].conclusion?.victimSigningPerson?.moment
                  ? moment(currentAnalysis.data[0].conclusion?.victimSigningPerson?.moment).format(
                      '[signé le] DD/MM/YYYY [par] '
                    )
                  : 'signé à une date inconnue par '}
                {formatName(currentAnalysis.data[0].candidate) ?? 'une personne inconnue'}
                <Button
                  variant="tertiary"
                  onClick={() => {
                    const data = currentAnalysis.data[0].victimSignature?.buffer as
                      | string
                      | undefined;
                    if (!data) return;
                    openFile(Buffer.from(data, 'base64'), 'image/png');
                  }}
                >
                  <Eye />
                </Button>
                {isEditable && (
                  <Button
                    variant="tertiary"
                    onClick={() => setIsDeleteVictimSignatureModalOpen(true)}
                  >
                    <Dustbin variant="line" color="blue" />
                  </Button>
                )}
              </div>
            </div>
          ) : victimSigningPerson.field.value?.victimSigningStatus ===
              SigningStatusEnum.CANNOT_SIGN && !isEditable ? (
            <div>L'intérimaire ne peut pas signer.</div>
          ) : victimSigningPerson.field.value?.victimSigningStatus ===
              SigningStatusEnum.DOES_NOT_WANT_TO_SIGN && !isEditable ? (
            <div>L'intérimaire ne veut pas signer.</div>
          ) : (
            <>
              <div>
                <RadioButtonGroup
                  name="victimSigningStatus"
                  radioButtonsInfo={VictimSigningStatusRadioButtons}
                  onChange={(_, id) =>
                    victimSigningPerson.field.onChange({
                      ...victimSigningPerson.field.value,
                      victimSigningStatus:
                        VictimSigningStatusRadioButtons.find(button => button.id === id)?.value ??
                        undefined,
                    })
                  }
                  selected={victimSigningPerson.field.value?.victimSigningStatus}
                />
              </div>
              {isEditable &&
                victimSigningPerson.field.value?.victimSigningStatus ===
                  SigningStatusEnum.SIGNS && (
                  <Button
                    variant="primary"
                    onClick={() => {
                      setIsCreateVictimSignatureModalOpen(true);
                    }}
                  >
                    signature intérimaire
                  </Button>
                )}
            </>
          )}
          <CreateSignatureModal
            open={isCreateVictimSignatureModalOpen}
            onClose={() => setIsCreateVictimSignatureModalOpen(false)}
            analysisId={analysisId}
            signatory={Signatory.VICTIM}
          />
          <DeleteSignatureModal
            open={isDeleteVictimSignatureModalOpen}
            onClose={() => setIsDeleteVictimSignatureModalOpen(false)}
            analysisId={analysisId}
            signatory={Signatory.VICTIM}
            personName={formatName(currentAnalysis.data?.[0]?.candidate)}
          />
        </WithLightTitle>

        <WithLightTitle
          title="signataire supplémentaire"
          className={styles.additionalSigningPerson}
        >
          {currentAnalysis.data?.[0]?.additionalSignature ? (
            <div className={styles.signatureValidated}>
              <div className={styles.greenTick}>
                <CircleCheck variant="fill" color="success" />
                {currentAnalysis.data[0].conclusion?.additionalSigningPerson?.moment
                  ? moment(
                      currentAnalysis.data[0].conclusion?.additionalSigningPerson?.moment
                    ).format('signé le DD/MM/YYYY par ')
                  : 'signé à une date inconnue par '}
                {currentAnalysis.data[0].conclusion?.additionalSigningPerson?.personName
                  ? capitalizeNames(
                      currentAnalysis.data[0].conclusion?.additionalSigningPerson?.personName
                    )
                  : 'une personne inconnue'}
                (rôle :
                {currentAnalysis.data[0].conclusion?.additionalSigningPerson?.personRole ??
                  'inconnu'}
                )
              </div>
              <Button
                variant="tertiary"
                onClick={() => {
                  const data = currentAnalysis.data[0].additionalSignature?.buffer as
                    | string
                    | undefined;
                  if (!data) return;
                  openFile(Buffer.from(data, 'base64'), 'image/png');
                }}
              >
                <Eye />
              </Button>
              {isEditable && (
                <Button
                  variant="tertiary"
                  onClick={() => setIsDeleteAdditionalSignatureModalOpen(true)}
                >
                  <Dustbin variant="line" color="blue" />
                </Button>
              )}
            </div>
          ) : (
            isEditable &&
            (!hasAdditionalSigningPerson ? (
              <Button
                variant="tertiary"
                className={styles.addSigningPerson}
                onClick={() => setHasAdditionalSigningPerson(true)}
              >
                <CircleAdd variant="fill" /> ajouter une signature
              </Button>
            ) : (
              <>
                <div className={styles.additionalSigningPersonTextInputContainer}>
                  <TextInput
                    type="text"
                    value={additionalSignataireName}
                    onValueChange={newValue => {
                      setAdditionalSignataireName(newValue);
                      additionalSigningPerson.field.onChange({
                        ...additionalSigningPerson.field.value,
                        personName: newValue,
                      });
                    }}
                    onBlurCapture={conclusionHandleSubmit()}
                    placeholder="nom du signataire supplémentaire"
                    containerClassName={styles.additionalSigningPersonTextInput}
                  />
                  <TextInput
                    type="text"
                    value={additionalSignataireRole}
                    onValueChange={newValue => {
                      setAdditionalSignataireRole(newValue);
                      additionalSigningPerson.field.onChange({
                        ...additionalSigningPerson.field.value,
                        personRole: newValue,
                      });
                    }}
                    onBlurCapture={conclusionHandleSubmit()}
                    placeholder="rôle du signataire supplémentaire"
                    containerClassName={styles.additionalSigningPersonTextInput}
                  />
                </div>
                <Button
                  variant="primary"
                  onClick={() => setIsCreateAdditionalSignatureModalOpen(true)}
                  disabled={
                    !currentAnalysis.data?.[0]?.conclusion?.additionalSigningPerson?.personName ||
                    !currentAnalysis.data?.[0]?.conclusion?.additionalSigningPerson?.personRole
                  }
                >
                  signature additionnelle
                </Button>
              </>
            ))
          )}
          <CreateSignatureModal
            open={isCreateAdditionalSignatureModalOpen}
            onClose={() => setIsCreateAdditionalSignatureModalOpen(false)}
            analysisId={analysisId}
            signatory={Signatory.ADDITIONAL}
          />
          <DeleteSignatureModal
            open={isDeleteAdditionalSignatureModalOpen}
            onClose={() => setIsDeleteAdditionalSignatureModalOpen(false)}
            analysisId={analysisId}
            signatory={Signatory.ADDITIONAL}
            personName={currentAnalysis.data?.[0]?.conclusion?.additionalSigningPerson?.personName}
          />
        </WithLightTitle>

        {isEditable && (
          <ClosingWorkAccidentAnalysisModal
            trigger={
              <Button variant="secondary" className={styles.lightTitle} disabled={isDisabled}>
                clôturer l'analyse AT
              </Button>
            }
            onValidate={closeAnalysis => {
              if (closeAnalysis) {
                finaliseWorkAccidentAnalysisMutation.mutate({
                  analyseId: analysisId,
                  candidateName: formatName(currentAnalysis.data?.[0].candidate) ?? '',
                  companyId: currentAnalysis.data?.[0].company?.id ?? '',
                  createdAt: moment(new Date()).toISOString(),
                  user,
                  agencyId: currentAnalysis.data?.[0].agencyId ?? '',
                  randstadSignatory:
                    currentAnalysis.data?.[0].conclusion?.randstadSigningPerson?.id ?? '',
                  clientSignatory:
                    currentAnalysis.data?.[0].conclusion?.clientSigningPerson?.id ?? '',
                });
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
