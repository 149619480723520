import styles from './PpaClientCard.module.scss';
import { Props } from './PpaClientCard.types';
import PopOverContact from 'src/Components/PopOverContact';
import { EnumCreateActionBodyActionTarget } from 'src/Services/API';
import React from 'react';

/** @deprecated we should use a `DataTable` instead */
export const PpaClientCard = ({
  companyName,
  qualificationName,
  city,
  postCode,
  contact,
}: Props) => (
  <div className={styles.container}>
    <div className={styles.infoContainer}>
      <p className={styles.companyName}>{companyName}</p>
      <p className={styles.otherInformation}>{`${qualificationName} ${
        city || postCode ? `| ${city} ${postCode}` : ''
      }`}</p>
      {contact && (
        <PopOverContact
          target={EnumCreateActionBodyActionTarget.CONTACT}
          contact={{ ...contact, lastContact: undefined }}
          triggerButton={<button className={styles.contact}>contacter ce client</button>}
          position="right bottom"
          enableEditMissionStatus={true}
        />
      )}
    </div>
  </div>
);
