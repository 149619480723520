import { AxiosInterceptors } from './types';

const axiosInterceptors: AxiosInterceptors = {
  errorInterceptor: async error => {
    const { response, request, message, config } = error;
    console.log(config);
    if (response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(response.data);
      console.log(response.status);
      console.log(response.headers);
    } else if (request) {
      // The request was made but no response was received
      // `request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', message);
    }

    // Either throw or return a rejected promise, will resolve in then otherwise
    return Promise.reject(error);
  },
};

export default axiosInterceptors;
