import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import {
  GetCandidatesAccidentsUnderMinStoppageDuration,
  WorkAccidentAnalysisService,
} from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchAccidentsUnderMinStoppageDuration = (
  extraOptions?: UseQueryOptions<
    GetCandidatesAccidentsUnderMinStoppageDuration,
    unknown,
    GetCandidatesAccidentsUnderMinStoppageDuration,
    any
  >
) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);

  return useQuery(
    [QueryKeys.fetchAccidentsUnderMinStoppageDuration],
    async () => {
      return WorkAccidentAnalysisService.workAccidentAnalysisControllerGetCandidatesAccidentsUnderMinStoppageDuration(
        {
          agencies: [agencyId],
          brandCode,
        }
      );
    },
    { ...extraOptions }
  );
};
