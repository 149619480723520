import { MenuItem } from '@randstad-lean-mobile-factory/react-components-core';
import {
  Telephone,
  Email,
  CommentsChat,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { EnumCreateActionBodyActionTarget, EnumCreateActionBodyActionType } from 'src/Services/API';
import { Props } from './CandidateContactPopup.types';
import { formatName } from 'src/Utils/formatName';

const CandidateContactPopup = ({
  contact,
  target,
  smsAllowed,
  callsAllowed,
  emailsAllowed,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const contactPopupMenuItems = [
    {
      method: 'téléphone',
      isAllowed: callsAllowed,
      icon: <Telephone variant="line" />,
      modalParameters: {
        type: EnumCreateActionBodyActionType.CALL,
        contactInfo:
          target === EnumCreateActionBodyActionTarget.CANDIDATE
            ? [
                { info: contact.phone2, infoType: 'bureau' },
                { info: contact.phone1, infoType: 'mobile' },
              ].filter(elem => elem.info)
            : [
                { info: contact.phone ?? '', infoType: 'bureau' },
                { info: contact.mobilePhoneNumber ?? '', infoType: 'mobile' },
              ].filter(elem => elem.info),
      },
    },
    {
      method: 'mail',
      isAllowed: emailsAllowed,
      icon: <Email variant="line" />,
      modalParameters: {
        type: EnumCreateActionBodyActionType.EMAIL,
        contactInfo: [{ info: contact.email ?? '', infoType: 'email' }],
      },
    },
    {
      method: 'SMS',
      isAllowed: smsAllowed,
      icon: <CommentsChat variant="line" />,
      modalParameters: {
        type: EnumCreateActionBodyActionType.SMS,
        contactInfo:
          target === EnumCreateActionBodyActionTarget.CANDIDATE
            ? [{ info: contact.phone1, infoType: 'mobile' }]
            : [{ info: contact.mobilePhoneNumber ?? '', infoType: 'mobile' }],
      },
    },
  ];
  const contactingMethods = contactPopupMenuItems.filter(
    contactPopupMenuItem => contactPopupMenuItem.isAllowed
  );
  return (
    <>
      {contactingMethods.map(contactPopupMenuItem => {
        return (
          <MenuItem
            key={contactPopupMenuItem.method}
            onClick={() => {
              navigate(MODAL_ROUTES.CONTACT_CANDIDATES.route.buildPath({}), {
                state: {
                  modalParameters: {
                    type: contactPopupMenuItem.modalParameters.type,
                    target: target,
                    contactName: formatName(contact),
                    contactId:
                      target === EnumCreateActionBodyActionTarget.CANDIDATE
                        ? contact.candidateId
                        : contact.contactId,
                    contactInfo: contactPopupMenuItem.modalParameters.contactInfo,
                  },
                  background: location,
                },
              });
            }}
          >
            {contactPopupMenuItem.icon} {contactPopupMenuItem.method}
          </MenuItem>
        );
      })}
    </>
  );
};

export default CandidateContactPopup;
