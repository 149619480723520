import { Props } from './YouplanMissionsFooter.types';
import styles from './YouplanMissionsFooter.module.scss';

import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import MissionCardLoader from 'src/Routes/Activity/YouplanMissions/YouplanMissionDetail/YouplanMissionsFooter/MissionCardLoader';

const LoadingCards = () =>
  Array.from({ length: 10 }).map((_, idx) => (
    <MissionCardLoader key={idx.toString()} uniqueKey={idx.toString()} />
  ));

const YouplanMissionsFooter = ({ onLoadMore, canLoadMore, isFetching }: Props) => {
  if (isFetching) {
    return <>{LoadingCards()}</>;
  }
  if (canLoadMore) {
    return (
      <div className={styles.loadMore}>
        <Button.Tertiary onClick={onLoadMore}>charger plus</Button.Tertiary>
      </div>
    );
  }
  return <div />;
};

export default YouplanMissionsFooter;
