import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import {
  EnumCandidateAdvancementInfoCandidateAdvancement,
  EnumCandidateAdvancementInfoCompanyAdvancement,
  MissionsService,
  UpdateCandidateStatusBody,
} from 'src/Services/API';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { MutationKeys, QueryKeys } from '../types';

export const useUpdateCandidateStatus = (
  extraOptions?: UseMutationOptions<
    void,
    unknown,
    { id: string; body: Omit<UpdateCandidateStatusBody, 'brandCode'> }
  >
) => {
  const brandCode = useSelector(getCurrentBrandCode);

  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.updateCandidateStatus],
    async ({ id, body }: { id: string; body: Omit<UpdateCandidateStatusBody, 'brandCode'> }) => {
      return MissionsService.missionsControllerUpdateCandidateStatus({
        id,
        body: { ...body, brandCode },
      });
    },
    {
      ...extraOptions,
      onSuccess: (_, args) => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchMissionDetails] });
        if (
          args.body.candidates.filter(
            candidate =>
              candidate.candidateAdvancement ===
                EnumCandidateAdvancementInfoCandidateAdvancement.accepté &&
              candidate.companyAdvancement ===
                EnumCandidateAdvancementInfoCompanyAdvancement.accepté
          ).length > 0
        ) {
          queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchCandidatesActivityPlanning] });
        }
      },
    }
  );
};
