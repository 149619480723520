import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import {
  Button,
  FetchButton,
  ModalDeprecated,
  TextArea,
} from '@randstad-lean-mobile-factory/react-components-core';
import ContentLoader from 'react-content-loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { redCloud } from 'src/Assets_DEPRECATED';
import { useFetchActionDetail } from 'src/Hooks/Actions/useFetchActionDetail';
import { useUpdateAction } from 'src/Hooks/Actions/useUpdateAction';
import { FETCH_STATUS } from 'src/Redux/Types';
import { Action } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { CRLocationState } from 'src/Services/Routing';
import { REPORT_MAX_LENGTH } from 'src/Utils/constants';
import styles from './ActionPreviewModal.module.scss';
import { formatName } from 'src/Utils/formatName';

const LoadingActionPreviewModal = () => (
  <div className={styles.container}>
    <ContentLoader uniqueKey="loadingActionPreviewModal" height="16.5rem" width="100%">
      {[
        { width: '80%', height: '0.75rem', y: '0' },
        { width: '90%', height: '0.75rem', y: '1.5rem' },
        { width: '100%', height: '0.75rem', y: '3rem' },
        { width: '70%', height: '0.75rem', y: '4.5rem' },
        { width: '100%', height: '0.75rem', y: '7.5rem' },
        { width: '100%', height: '0.75rem', y: '9rem' },
      ].map((props, idx) => (
        <rect key={idx} x="0" rx="4" ry="4" {...props} />
      ))}
    </ContentLoader>
  </div>
);

const RejectedActionPreviewModal = () => <img src={redCloud} alt="error" />;

const ActionPreviewModal = () => {
  const modalParameters = (useLocation().state as CRLocationState | undefined)?.modalParameters;
  const navigate = useNavigate();

  const fetchActionQuery = useFetchActionDetail(modalParameters?.actionId);
  const updateActionMutation = useUpdateAction();
  const updateFetchStatus = toFetchStatus(updateActionMutation);
  const onValidate = useCallback(
    (action: Action) => {
      updateActionMutation.mutate(action);
    },
    [updateActionMutation]
  );
  const fetchStatus = toFetchStatus(fetchActionQuery);
  const action = fetchActionQuery.data;
  const onClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);
  const [report, setReport] = useState('');
  useEffect(() => setReport(action?.report ?? ''), [action]);
  const title =
    modalParameters?.target === undefined
      ? 'dernier CR sur ce compte'
      : `dernier CR de ${formatName(modalParameters.target)}`;

  return (
    <ModalDeprecated
      nested
      key="crModal"
      open
      onClose={onClose}
      title={
        fetchStatus === FETCH_STATUS.REJECTED
          ? 'Impossible de récupérer le dernier compte-rendu'
          : title
      }
      footerActionsRight={
        action?.isEditable
          ? [
              <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
              <FetchButton
                fetchStatus={updateFetchStatus}
                title="valider"
                errorTitle="réessayer"
                onClick={() => {
                  onValidate({
                    actionId: action.actionId,
                    report: report,
                    isEditable: action.isEditable,
                  });
                }}
                onSuccess={onClose}
              />,
            ]
          : [<Button.Secondary onClick={onClose}>fermer</Button.Secondary>]
      }
    >
      {fetchStatus === FETCH_STATUS.REJECTED && <RejectedActionPreviewModal />}
      {fetchStatus === FETCH_STATUS.PENDING && <LoadingActionPreviewModal />}
      {fetchStatus === FETCH_STATUS.FULFILLED &&
        (action?.isEditable ? (
          <div className={classnames(styles.report)}>
            <TextArea
              className={styles.comment}
              value={report}
              onChange={event => setReport((event.target as HTMLTextAreaElement).value)}
              maxLength={REPORT_MAX_LENGTH}
            />
          </div>
        ) : (
          <p className={classnames(styles.report, { [styles.emptyReport]: !report })}>
            {report || "Aucun compte-rendu n'a été saisi pour cette action."}
          </p>
        ))}
    </ModalDeprecated>
  );
};

export default ActionPreviewModal;
