import { BadgeProps } from '@randstad-lean-mobile-factory/react-components-core/dist/Badge/Badge.types';
import styles from './MissionsStatus.module.scss';

export const getStatusLabel = (status: string | undefined) => {
  switch (status) {
    case 'S':
      return 'servie';
    case 'SP':
      return 'servie partiellement';
    case 'NS':
      return 'non servie';
    case 'AS':
      return 'à servir';
    case 'AN':
      return 'annulée';
    case 'F':
      return 'fermée';
    default:
      return 'N/A';
  }
};

export const getStatusStyle = (status: string | undefined) => {
  switch (status) {
    case 'S':
      return styles.served;
    case 'SP':
      return styles.partiallyServed;
    case 'NS':
      return styles.notServed;
    case 'AS':
      return styles.toBeServed;
    case 'AN':
      return styles.canceled;
    case 'F':
      return styles.canceled;
  }
};

export const getStatusColor = (status: string | undefined): BadgeProps['color'] => {
  switch (status) {
    case 'S':
      return 'success';
    case 'SP':
      return 'information';
    case 'NS':
      return 'error';
    case 'AS':
      return 'warning';
    case 'AN':
      return 'navy';
    case 'F':
      return 'navy';
    default:
      return 'navy';
  }
};
