import { useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import {
  AppelMedicalEventSourceDto,
  DisplayStatusDto,
  ExternalDemandsService,
} from 'src/Services/API';
import { QueryKeys } from '../types';

/** TODO mbapi should return all counts in a single call */
export const useCountExternalDemands = (displayStatus: DisplayStatusDto) => {
  const agencyId = useSelector(getCurrentAgency);

  const counts = useQueries({
    queries: Object.values(AppelMedicalEventSourceDto).map(source => ({
      queryKey: [QueryKeys.countExternalDemands, source, displayStatus],
      queryFn: async () => {
        return await ExternalDemandsService.externalDemandsControllerCountExternalDemands({
          body: { source, agencyId, displayStatus },
        }).then(({ count }) => count);
      },
    })),
  });

  const countBySource = useMemo(() => {
    const entries = Object.values(AppelMedicalEventSourceDto).map((source, index) =>
      counts[index].data === undefined ? undefined : [source, counts[index].data]
    );
    if (entries.some(entry => !entry)) return;
    return Object.fromEntries(entries as [AppelMedicalEventSourceDto, number][]);
  }, [counts]);

  const isLoading = useMemo(() => counts.some(count => count.isLoading), [counts]);

  return useMemo(() => ({ data: countBySource, isLoading }), [countBySource, isLoading]);
};
