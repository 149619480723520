import { Signatory } from 'src/Routes/Accidentology/WorkAccidentAnalysis/WorkAccidentAnalysisDetail/Conclusion/Conclusion.types';
import { ModalProps } from '@randstad-lean-mobile-factory/react-components-core/dist/Modals/Modal/Modal.types';

export interface CreateSignatureModalProps extends Pick<ModalProps, 'open' | 'onClose'> {
  analysisId: string;
  signatory: Signatory;
}

export const signatoryToSignatureNameFile = {
  [Signatory.CONSULTANT]: 'randstad_signing_person_signature.png',
  [Signatory.CLIENT]: 'client_signing_person_signature.png',
  [Signatory.VICTIM]: 'victim_signing_person_signature.png',
  [Signatory.ADDITIONAL]: 'additional_signing_person_signature.png',
};
