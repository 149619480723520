import { AppelMedicalEventSourceDto } from 'src/Services/API';

export interface MailParameterTableProps {
  source: AppelMedicalEventSourceDto;
  siret: string;
  clientName?: string;
  missing: number;
  qualifications: string[];
  domains: string[];
  isOpen: boolean;
  onToggle: () => void;
}

export const OSMOSE_IGNORE_VALUE = '## ignore ##';

export const OSMOSE_IGNORE_LABEL = 'à ignorer';
