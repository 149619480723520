import { useFetchCandidateDetails } from 'src/Hooks/Candidate/useFetchCandidateDetails';
import { Props } from './BlacklistedModal.types';
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
} from '@randstad-lean-mobile-factory/react-components-core';
import { capitalizeNames } from 'src/Utils/capitalizeNames';
import { DeleteAlerte } from '@randstad-lean-mobile-factory/react-assets/dist/headers';

const BlacklistedModal = ({ candidateId, companies, isOpen, setIsOpen, addToMissions }: Props) => {
  const { data: candidate } = useFetchCandidateDetails(candidateId);

  return (
    <Modal size="medium" open={isOpen} onClose={() => setIsOpen(false)}>
      <ModalContent header={<DeleteAlerte />} title="Alerte">
        {candidate?.name &&
          candidate.firstName &&
          `Vous êtes sur le point de rattacher ${capitalizeNames(candidate.name)} ${capitalizeNames(
            candidate.firstName
          )} chez ${companies
            ?.map(company => company.name)
            .join(
              ', '
            )} alors qu'il ne souhaite plus y travailler. Veuillez confirmer que vous souhaitez poursuivre cette action.`}
      </ModalContent>
      <ModalActions side="left">
        <Button variant="secondary" onClick={() => setIsOpen(false)}>
          annuler
        </Button>
      </ModalActions>
      <ModalActions side="right">
        <Button
          variant="primary"
          onClick={() => {
            addToMissions();
            setIsOpen(false);
          }}
        >
          valider
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default BlacklistedModal;
