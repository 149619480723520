import {
  Button,
  Checkbox,
  Modal,
  ModalActions,
  ModalContent,
  TextArea,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';
import { useCreateCandidateMemo } from 'src/Hooks/Candidate/useCreateCandidateMemo';
import { CreateCandidateMemoModalProps } from './CreateCandidateMemoModal.types';
import styles from './CreateCandidateMemoModal.module.scss';

const CreateCandidateMemoModalContent = ({
  candidateId,
  onClose,
}: CreateCandidateMemoModalProps) => {
  const [comment, setComment] = useState('');
  const [isBehaviorMemo, setIsBehaviorMemo] = useState(false);
  const createCandidateMemo = useCreateCandidateMemo(candidateId, {
    onSuccess: () => {
      setTimeout(() => onClose?.(), 500);
    },
    onError: () => {
      setTimeout(() => createCandidateMemo.reset(), 500);
    },
  });

  return (
    <>
      <ModalContent
        title="Ajouter un mémo"
        description={
          <a
            href="https://drive.google.com/file/d/1SDuEoSZdM5I50EIUm1vGjfXIHqBYJMBr/view"
            target="_blank"
            rel="noreferrer"
            className={styles.memoGuideRedirect}
          >
            Ouvrir le guide de saisie des mémos
          </a>
        }
      >
        <WithLightTitle title="commentaire (1 caractère min)" className={styles.textAreaTitle}>
          <TextArea
            placeholder="aucun commentaire renseigné"
            className={styles.textArea}
            value={comment}
            minLength={1}
            maxLength={200}
            onChange={event => setComment((event.target as HTMLTextAreaElement).value)}
          />
        </WithLightTitle>
        <Checkbox
          label="le mémo est à caractère disciplinaire"
          checked={isBehaviorMemo}
          onClick={() => {
            setIsBehaviorMemo(!isBehaviorMemo);
          }}
        />
      </ModalContent>
      <ModalActions side="right">
        <Button variant="secondary" onClick={onClose}>
          annuler
        </Button>
        <Button
          disabled={comment.length < 1}
          onClick={() => createCandidateMemo.mutate({ comment, isBehaviorMemo })}
          mutationStatus={createCandidateMemo.status}
        >
          envoyer
        </Button>
      </ModalActions>
    </>
  );
};

export const CreateCandidateMemoModal = ({
  candidateId,
  open,
  onClose,
}: CreateCandidateMemoModalProps) => {
  return (
    <Modal size="medium" open={open} onClose={onClose}>
      <CreateCandidateMemoModalContent candidateId={candidateId} open={open} onClose={onClose} />
    </Modal>
  );
};
