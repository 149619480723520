import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { SkillsManagementCentersService } from 'src/Services/API';
import { QueryKeys } from '../types';
import { useIsCGC } from './useIsCGC';

export const useFetchAgenciesInfoFromCGC = () => {
  const agencyId = useSelector(getCurrentAgency);

  const { data: isCgc } = useIsCGC();

  return useQuery(
    [QueryKeys.fetchAgenciesInfoFromCGC],
    async () => {
      const agencies = await SkillsManagementCentersService.skillsManagementCentersControllerGetAgenciesInfoFromCgc(
        { cgcName: agencyId }
      );

      return agencies;
    },
    {
      enabled: Boolean(isCgc),
      cacheTime: 1,
    }
  );
};
