import { useQuery } from '@tanstack/react-query';
import { EstablishmentRegion, EstablishmentsService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchEstablishments = () => {
  return useQuery<EstablishmentRegion[]>(
    [QueryKeys.fetchEstablishments],
    async () => {
      return await EstablishmentsService.establishmentControllerFetchEstablishments();
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
};
