import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';
import { Props } from './SelectContact.types';

import { useFetchCompanyContacts } from 'src/Hooks/Companies/useFetchCompanyContacts';
import { toFetchStatus } from 'src/Services/Async';
import { formatName } from 'src/Utils/formatName';

const SelectContact = ({ selectedContact, canBeReset, setSelectedContact, companyId }: Props) => {
  const contactQuery = useFetchCompanyContacts(companyId);
  const contactsFetchStatus = toFetchStatus(contactQuery);

  return (
    <ComboBox
      id="SelectContact"
      isError={!selectedContact}
      disableClearable={canBeReset}
      value={selectedContact}
      useNativeOptionFiltering
      multiple={false}
      searchResults={contactQuery.data ?? []}
      keyValueExtractor={contact => ({
        key: contact.contactId ?? '',
        value: formatName(contact) ?? '',
      })}
      onChange={searchResult => setSelectedContact(searchResult ?? undefined)}
      fetchStatus={contactsFetchStatus}
      minLengthToSearch={0}
    />
  );
};

export default SelectContact;
