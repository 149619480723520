import { createSelector } from '@reduxjs/toolkit';
import { ExternalDemandsState } from './Types';
import { RootState } from '../RootReducer';

const getExternalDemandListState = (state: RootState) => state.externalDemands;

export const getSelectedSource = createSelector(
  [getExternalDemandListState],
  (state: ExternalDemandsState) => state.selectedSource
);
