import { CircleCheck, Danger } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { Props } from './CdiiLastMedicalVisit.types';
import styles from './CdiiLastMedicalVisit.module.scss';

const CdiiLastMedicalVisit = ({ lastMedicalVisitDate }: Props) => {
  const formattedDate = lastMedicalVisitDate && moment(lastMedicalVisitDate).format('DD/MM/YYYY');
  const isFutureDate = lastMedicalVisitDate
    ? moment(lastMedicalVisitDate).isAfter(moment().startOf('day'))
    : false;

  return (
    <WithLightTitle title="dernière visite médicale">
      <div className={styles.detailsContainer}>
        <div>
          {lastMedicalVisitDate ? (
            <CircleCheck variant="fill" color="success" />
          ) : (
            <Danger variant="fill" color="danger" />
          )}
        </div>
        <div className={styles.lastMedicalVisitDetails}>
          {formattedDate
            ? isFutureDate
              ? `prévue le ${formattedDate}`
              : `effectuée le ${formattedDate}`
            : 'aucune visite détectée'}
        </div>
      </div>
    </WithLightTitle>
  );
};

export default CdiiLastMedicalVisit;
