import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { CandidatesService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchAgenciesByCandidate = (
  candidateId: string,
  extraOptions?: UseQueryOptions<string[], unknown, string[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchAgenciesByCandidate, candidateId],
    async () => {
      return CandidatesService.candidatesControllerGetAgenciesByCandidates({ id: candidateId });
    },
    { ...extraOptions }
  );
};
