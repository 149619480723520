import { IllusEscalierYellow } from '@randstad-lean-mobile-factory/react-components-ui-shared';

import styles from './ErrorMessage.module.scss';
import { Props } from './ErrorMessage.types';

const ErrorMessage = ({ message }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.illusEscalier}>
        <IllusEscalierYellow />
      </div>
      <div className={styles.textContainer}>
        <p className={styles.errorTitle}>oups,</p>
        <p className={styles.errorContent}>{message ?? 'Une erreur inconnue est survenue'}</p>
      </div>
    </div>
  );
};

export default ErrorMessage;
