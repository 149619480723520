import { Check, Parameters } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Badge, Button, Chip, Modal } from '@randstad-lean-mobile-factory/react-components-core';
import { useMemo, useState } from 'react';
import { TemporaryWorkerStatus } from 'src/Services/API';
import { pluralFormat } from 'src/Utils/pluralFormat';
import { TemporaryWorkersAgencyFiltersProps } from './TemporaryWorkersAgencyFilters.types';
import styles from './TemporaryWorkersAgencyFilters.module.scss';
import { TemporaryWorkersAgencyFiltersModalContent } from './TemporaryWorkersAgencyFiltersModalContent';

export const TemporaryWorkersAgencyFilters = ({
  filters,
  setFilters,
}: TemporaryWorkersAgencyFiltersProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const filtersCount = useMemo(
    () =>
      +Boolean(filters.qualifications.length) +
      +Boolean(
        filters.status.length > 0 &&
          filters.status.length < Object.keys(TemporaryWorkerStatus).length
      ) +
      1, // for availabilityDateInterval
    [filters.qualifications.length, filters.status.length]
  );

  return (
    <div className={styles.container}>
      {Boolean(filters.qualifications.length) && (
        <Chip onClick={() => setIsOpen(true)} selected>
          <Check />
          {pluralFormat(filters.qualifications.length, 'qualification')}
        </Chip>
      )}
      <Chip onClick={() => setIsOpen(true)} selected>
        <Check />± {filters.availabilityDateInterval} jours
      </Chip>
      {Boolean(
        filters.status.length > 0 &&
          filters.status.length < Object.keys(TemporaryWorkerStatus).length
      ) && (
        <Chip onClick={() => setIsOpen(true)} selected>
          <Check />
          {pluralFormat(filters.status.length, 'statut')}
        </Chip>
      )}

      <Button variant="tertiary" size="medium" onClick={() => setIsOpen(true)}>
        <Parameters />
        <Badge color="blue">{filtersCount}</Badge>
      </Button>

      <Modal size="medium" open={isOpen} onClose={() => setIsOpen(false)}>
        <TemporaryWorkersAgencyFiltersModalContent
          onClose={() => setIsOpen(false)}
          filters={filters}
          setFilters={setFilters}
        />
      </Modal>
    </div>
  );
};
