import classnames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTypedState } from 'react-router-typesafe-routes/dom';
import { useFetchDomains } from 'src/Hooks/Domains/useFetchDomains';
import { useFetchQualifications } from 'src/Hooks/Qualifications/useFetchQualifications';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { DEFAULT_SOURCE_VALUE } from 'src/Utils/constants';
import styles from './MailParametersList.module.scss';
import { Props } from './MailParametersList.types';
import MailParametersTable from './MailParameterTable/MailParameterTable.component';

export const MailParametersList = ({ source, globalFilter, uniqueSirets }: Props) => {
  const initialSiret = useTypedState(ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES).siret;

  const brandCode = useSelector(getCurrentBrandCode);
  const [openSection, setOpenSection] = useState<string | undefined>(
    initialSiret ? initialSiret + source : undefined
  );

  const data = useMemo(
    () =>
      uniqueSirets.filter(({ siret, clientName, parameterStats }) => {
        const stats = parameterStats.find(item => item.source === source);
        if (!stats) return false;
        if (!stats.total) return false;
        if (!globalFilter) return true;
        if (siret === DEFAULT_SOURCE_VALUE) return false;
        return (
          siret.toLowerCase().trim().includes(globalFilter.toLowerCase().trim()) ||
          clientName?.toLowerCase().trim().includes(globalFilter.toLowerCase().trim())
        );
      }),
    [globalFilter, source, uniqueSirets]
  );

  const { data: qualificationData } = useFetchQualifications({ brandCode: brandCode });
  const qualifications = useMemo(
    () => qualificationData?.map(qualifications => qualifications.shortLabel).filter(Boolean) ?? [],
    [qualificationData]
  );
  const { data: domainsData } = useFetchDomains();
  const domains = useMemo(
    () => domainsData?.map(domain => domain.shortLabel).filter(Boolean) ?? [],
    [domainsData]
  );

  const handleToggleSection = useCallback((siret: string) => {
    setOpenSection(prevOpenSection => (prevOpenSection === siret ? undefined : siret));
  }, []);

  return (
    <div className={classnames(styles.container)}>
      {data.length === 0 ? (
        <div className={styles.noResult}>aucun résultat ne correspond à votre recherche</div>
      ) : (
        data.map(({ siret, clientName, parameterStats }) => {
          const stats = parameterStats.find(item => item.source === source);
          if (!stats) return null;

          return (
            <MailParametersTable
              key={source + siret}
              siret={siret}
              clientName={clientName}
              missing={stats.missing}
              domains={domains}
              qualifications={qualifications}
              source={source}
              isOpen={openSection === siret + source}
              onToggle={() => handleToggleSection(siret + source)}
            />
          );
        })
      )}
    </div>
  );
};
