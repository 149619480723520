import { useQuery } from '@tanstack/react-query';
import invariant from 'invariant';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';

import { CompaniesService } from 'src/Services/API';
import { DEFAULT_SOURCE_VALUE } from 'src/Utils/constants';

import { QueryKeys } from '../types';

export const useFetchCompanyServicesBySiret = (siret: string, enabled: boolean) => {
  const brandCode = useSelector(getCurrentBrandCode);

  return useQuery(
    [QueryKeys.fetchCompanyServices, siret],
    async () => {
      invariant(brandCode, 'brandCode is missing');
      if (siret === DEFAULT_SOURCE_VALUE) return [];
      const services = await CompaniesService.companiesControllerGetServicesBySiret({
        siret,
        brandCode,
      });
      return services.sort((a, b) =>
        // eslint-disable-next-line no-restricted-syntax
        a.name === b.name ? 0 : (a.name ?? '') < (b.name ?? '') ? -1 : 1
      );
    },
    {
      enabled,
    }
  );
};
