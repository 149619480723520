import { CircleAdd } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTypedHash, useTypedParams } from 'react-router-typesafe-routes/dom';
import { TopBar } from 'src/Components/TopBar';
import { useFetchTemporaryWorkerDetail } from 'src/Hooks/Candidate/useFetchTemporaryWorkerDetail';
import { MODAL_ROUTES, useBackgroundLocation } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { missionCreationActions } from 'src/Redux/MissionCreation/Slice';
import { ROUTES } from 'src/Routes/Routes.types';
import { formatName } from 'src/Utils/formatName';
import RepositioningMissions from './RepositioningMissionsTalent/RepositioningMissions.component';
import styles from './TemporaryWorkerRepositioning.module.scss';

export const TemporaryWorkerRepositioningRoute = () => {
  const navigate = useNavigate();
  const location = useBackgroundLocation();
  const { talentId } = useTypedParams(
    ROUTES.TALENTS.TEMPORARY_WORKERS.TEMPORARY_WORKER_DETAIL.REPOSITIONING
  );
  const source = useTypedHash(
    ROUTES.TALENTS.TEMPORARY_WORKERS.TEMPORARY_WORKER_DETAIL.REPOSITIONING
  );

  const { data: talent } = useFetchTemporaryWorkerDetail(talentId);

  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      <TopBar
        title={`repositionner ${formatName(talent)}`}
        goBackTo={
          talentId &&
          ROUTES.TALENTS.TEMPORARY_WORKERS.TEMPORARY_WORKER_DETAIL.buildPath(
            { talentId },
            undefined,
            source
          )
        }
        disablePerimeterSelection
      >
        <Button
          size="medium"
          onClick={() => {
            dispatch(missionCreationActions.setCandidateId(talentId));
            navigate(
              MODAL_ROUTES.REPOSITIONING_TALENT_MISSION_CREATION.route.buildPath({
                talentId,
              }),
              {
                state: {
                  background: location,
                  modalParameters: { isTalent: true },
                },
              }
            );
          }}
        >
          <CircleAdd /> nouvelle commande
        </Button>
      </TopBar>
      <RepositioningMissions candidateId={talentId} source={source} />
    </div>
  );
};
