import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ExternalDemandsService } from 'src/Services/API';
import { MutationKeys, QueryKeys } from '../types';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';

export const useCreateExternalDemandsJournal = () => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);

  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.createExternalDemandsJournal],
    async () => {
      await ExternalDemandsService.externalDemandsControllerCreateAgencyJournal({
        agencyId,
        body: { brandCode },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.fetchAgencyBrandCode],
        });
      },
    }
  );
};
