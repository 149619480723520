import React from 'react';

import Card, { LoadingCard } from './Card.component';
import { BadgeDeprecated } from '@randstad-lean-mobile-factory/react-components-core';
import { DotColor } from 'src/Routes/Activity/Contracts/ListContracts/ListContracts.types';

export const defaultData = {
  title: 'TRIXELL',
  firstSubtitle: '131-636',
  secondSubtitle: 'soudeurs',
  description: 'commande : XXX-XXX-XXXXXXX',
  dotColor: DotColor.GREEN,
  disabled: false,
};

export const Default = () => <Card {...defaultData} />;

const longTextData = {
  disabled: false,
  title:
    'think nice behavior fact foot whole milk shoe broad breeze wheat body rule sand chamber limited neighborhood thee beneath report memory would boy swept',
  firstSubtitle:
    'think nice behavior fact foot whole milk shoe broad breeze wheat body rule sand chamber limited neighborhood thee beneath report memory would boy swept',
  secondSubtitle:
    'think nice behavior fact foot whole milk shoe broad breeze wheat body rule sand chamber limited neighborhood thee beneath report memory would boy swept',
  description:
    'think nice behavior fact foot whole milk shoe broad breeze wheat body rule sand chamber limited neighborhood thee beneath report memory would boy swept',
};

export const Disabled = () => <Card {...defaultData} disabled />;

export const WithLongText = () => <Card {...longTextData} />;

export const WithLongTextAndDot = () => <Card {...longTextData} dotColor={DotColor.ORANGE} />;

export const WithDifferentH = () => (
  <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
    {Array.from({ length: 6 })
      .map((_, idx) => `h${idx + 1}`)
      .map(Hi => (
        <Card key={Hi} {...defaultData} dotColor={DotColor.ORANGE} title={Hi} />
      ))}
  </div>
);

export const Loading = () => <LoadingCard />;

export const CDI = () => (
  <Card {...defaultData} topRightComponent={<BadgeDeprecated value="J-2" />} />
);
