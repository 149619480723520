import { Danger } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Flash } from '@randstad-lean-mobile-factory/react-assets/dist/logos';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useAuth from 'src/Hooks/Authentication/useAuth';
import { useFetchPerimeter } from 'src/Hooks/Consultant/useFetchPerimeter';
import { useFetchFeatureFlags } from 'src/Hooks/FeatureFlag/useFetchFeatureFlags';
import { PerimeterSelectionModal } from 'src/Modals/PerimeterSelectionModal';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import styles from './Login.module.scss';

export const Login = () => {
  const agencyId = useSelector(getCurrentAgency);

  const {
    isAuthenticated,
    isLoading: isAuthLoading,
    loginWithRedirect,
    error: authError,
  } = useAuth();

  const {
    data: perimeter,
    isLoading: isPerimeterLoading,
    error: perimeterError,
    refetch: refetchPerimeter,
  } = useFetchPerimeter();

  const {
    isLoading: isFeatureFlagsLoading,
    error: featureFlagsError,
    refetch: refetchFeatureFlags,
  } = useFetchFeatureFlags();

  const isNetworkError =
    perimeterError?.message === 'Network Error' ||
    featureFlagsError?.message === 'Network Error' ||
    perimeterError?.response?.status === 502 ||
    featureFlagsError?.response?.status === 502;

  const [isPerimeterModalOpen, setIsPerimeterModalOpen] = useState(false);

  return (
    <div className={styles.container} data-theme="dark">
      <div className={styles.content}>
        <Flash size="large" />
        <div className={styles.title}>
          Bienvenue
          <br />
          sur la nouvelle version flash
        </div>
        <div className={styles.description}>votre service online de suivi de votre activité</div>
        {authError && (
          <>
            <div className={styles.error}>
              <Danger size="xlarge" />
              <div>
                Une erreur s'est produite lors de l'authentification. Si l'erreur persiste,
                contactez l'assistance.
              </div>
            </div>
            <div className={styles.error}>
              Message d'erreur :
              <br />
              {authError?.message}
            </div>
          </>
        )}
        {isNetworkError ? (
          <div className={styles.error}>
            <Danger size="xlarge" />
            <div>
              Impossible de contacter le serveur de flash. Vérifiez le statut de votre connexion
              internet.
              <br />
              Si l'erreur persiste, contactez l'assistance.
            </div>
          </div>
        ) : (
          (perimeterError || featureFlagsError) && (
            <div className={styles.error}>
              <Danger size="xlarge" />
              <div>
                Une erreur s'est produite lors de la récupération de votre périmètre. Vérifiez que
                votre périmètre a bien été renseigné dans workday.
              </div>
            </div>
          )
        )}
        {!isAuthenticated ? (
          <Button
            className={styles.button}
            onClick={() => loginWithRedirect({ appState: { returnTo: window.location.href } })}
            mutationStatus={isAuthLoading ? 'loading' : undefined}
          >
            se connecter
          </Button>
        ) : !perimeter ? (
          <Button
            className={styles.button}
            onClick={() => refetchPerimeter()}
            mutationStatus={isPerimeterLoading ? 'loading' : undefined}
          >
            réessayer
          </Button>
        ) : !agencyId ? (
          <>
            <Button className={styles.button} onClick={() => setIsPerimeterModalOpen(true)}>
              sélectionner une agence
            </Button>
            <PerimeterSelectionModal
              open={isPerimeterModalOpen}
              onClose={() => setIsPerimeterModalOpen(false)}
            />
          </>
        ) : (
          <Button
            className={styles.button}
            onClick={() => refetchFeatureFlags()}
            mutationStatus={isFeatureFlagsLoading ? 'loading' : undefined}
          >
            réessayer
          </Button>
        )}
      </div>
    </div>
  );
};
