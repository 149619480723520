import { useLocation } from 'react-router-dom';
import { route } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/Routes/Routes.types';
import { LocationState } from 'src/Services/Routing';
import EnhancedActionPlansListModal from './ActionPlanListModal/ActionPlansListModal.enhanced';
import ActionPreviewModal from './ActionPreviewModal';
import ArchiveATModal from './ArchiveATModal';
import ArchivedATDrawer from './ArchivedATDrawer/ArchivedATDrawer.component';
import AttachSuggestedCandidateModal from './AttachSuggestedCandidateModal/AttachSuggestedCandidateModal.component';
import ContactCandidatesModal from './ContactCandidatesModal';
import ContactMissionsModal from './ContactMissionsModal';
import ContractRenewalModalContent from './ContractRenewalModal';
import FilterYouplanMissionsModal from './FilterYouplanMissionsModal';
import MissionCreationModal from './MissionCreationModal';
import RefuseSuggestedCandidateModal from './RefuseSuggestedCandidateModal';
import StatusModalContent from './StatusModal';
import VariableSimulator from './VariableSimulator';
import WrongAgencyFromEDPModal from './WrongAgencyFromEDPModal';
import YouplanMissionModalContent from './YouplanMissionModalContent';

export const useBackgroundLocation = () => {
  const location = useLocation();
  const background = (location.state as LocationState | undefined)?.background;
  return background ?? location;
};

/**
 * @deprecated Do not create new modals using routing.
 * Instead create a ModalDeprecated component using :
 *
 * ```
 * const [open, setOpen] = useState(false);
 * <ModalDeprecated open={open} setOpen={setOpen}>...</ModalDeprecated>
 * ```
 *
 * If you are using one of these existing modals in a new place, it might be time to refactor the ModalDeprecated.
 */
export const MODAL_ROUTES = {
  /**
   * @deprecated Do not create new modals using routing.
   * Instead create a ModalDeprecated component using :
   *
   * ```
   * const [open, setOpen] = useState(false);
   * <ModalDeprecated open={open} setOpen={setOpen}>...</ModalDeprecated>
   * ```
   *
   * If you are using `MissionCreationModal` in a new place, it might be time to refactor `MissionCreationModal`.
   */
  MISSION_CREATION: {
    route: route('missionCreation'),
    Component: MissionCreationModal,
  },
  /**
   * @deprecated Do not create new modals using routing.
   * Instead create a ModalDeprecated component using :
   *
   * ```
   * const [open, setOpen] = useState(false);
   * <ModalDeprecated open={open} setOpen={setOpen}>...</ModalDeprecated>
   * ```
   *
   * If you are using `MissionCreationModal` in a new place, it might be time to refactor `MissionCreationModal`.
   */
  REPOSITIONING_CDII_MISSION_CREATION: {
    route: route('talents/cdii/:candidateId/repositioning/missionCreation'),
    Component: MissionCreationModal,
  },
  /**
   * @deprecated Do not create new modals using routing.
   * Instead create a ModalDeprecated component using :
   *
   * ```
   * const [open, setOpen] = useState(false);
   * <ModalDeprecated open={open} setOpen={setOpen}>...</ModalDeprecated>
   * ```
   *
   * If you are using `MissionCreationModal` in a new place, it might be time to refactor `MissionCreationModal`.
   */
  REPOSITIONING_TALENT_MISSION_CREATION: {
    route: route('talents/temporary-workers/:talentId/repositioning/missionCreation'),
    Component: MissionCreationModal,
  },
  /**
   * @deprecated Do not create new modals using routing.
   * Instead create a ModalDeprecated component using :
   *
   * ```
   * const [open, setOpen] = useState(false);
   * <ModalDeprecated open={open} setOpen={setOpen}>...</ModalDeprecated>
   * ```
   *
   * If you are using `FilterYouplanMissionsModal` in a new place, it might be time to refactor `FilterYouplanMissionsModal`.
   */
  FILTER_YOUPLAN_MISSIONS: {
    route: route(ROUTES.ACTIVITY.YOUPLAN.path + '/filter'),
    Component: FilterYouplanMissionsModal,
  },
  /**
   * @deprecated Do not create new modals using routing.
   * Instead create a ModalDeprecated component using :
   *
   * ```
   * const [open, setOpen] = useState(false);
   * <ModalDeprecated open={open} setOpen={setOpen}>...</ModalDeprecated>
   * ```
   *
   * If you are using `YouplanMissionModalContent` in a new place, it might be time to refactor `YouplanMissionModalContent`.
   */
  YOUPLAN_MISSION_DETAILS: {
    route: route('youplanMissionDetails'),
    Component: YouplanMissionModalContent,
  },
  /**
   * @deprecated Do not create new modals using routing.
   * Instead create a ModalDeprecated component using :
   *
   * ```
   * const [open, setOpen] = useState(false);
   * <ModalDeprecated open={open} setOpen={setOpen}>...</ModalDeprecated>
   * ```
   *
   * If you are using `ContactMissionsModal` in a new place, it might be time to refactor `ContactMissionsModal`.
   */
  CONTACT_MISSIONS: {
    route: route(ROUTES.ACTIVITY.MISSIONS.path + '/contact'),
    Component: ContactMissionsModal,
    // TODO changer le format d'URL. firstSection et secondSection sont utilisés pour le useGetSearchMode
  },
  /**
   * @deprecated Do not create new modals using routing.
   * Instead create a ModalDeprecated component using :
   *
   * ```
   * const [open, setOpen] = useState(false);
   * <ModalDeprecated open={open} setOpen={setOpen}>...</ModalDeprecated>
   * ```
   *
   * If you are using `ContactCandidatesModal` in a new place, it might be time to refactor `ContactCandidatesModal`.
   */
  CONTACT_CANDIDATES: {
    route: route(ROUTES.TALENTS.CANDIDATES.path + '/contact'),
    Component: ContactCandidatesModal,
    // TODO changer le format d'URL. firstSection et secondSection sont utilisés pour le useGetSearchMode
  },
  /**
   * @deprecated Do not create new modals using routing.
   * Instead create a ModalDeprecated component using :
   *
   * ```
   * const [open, setOpen] = useState(false);
   * <ModalDeprecated open={open} setOpen={setOpen}>...</ModalDeprecated>
   * ```
   *
   * If you are using `StatusModalContent` in a new place, it might be time to refactor `StatusModalContent`.
   */
  STATUS_MODAL_CONTENT: {
    route: route('contactStatus'),
    Component: StatusModalContent,
  },
  /**
   * @deprecated Do not create new modals using routing.
   * Instead create a ModalDeprecated component using :
   *
   * ```
   * const [open, setOpen] = useState(false);
   * <ModalDeprecated open={open} setOpen={setOpen}>...</ModalDeprecated>
   * ```
   *
   * If you are using `ActionPreviewModal` in a new place, it might be time to refactor `ActionPreviewModal`.
   */
  ACTION_PREVIEW_MODAL: {
    route: route('report'),
    Component: ActionPreviewModal,
  },
  /**
   * @deprecated Do not create new modals using routing.
   * Instead create a ModalDeprecated component using :
   *
   * ```
   * const [open, setOpen] = useState(false);
   * <ModalDeprecated open={open} setOpen={setOpen}>...</ModalDeprecated>
   * ```
   *
   * If you are using `ContractRenewalModalContent` in a new place, it might be time to refactor `ContractRenewalModalContent`.
   */
  CONTRACT_RENEWAL: {
    route: route('contractRenewal'),
    Component: ContractRenewalModalContent,
  },
  /**
   * @deprecated Do not create new modals using routing.
   * Instead create a ModalDeprecated component using :
   *
   * ```
   * const [open, setOpen] = useState(false);
   * <ModalDeprecated open={open} setOpen={setOpen}>...</ModalDeprecated>
   * ```
   *
   * If you are using `WrongAgencyFromEDPModal` in a new place, it might be time to refactor `WrongAgencyFromEDPModal`.
   */
  WRONG_AGENCY_FROM_EDP: {
    route: route('wrongAgencyFromEDP'),
    Component: WrongAgencyFromEDPModal,
  },
  /**
   * @deprecated Do not create new modals using routing.
   * Instead create a ModalDeprecated component using :
   *
   * ```
   * const [open, setOpen] = useState(false);
   * <ModalDeprecated open={open} setOpen={setOpen}>...</ModalDeprecated>
   * ```
   *
   * If you are using `VariableSimulator` in a new place, it might be time to refactor `VariableSimulator`.
   */
  VARIABLE_SIMULATOR: {
    route: route('variableSimulator'),
    Component: VariableSimulator,
  },
  /**
   * @deprecated Do not create new modals using routing.
   * Instead create a ModalDeprecated component using :
   *
   * ```
   * const [open, setOpen] = useState(false);
   * <ModalDeprecated open={open} setOpen={setOpen}>...</ModalDeprecated>
   * ```
   *
   * If you are using `EnhancedActionPlansListModal` in a new place, it might be time to refactor `EnhancedActionPlansListModal`.
   */
  ENHANCED_ACTION_PLANS_LIST: {
    route: route('editAction'),
    Component: EnhancedActionPlansListModal,
  },
  /**
   * @deprecated Do not create new modals using routing.
   * Instead create a ModalDeprecated component using :
   *
   * ```
   * const [open, setOpen] = useState(false);
   * <ModalDeprecated open={open} setOpen={setOpen}>...</ModalDeprecated>
   * ```
   *
   * If you are using `RefuseSuggestedCandidateModal` in a new place, it might be time to refactor `RefuseSuggestedCandidateModal`.
   */
  REFUSE_SUGGESTED_CANDIDATE: {
    route: route(ROUTES.TALENTS.CDII.path + '/refuseCandidate'),
    Component: RefuseSuggestedCandidateModal,
  },
  /**
   * @deprecated Do not create new modals using routing.
   * Instead create a ModalDeprecated component using :
   *
   * ```
   * const [open, setOpen] = useState(false);
   * <ModalDeprecated open={open} setOpen={setOpen}>...</ModalDeprecated>
   * ```
   *
   * If you are using `AttachSuggestedCandidateModal` in a new place, it might be time to refactor `AttachSuggestedCandidateModal`.
   */
  ATTACH_SUGGESTED_CANDIDATE: {
    route: route(ROUTES.TALENTS.CDII.path + '/attachSuggestedCandidate'),
    Component: AttachSuggestedCandidateModal,
  },
  /**
   * @deprecated Do not create new modals using routing.
   * Instead create a ModalDeprecated component using :
   *
   * ```
   * const [open, setOpen] = useState(false);
   * <ModalDeprecated open={open} setOpen={setOpen}>...</ModalDeprecated>
   * ```
   *
   * If you are using `ArchiveATModal` in a new place, it might be time to refactor `ArchiveATModal`.
   */
  ARCHIVE_AT: {
    route: route(ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.path + '/ATArchive'),
    Component: ArchiveATModal,
  },
  /**
   * @deprecated Do not create new modals using routing.
   * Instead create a ModalDeprecated component using :
   *
   * ```
   * const [open, setOpen] = useState(false);
   * <ModalDeprecated open={open} setOpen={setOpen}>...</ModalDeprecated>
   * ```
   *
   * If you are using `ArchivedATDrawer` in a new place, it might be time to refactor `ArchivedATDrawer`.
   */
  ARCHIVED_AT: {
    route: route(ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.path + '/archivedAT'),
    Component: ArchivedATDrawer,
  },
};
