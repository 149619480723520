import { EnumSearchMissionsBodySortBy, EnumSearchMissionsBodySortOrder } from 'src/Services/API';

export interface MissionsState {
  currentMissionId: string;
  canLoadMore: boolean;
  repositioningMissionsSortBy: RepositioningMissionsSortBy;
  sortOrder: EnumSearchMissionsBodySortOrder;
  missionsSortBy: EnumSearchMissionsBodySortBy;
}

export enum RepositioningMissionsSortBy {
  DISTANCE,
  NB_POSITIONS,
  START_DATE,
  STATUT,
}
