import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { WorkAccidentAnalysisService } from 'src/Services/API';
import { MutationKeys, QueryKeys } from '../types';
interface WorkAccidentActionUpdateParams {
  workAccidentId: string;
  osmoseActionId: string;
  description?: string;
  actor?: string;
  actorName?: string;
  realisationDate?: Date;
  companyId?: string;
}

export const useUpdateWorkAccidentAction = (
  extraOptions?: UseMutationOptions<void, unknown, WorkAccidentActionUpdateParams, unknown>
) => {
  const queryClient = useQueryClient();

  const brandCode = useSelector(getCurrentBrandCode);

  return useMutation(
    [MutationKeys.updateWorkAccidentAction],
    async (params: WorkAccidentActionUpdateParams) => {
      return await WorkAccidentAnalysisService.workAccidentAnalysisControllerEditWorkAccidentActionPlan(
        {
          body: {
            actor: params.actor,
            actorName: params.actorName,
            workAccidentId: params.workAccidentId,
            osmoseActionId: params.osmoseActionId,
            description: params.description,
            realisationDate: params.realisationDate,
            brandCode,
            companyId: params.companyId,
          },
        }
      );
    },
    {
      ...extraOptions,
      onSuccess: (data, variables) => {
        // queryClient.setQueryData(
        //   [
        //     QueryKeys.fetchWorkAccidentActionPlans,
        //     currentAgencyId,
        //     currentBrand?.brandCodeApiHeader,
        //   ],
        //   (oldData?: ReturnType<typeof useFetchWorkAccidentActionPlans>['data']) => {
        //     const workAccident = oldData?.find(
        //       workAccident => variables.workAccidentId === workAccident.workAccidentId
        //     );
        //     if (workAccident) {
        //       const newActions = workAccident.whyActions?.filter(
        //         workAccidentAction => variables.osmoseActionId !== workAccidentAction.osmoseActionId
        //       );
        //       const action = workAccident.whyActions?.find(
        //         workAccidentAction => variables.osmoseActionId === workAccidentAction.osmoseActionId
        //       );
        //       if (action && newActions) {
        //         return [
        //           ...(oldData ?? []).filter(
        //             workAccident => variables.workAccidentId !== workAccident.workAccidentId
        //           ),

        //           {
        //             ...workAccident,
        //             whyActions: [
        //               ...(newActions ?? []),
        //               {
        //                 ...action,
        //                 actorName: variables.actorName ?? action?.actorName,
        //                 actor: variables.actor ?? action?.actor,
        //                 description: variables.description ?? action?.description,
        //                 realisationDate: variables.realisationDate ?? action?.realisationDate,
        //               },
        //             ],
        //           },
        //         ];
        //       }
        //     }
        //     return oldData;
        //   }
        // );
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.fetchWorkAccidentActionPlans],
        });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchWorkAccidents] });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.fetchWorkAccidentById, variables.workAccidentId],
        });

        toast.success("l'action a été mise à jour");
      },
      onError: (...args) => {
        if ((args as any)[0].response.data.statusCode === 513) {
          toast.error("l'action n'a pas pu être mise à jour");
        }
      },
    }
  );
};
