import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EnumSearchMissionsBodySortBy, EnumSearchMissionsBodySortOrder } from 'src/Services/API';
import { RepositioningMissionsSortBy, MissionsState } from './Types';

const initialState: MissionsState = {
  currentMissionId: '',
  canLoadMore: false,
  sortOrder: EnumSearchMissionsBodySortOrder.DESC,
  repositioningMissionsSortBy: RepositioningMissionsSortBy.START_DATE,
  missionsSortBy: EnumSearchMissionsBodySortBy['date de début'],
};

export const { reducer: missionsReducer, actions: missionsActions } = createSlice({
  name: 'missions',
  initialState,
  reducers: {
    setCurrentMissionId: (state, action: PayloadAction<string>) => {
      state.currentMissionId = action.payload;
    },
    setRepositioningMissionsSortBy: (state, action: PayloadAction<RepositioningMissionsSortBy>) => {
      state.repositioningMissionsSortBy = action.payload;
    },
    setMissionsSortBy: (state, action: PayloadAction<EnumSearchMissionsBodySortBy>) => {
      state.missionsSortBy = action.payload;
    },
    setMissionSortOrder: (state, action: PayloadAction<EnumSearchMissionsBodySortOrder>) => {
      state.sortOrder = action.payload;
    },
  },
});
