import {
  AnalysisStatus,
  CreateActionResponse,
  EnumFinalizeATAnalysisBrandCode,
  FinalizeATAnalysis,
  WorkAccidentAnalysisService,
} from 'src/Services/API';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { MutationKeys, QueryKeys } from '../types';
import ReactGA from 'react-ga4';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { useSelector } from 'react-redux';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import { toast } from '@randstad-lean-mobile-factory/react-components-core';

export const useFinaliseWorkAccidentAnalysis = (
  setCloseAnalysis: React.Dispatch<React.SetStateAction<AnalysisStatus.DONE | undefined>>,
  extraOptions?: UseMutationOptions<
    CreateActionResponse,
    unknown,
    Omit<FinalizeATAnalysis, 'brandCode'>,
    unknown
  >
) => {
  const queryClient = useQueryClient();
  const userInfo = useSelector(getAnalyticsUserInfo);

  return useMutation(
    [MutationKeys.finaliseWorkAccidentAnalysis],
    async (params: Omit<FinalizeATAnalysis, 'brandCode'>) => {
      return await WorkAccidentAnalysisService.workAccidentAnalysisControllerFinalizeWorkAccidentAnalysis(
        {
          body: {
            ...params,
            brandCode: EnumFinalizeATAnalysisBrandCode['001VB'],
          },
        }
      );
    },
    {
      ...extraOptions,
      onSuccess: () => {
        setCloseAnalysis(AnalysisStatus.DONE);
        queryClient.invalidateQueries([QueryKeys.fetchWorkAccidentActionPlans]);
        ReactGA.event(ANALYTICS_EVENT.FINALISE_WORK_ACCIDENT_ANALYSIS, { ...userInfo });
        toast.success('votre analyse AT a bien été clôturée');
      },
      onError: () => {
        toast.error("une erreur s'est produite lors de la clôture, veuillez réessayer");
      },
    }
  );
};
