import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/Routes/Routes.types';
import {
  AnalysisStatus,
  Conclusion,
  Context,
  Description,
  EnumWorkAccidentAnalysisStatus,
  WhyAnalysis,
  WorkAccidentAnalysis,
  WorkAccidentAnalysisService,
} from 'src/Services/API';
import { MutationKeys, QueryKeys } from '../types';

interface WorkAccidentUpdateParams {
  analyseId: string;
  status?: AnalysisStatus;
  context?: Context;
  description?: Description;
  whyAnalysis?: WhyAnalysis;
  conclusion?: Conclusion;
}

export const useUpdateWorkAccidentAnalysis = (
  extraOptions?: UseMutationOptions<
    WorkAccidentAnalysis,
    unknown,
    WorkAccidentUpdateParams,
    unknown
  >
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    [MutationKeys.updateWorkAccidentAnalysis],
    async (params: WorkAccidentUpdateParams) => {
      return await WorkAccidentAnalysisService.workAccidentAnalysisControllerUpdateWorkAccidentById(
        {
          id: params.analyseId,
          body: {
            ...params,
          },
        }
      );
    },
    {
      ...extraOptions,
      onSuccess: data => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchWorkAccidentById] });
        if (data.status === EnumWorkAccidentAnalysisStatus.DONE) {
          navigate(ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.buildPath({}));
        }
      },
    }
  );
};
