import { useQuery } from '@tanstack/react-query';
import invariant from 'invariant';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { MissionsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchMissionDetails = (missionId?: string) => {
  const brandCode = useSelector(getCurrentBrandCode);

  return useQuery(
    [QueryKeys.fetchMissionDetails, missionId],

    () => {
      invariant(missionId, 'missionId is missing');
      return MissionsService.missionsControllerGetMission({ id: missionId, brandCode });
    },
    {
      enabled: Boolean(missionId),
      refetchOnWindowFocus: false,
    }
  );
};
