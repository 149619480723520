import { FETCH_STATUS } from 'src/Redux/Types';

/** @deprecated you do not need this */
export function toFetchStatus(
  ...queries: { isSuccess: boolean; isError: boolean; isLoading: boolean }[]
) {
  if (queries.find(query => query.isLoading === true)) {
    return FETCH_STATUS.PENDING;
  }
  if (queries.find(query => query.isError === true)) {
    return FETCH_STATUS.REJECTED;
  } else {
    return FETCH_STATUS.FULFILLED;
  }
}
