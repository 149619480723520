import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Props } from './SubscribeModal.types';
import { DeleteAlerte } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import { useUpdateCandidateSubscription } from 'src/Hooks/Candidate/useUpdateCandidateSubscription';
import { useCallback, useEffect } from 'react';

const SubscribeModal = ({
  open,
  setOpen,
  candidateId,
  decryptedAgenciesIds,
  setRepositiongValidationModalOpen,
}: Props) => {
  const updateSubscription = useUpdateCandidateSubscription();

  const onClose = useCallback(() => {
    setOpen(false);
    setRepositiongValidationModalOpen(true);
  }, [setOpen, setRepositiongValidationModalOpen]);

  useEffect(() => {
    if (updateSubscription.status === 'success') {
      onClose();
    }
  }, [onClose, updateSubscription.status]);
  return (
    <Modal size="medium" open={open} onClose={onClose}>
      <ModalContent header={<DeleteAlerte />} title="Inscription sur l'unité">
        Vous venez de rattacher un talent à une commande n'ayant pas été créée sur une des agences
        d'inscription du talent. Voulez-vous l'inscrire sur cette unité ?
      </ModalContent>
      <ModalActions side="left">
        <Button variant="secondary" onClick={onClose}>
          annuler
        </Button>
      </ModalActions>
      <ModalActions side="right">
        <Button
          variant="primary"
          onClick={() => {
            updateSubscription.mutate({ candidateId, decryptedAgenciesIds });
          }}
          mutationStatus={updateSubscription.status}
        >
          valider
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default SubscribeModal;
