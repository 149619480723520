import { SortDescending } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { TemporaryWorkersSource } from '../TemporaryWorkers.types';
import { TemporaryWorkersAgencyFilters } from './TemporaryWorkersAgencyFilters';
import { TemporaryWorkersRegionalFilters } from './TemporaryWorkersRegionalFilters';
import { TemporaryWorkersSortingMenu } from './TemporaryWorkersSortingMenu';
import { TemporaryWorkersTopBarContentProps } from './TemporaryWorkersTopBarContent.types';

export const TemporaryWorkersTopBarContent = ({
  selectedSource,
  agencyFilters,
  setAgencyFilters,
  agencySort,
  setAgencySort,
  regionalFilters,
  setRegionalFilters,
  regionalSort,
  setRegionalSort,
}: TemporaryWorkersTopBarContentProps) => (
  <>
    {selectedSource === TemporaryWorkersSource.agency ? (
      <TemporaryWorkersAgencyFilters filters={agencyFilters} setFilters={setAgencyFilters} />
    ) : (
      <TemporaryWorkersRegionalFilters filters={regionalFilters} setFilters={setRegionalFilters} />
    )}
    <TemporaryWorkersSortingMenu
      selectedSource={selectedSource}
      trigger={
        <Button variant="tertiary" size="medium">
          <SortDescending />
        </Button>
      }
      agencySort={agencySort}
      setAgencySort={setAgencySort}
      regionalSort={regionalSort}
      setRegionalSort={setRegionalSort}
    />
  </>
);
