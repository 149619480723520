import {
  Edit,
  Eye,
  Finish,
  OtherHorizontal,
  Parameters,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Button,
  Menu,
  MenuItem,
  Tooltip,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useNavigate } from 'react-router-dom';
import { useReplayExternalDemands } from 'src/Hooks/ExternalDemands/useReplayExternalDemands';
import { useUpdateExternalDemands } from 'src/Hooks/ExternalDemands/useUpdateExternalDemands';
import { ROUTES } from 'src/Routes/Routes.types';
import {
  AppelMedicalEventStatusDto,
  DisplayStatusDto,
  ExternalDemandStatusDto,
} from 'src/Services/API';
import { isDemandDto } from '../ExternalDemandsList.types';
import { ExternalDemandStatusBadge } from '../../ExternalDemandStatusBadge';
import { formatStatus } from '../utils';
import { ExternalDemandElementMenuProps } from './ExternalDemandElementMenu.types';

export const ExternalDemandElementMenu = ({
  element,
  showArchivedDemands,
  source,
  setSelectedDemand,
  setSelectedEvent,
}: ExternalDemandElementMenuProps) => {
  const navigate = useNavigate();

  const updateExternalDemandsMutation = useUpdateExternalDemands();
  const replayDemands = useReplayExternalDemands(source);

  return (
    <>
      <Tooltip title={formatStatus(element)}>
        <ExternalDemandStatusBadge element={element} />
      </Tooltip>
      <Button
        size="medium"
        variant="tertiary"
        onClick={event => {
          event.stopPropagation();
          if (isDemandDto(element)) setSelectedDemand(element);
          else setSelectedEvent(element);
        }}
      >
        <Eye />
      </Button>
      {!showArchivedDemands && (
        <Menu
          trigger={
            <Button size="medium" variant="tertiary">
              <OtherHorizontal variant="fill" />
            </Button>
          }
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          enableStopPropagation
        >
          <MenuItem
            onClick={() =>
              navigate(ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES.buildPath({}), {
                state: ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES.buildState({
                  siret: isDemandDto(element) ? element.siret : element.parent.siret,
                }),
              })
            }
          >
            <Parameters /> correspondances
          </MenuItem>
          <MenuItem
            onClick={() =>
              updateExternalDemandsMutation.mutate({
                commandStatusToUpdate: [
                  {
                    commandId: isDemandDto(element) ? element.commandId : element.parent.commandId,
                    displayStatus: DisplayStatusDto.archived,
                    sourceIds: isDemandDto(element) ? undefined : [element.sourceId],
                  },
                ],
              })
            }
          >
            <Finish /> archiver
          </MenuItem>
          {[
            AppelMedicalEventStatusDto.Erreur,
            AppelMedicalEventStatusDto['Erreur annulation'],
            ExternalDemandStatusDto.ERROR,
          ].includes(element.status) && (
            <MenuItem onClick={() => replayDemands.mutate(element)}>
              <Edit /> horaire roulement actualisé
            </MenuItem>
          )}
        </Menu>
      )}
    </>
  );
};
