import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../RootReducer';
import { CDICandidatesState } from './Types';

const getCDICandidatesState = (state: RootState) => state.CDICandidates;

export const getCurrentCandidate = createSelector(
  [getCDICandidatesState],
  (state: CDICandidatesState) => state.currentCandidate
);

export const getPlanningStartDate = createSelector(
  [getCDICandidatesState],
  (state: CDICandidatesState) => state.planningStartDate
);

export const getCDISortBy = createSelector(
  [getCDICandidatesState],
  (state: CDICandidatesState) => state.sortBy
);

export const getCDISortOrder = createSelector(
  [getCDICandidatesState],
  (state: CDICandidatesState) => state.sortOrder
);
