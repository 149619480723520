import { Parameters } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Badge, Button } from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { redCloud } from 'src/Assets_DEPRECATED';
import CardList from 'src/Components/CardList';
import SortContractsPopOverMenu from 'src/Components/PopOverMenus/SortContractsPopOverMenu';
import FilterContractsModal from 'src/Modals/FilterContractsModal/FilterContractsModal.enhanced';
import { getCurrentCompany } from 'src/Redux/Companies/Selectors';
import { getCanLoadMore } from 'src/Redux/Contracts/Selectors';
import { getCurrentQualification } from 'src/Redux/Qualifications/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import styles from './ListContracts.module.scss';
import { Props } from './ListContracts.types';

const ListContracts = (props: Props): JSX.Element => {
  const canLoadMore = useSelector(getCanLoadMore);
  const qualification = useSelector(getCurrentQualification);
  const company = useSelector(getCurrentCompany);
  const [isFilterContractsModalOpen, setIsFilterContractsModalOpen] = useState(false);

  const title =
    props.fetchStatus === FETCH_STATUS.PENDING
      ? `contrats en cours de récupération`
      : `${props.cards.length} contrat${props.cards.length > 1 ? 's' : ''}`;

  let numberOfFilters = 2; // there is always a date and a unit
  if (qualification) {
    numberOfFilters += 1;
  }
  if (company) {
    numberOfFilters += 1;
  }

  if (props.fetchStatus === FETCH_STATUS.REJECTED) {
    return (
      <div className={styles.error}>
        <img src={redCloud} alt="error-img" />
        <p>Une erreur est survenue</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <Button variant="secondary" onClick={() => setIsFilterContractsModalOpen(true)}>
          <Parameters />
          filtres
          <Badge color="blue">{numberOfFilters}</Badge>
        </Button>
        <SortContractsPopOverMenu />
      </div>

      <div className={styles.total}>{title}</div>
      <div className={styles.cardsContainer}>
        <CardList
          cards={props.cards}
          fetchStatus={props.fetchStatus}
          selectedId={props.selectedId}
          canLoadMore={canLoadMore}
          onLoadMore={props.onLoadMore}
        />
      </div>
      <FilterContractsModal
        isOpen={isFilterContractsModalOpen}
        setIsOpen={setIsFilterContractsModalOpen}
      />
    </div>
  );
};

export default ListContracts;
