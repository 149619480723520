import { BadgeDeprecated } from '@randstad-lean-mobile-factory/react-components-core';
import { ThunkDispatch } from '@reduxjs/toolkit';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { clock } from 'src/Assets_DEPRECATED';
import {
  getContracts,
  getContractsFetchStatus,
  getCurrentContract,
} from 'src/Redux/Contracts/Selectors';
import { fetchContractDetail } from 'src/Redux/Contracts/Thunks';
import { RootState } from 'src/Redux/RootReducer';
import { searchActions } from 'src/Redux/Search/Slice';
import { ContactingStatus, EnumSearchMissionsBodySortBy } from 'src/Services/API';
import { isCDIWarning } from 'src/Services/Contracts';
import ListContracts from './ListContracts.component';
import { DotColor } from './ListContracts.types';
import styles from './ListContracts.module.scss';
import { useSearchContractsWithReset } from 'src/Redux/Search/Hooks';
import { formatName } from 'src/Utils/formatName';

const MemoListContracts = React.memo(ListContracts);

function getColorForContactingStatus(status: ContactingStatus): DotColor {
  switch (status) {
    case ContactingStatus.COMPLETED:
      return DotColor.GREEN;
    case ContactingStatus.IN_PROGRESS:
      return DotColor.ORANGE;
    default:
      return DotColor.GREY;
  }
}

const EnhancedListContracts = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const contracts = useSelector(getContracts);
  const contractsFetchStatus = useSelector(getContractsFetchStatus);
  const currentContract = useSelector(getCurrentContract);

  const searchWithReset = useSearchContractsWithReset();

  useEffect(() => {
    searchWithReset();
    dispatch(searchActions.setSortMode(EnumSearchMissionsBodySortBy['date de début']));
  }, [dispatch, searchWithReset]);

  const onLoadMore = useCallback(() => {
    dispatch(searchActions.incrementOffset());
    searchWithReset();
  }, [dispatch, searchWithReset]);

  const formatDates = (start?: Date, end?: Date) => {
    const startDate = moment(start).format('DD/MM/Y') || '?';
    if (end) {
      return `${startDate} - ${moment(end).format('DD/MM/Y')}`;
    }
    return `${startDate}`;
  };

  const listSectionProps = {
    fetchStatus: contractsFetchStatus,
    selectedId: currentContract?.contractId || null,
    cards: contracts.map(contract => {
      const daysLeft = moment(contract.effectiveEndDate).diff(moment(), 'days');
      return {
        id: contract.contractId ?? '',
        title: contract?.company?.name ?? 'Compte inconnu',
        firstSubtitle: formatDates(contract?.effectiveStartDate, contract?.effectiveEndDate), // company.name
        secondSubtitle: contract.qualification?.label || '',
        description: formatName(contract?.candidate) ?? '',
        onClick: () => dispatch(fetchContractDetail(contract)),
        borderLeftColor: getColorForContactingStatus(contract?.clientContactingStatus),
        dotColor: getColorForContactingStatus(contract?.candidateContactingStatus),
        topRightComponent: isCDIWarning(daysLeft, contract.isDerogatoryMissionLetter) ? (
          <BadgeDeprecated
            value={`J-${daysLeft}`}
            leftIcon={<img src={clock} alt="clock" />}
            className={styles.timeGellule}
          />
        ) : undefined,
      };
    }),
    onLoadMore: onLoadMore,
  };

  return <MemoListContracts {...listSectionProps} />;
};

export default EnhancedListContracts;
