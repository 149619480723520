import {
  ServerErrorRed,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import { Button, Checkbox, Loader } from '@randstad-lean-mobile-factory/react-components-core';
import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { illusReposi } from 'src/Assets_DEPRECATED';
import { useFetchCandidateLastContractEndDate } from 'src/Hooks/Candidate/useFetchCandidateLastContractEndDate';
import { useFetchCandidateQualifications } from 'src/Hooks/Candidate/useFetchCandidateQualifications';
import useFetchDepartments from 'src/Hooks/Departments/useFetchDepartments';
import { useFetchMissionsBySkillMatchingForRepositioning } from 'src/Hooks/Missions/useFetchMissionsBySkillMatchingForRepositioning';
import { getMissionsSortOrder, getRepositioningMissionsSortBy } from 'src/Redux/Missions/Selectors';
import { RepositioningMissionsSortBy } from 'src/Redux/Missions/Types';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import MissionDetail from 'src/Routes/Activity/Missions/MissionDetail';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { Department, EnumSearchMissionsBodySortOrder, Mission } from 'src/Services/API';
import { getStatusStyle } from 'src/Services/Missions';
import { formatName } from 'src/Utils/formatName';
import { FiltersMenu } from '../RepositioningMissions/FiltersMenu';
import CGCMissionPropositionModal from '../RepositioningMissions/CGCMissionPropositionModal';
import RepositioningCard from './RepositioningCard';
import styles from './RepositioningSkillMatching.module.scss';
import { Props } from './RepositioningSkillMatching.types';

export const RepositioningSkillMatching = ({ candidateId, candidateData, statut }: Props) => {
  const { data: candidateLastContractEndDate } =
    useFetchCandidateLastContractEndDate(candidateId) ?? moment();

  const { inView, ref } = useInView();

  const userInfo = useSelector(getAnalyticsUserInfo);
  const sortBy = useSelector(getRepositioningMissionsSortBy);
  const sortOrder = useSelector(getMissionsSortOrder);

  const departmentsQuery = useFetchDepartments();
  const qualificationQuery = useFetchCandidateQualifications(candidateData.id);

  useEffect(() => {
    setSelectedDepartments(
      departmentsQuery.data?.filter(
        dep => dep.id === candidateData.candidateAddress?.candidatePostalCode?.slice(0, 2)
      ) ?? undefined
    );
  }, [candidateData.candidateAddress?.candidatePostalCode, departmentsQuery.data]);
  useEffect(() => {
    setSelectedQualifications(
      qualificationQuery.data?.map(qualification => qualification.id).filter(Boolean)
    );
  }, [qualificationQuery.data]);

  const [selectedDepartments, setSelectedDepartments] = useState<Department[]>();
  const [selectedQualifications, setSelectedQualifications] = useState<string[]>();
  const [selectedMissions, setSelectedMissions] = useState<Mission[]>([]);
  const [missionToDisplay, setMissionToDisplay] = useState<string>();
  const {
    data: missionsPages,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useFetchMissionsBySkillMatchingForRepositioning({
    mainQualifications: selectedQualifications,
    departmentIds: selectedDepartments?.map(item => item.id).filter(Boolean),
    startDate: moment(candidateLastContractEndDate) ?? moment(),
    endDate: moment(candidateLastContractEndDate).add(6, 'months'),
  });

  const missions = useMemo(
    () =>
      missionsPages?.pages
        .flat()
        .filter(mission => !mission.endDate || moment(mission.endDate).isAfter(moment())),
    [missionsPages?.pages]
  );

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  // TODO: server-side sorting
  const sortedMissions = useMemo(() => {
    switch (sortBy) {
      case RepositioningMissionsSortBy.DISTANCE:
        return (missions ?? []).sort((a, b) => {
          return sortOrder === EnumSearchMissionsBodySortOrder.ASC
            ? (a.distanceToTT ?? Math.pow(10, 4)) > (b.distanceToTT ?? Math.pow(10, 4))
              ? 1
              : -1
            : (a.distanceToTT ?? -1) < (b.distanceToTT ?? -1)
            ? 1
            : -1;
        });
      case RepositioningMissionsSortBy.NB_POSITIONS:
        return (missions ?? []).sort((a, b) => {
          return sortOrder === EnumSearchMissionsBodySortOrder.ASC
            ? (a.numberPositions ?? 0) > (b.numberPositions ?? 0)
              ? 1
              : -1
            : (a.numberPositions ?? 0) < (b.numberPositions ?? 0)
            ? 1
            : -1;
        });
      case RepositioningMissionsSortBy.START_DATE:
        const missionsWithStartDate = missions?.filter(mission => mission.startDate !== undefined);
        return [
          ...(missionsWithStartDate ?? []).sort((a, b) => {
            return sortOrder === EnumSearchMissionsBodySortOrder.ASC
              ? moment(a.startDate).isAfter(moment(b.startDate))
                ? 1
                : -1
              : moment(a.startDate).isBefore(moment(b.startDate))
              ? 1
              : -1;
          }),
          ...(missions?.filter(mission => mission.startDate === undefined) ?? []),
        ];
      case RepositioningMissionsSortBy.STATUT:
        return [
          ...(missions ?? []).sort((a, b) => {
            const aSalesPhase =
              (a.salesPhase?.label ?? '').toLocaleLowerCase() === 'a servir' ? 0 : 1;
            const bSalesPhase =
              (b.salesPhase?.label ?? '').toLocaleLowerCase() === 'a servir' ? 0 : 1;

            return sortOrder === EnumSearchMissionsBodySortOrder.ASC
              ? aSalesPhase > bSalesPhase || moment(a.startDate).isAfter(moment(b.startDate))
                ? 1
                : -1
              : aSalesPhase < bSalesPhase || moment(a.startDate).isBefore(moment(b.startDate))
              ? 1
              : -1;
          }),
        ];
    }
  }, [sortBy, sortOrder, missions]);

  useEffect(() => {
    if (
      sortedMissions !== undefined &&
      sortedMissions.length > 0 &&
      missionToDisplay === undefined
    ) {
      setMissionToDisplay(sortedMissions[0].missionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedMissions]);

  const [isMissionSuggestionOpen, setIsMissionSuggestionOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Checkbox
          checked={
            sortedMissions.length === selectedMissions.length && selectedMissions.length !== 0
          }
          halfChecked={selectedMissions.length > 0}
          onClick={() => {
            selectedMissions.length === sortedMissions.length
              ? setSelectedMissions([])
              : setSelectedMissions(sortedMissions);
          }}
        />
        <Button
          size="medium"
          variant="secondary"
          disabled={selectedMissions.length === 0}
          onClick={() => {
            setIsMissionSuggestionOpen(true);
            ReactGA.event(ANALYTICS_EVENT.CLICK_ORDER_SUGGEST, {
              ...userInfo,
              suggestedOrderCount: selectedMissions.length,
              statut,
            });
          }}
        >
          {selectedMissions.length > 1 ? 'suggérer sur les commandes' : 'suggérer sur la commande'}
        </Button>
        <div className={styles.spacer} />
        <FiltersMenu />
      </div>
      {isLoading ? (
        <div className={styles.illustration}>
          <Loader size="large" />
          chargement des missions
        </div>
      ) : !missionsPages ? (
        <div className={styles.illustration}>
          <ServerErrorRed />
          désolé, une erreur s'est produite
        </div>
      ) : !sortedMissions.length ? (
        <div className={styles.illustration}>
          <VidePasteque />
          Aucune commande trouvée pour les compétences de {formatName(candidateData)}
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.leftPartContainer}>
            <div className={styles.missionsContainer}>
              {sortedMissions.map(mission => {
                const selectedMissionIds = selectedMissions.map(mission => mission.missionId);
                const isSelected = selectedMissionIds.includes(mission?.missionId ?? '');
                return (
                  <RepositioningCard
                    key={mission.missionId}
                    companyName={mission.company?.name ?? ''}
                    serviceName={mission.service?.name}
                    startDate={mission.startDate}
                    agencyId={mission.agencyId}
                    status={mission.salesPhase?.label}
                    isChecked={isSelected}
                    isSelected={missionToDisplay === mission.missionId}
                    qualificationName={mission.qualification?.label ?? ''}
                    onClick={() => setMissionToDisplay(mission.missionId)}
                    onCheckBoxClick={() => {
                      isSelected
                        ? setSelectedMissions(
                            selectedMissions.filter(
                              missionItem => mission.missionId !== missionItem.missionId
                            )
                          )
                        : setSelectedMissions([...selectedMissions, mission]);
                    }}
                    flag={{
                      count: mission.distanceToTT,
                      className:
                        mission.distanceToTT !== undefined
                          ? mission.distanceToTT <= 50
                            ? styles.kmFlagBelow
                            : styles.kmFlagAbove
                          : styles.kmFlagNone,
                      label: 'km',
                    }}
                    badgeClassName={classNames(
                      styles.badge,
                      getStatusStyle(mission.salesPhase?.id)
                    )}
                    isSkillMatching={true}
                    skillMatchingMatchScore={Math.round(
                      parseFloat(mission.matchScore ?? '0') * 100
                    )}
                  />
                );
              })}

              <div ref={ref} />
            </div>
          </div>
          {selectedMissions.length >= 2 ? (
            <div className={styles.detailContainerMore}>
              <div className={styles.detailContainerTitle}>Sélection</div>
              <div className={styles.detailContainerContent}>
                {selectedMissions.length} commandes sélectionnées
              </div>
              <img
                src={illusReposi}
                alt="repositionnement-illus"
                className={styles.illusRepositioning}
              />
            </div>
          ) : (
            <div className={styles.detailContainer}>
              <MissionDetail
                missionId={missionToDisplay}
                candidateId={candidateData.id}
                isSkillMatching={true}
                SkillMatchingMission={missions
                  ?.filter(mission => mission.missionId === missionToDisplay)
                  .at(0)}
              />
            </div>
          )}
        </div>
      )}
      <CGCMissionPropositionModal
        candidateData={candidateData}
        selectedMissions={selectedMissions}
        statut={statut}
        open={isMissionSuggestionOpen}
        onClose={() => setIsMissionSuggestionOpen(false)}
      />
    </div>
  );
};
