import { useEffect } from 'react';

import { ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { AnyAction } from 'redux';
import { useFetchPerimeter } from 'src/Hooks/Consultant/useFetchPerimeter';
import { useWatchForEdpURLParam } from 'src/Hooks/Missions/useWatchForEdpURLParam';
import { emitLoginEvent } from 'src/Redux/Analytics/Thunks';
import { RootState } from 'src/Redux/RootReducer';
import styles from './AppRoot.module.scss';
import { Sidebar } from './Sidebar';

export const AppRoot = () => {
  const perimeterQuery = useFetchPerimeter();

  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  useEffect(() => {
    if (perimeterQuery.isSuccess) {
      dispatch(emitLoginEvent());
    }
  }, [dispatch, perimeterQuery]);
  useWatchForEdpURLParam();

  return (
    <div className={styles.container}>
      <Sidebar />
      <div className={styles.contentContainer}>{perimeterQuery.isSuccess && <Outlet />}</div>
    </div>
  );
};
