import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentQualification } from 'src/Redux/Qualifications/Selectors';
import { qualificationsActions } from 'src/Redux/Qualifications/Slice';

import { getCurrentCompany } from 'src/Redux/Companies/Selectors';
import { companiesActions } from 'src/Redux/Companies/Slice';

import { useDatePicker, useSearchYouplanCompaniesWithReset } from 'src/Redux/Search/Hooks';

import { ThunkDispatch } from '@reduxjs/toolkit';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { AnyAction } from 'redux';
import { RootState } from 'src/Redux/RootReducer';
import { getSelectedSalesphases } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { getCurrentService } from 'src/Redux/Services/Selectors';
import { servicesActions } from 'src/Redux/Services/Slice';
import { fetchServices } from 'src/Redux/Services/Thunks';
import { CompanySearchResult, CompanyService, Qualification, Salesphase } from 'src/Services/API';
import FilterYouplanMissionsModal from './FilterYouplanMissionsModal.component';

const MemoFilterYouplanMissionsModal = React.memo(FilterYouplanMissionsModal);

const EnhancedFilterYouplanMissionsModal = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const navigate = useNavigate();

  // Qualification Selection
  const selectedQualification = useSelector(getCurrentQualification);
  const setSelectedQualification = useCallback(
    (qualification?: Qualification) =>
      dispatch(qualificationsActions.setCurrentQualification(qualification)),
    [dispatch]
  );

  // Company Selection
  const selectedCompany = useSelector(getCurrentCompany);
  const setSelectedCompany = useCallback(
    (company?: CompanySearchResult) => dispatch(companiesActions.setCurrentCompany(company)),
    [dispatch]
  );
  const onCompanySelection = useCallback(
    (companyId: string) => dispatch(fetchServices(companyId)),
    [dispatch]
  );

  // Service Selection
  const selectedService = useSelector(getCurrentService);
  const setSelectedService = useCallback(
    (company?: CompanyService) => dispatch(servicesActions.setCurrentService(company)),
    [dispatch]
  );

  // Salesphases Selection
  const selectedSalesphases = useSelector(getSelectedSalesphases);
  const setSelectedSalesphases = useCallback(
    (salesphases: Salesphase[]) => dispatch(searchActions.setSalesphases(salesphases)),
    [dispatch]
  );

  // Dates Selection
  const [startDate, endDate, onDatesChange] = useDatePicker();

  // Search on validate
  const searchWithReset = useSearchYouplanCompaniesWithReset();
  const onValidateClick = useCallback(() => {
    navigate(-1);
    searchWithReset();
  }, [navigate, searchWithReset]);
  const onCancelClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <MemoFilterYouplanMissionsModal
      selectedQualification={selectedQualification}
      setSelectedQualification={setSelectedQualification}
      selectedCompany={selectedCompany}
      setSelectedCompany={setSelectedCompany}
      onCompanySelection={onCompanySelection}
      selectedService={selectedService}
      setSelectedService={setSelectedService}
      selectedSalesphases={selectedSalesphases}
      setSelectedSalesphases={setSelectedSalesphases}
      startDate={moment(startDate)}
      endDate={endDate}
      onDatesChange={onDatesChange}
      onValidateClick={onValidateClick}
      onCancelClick={onCancelClick}
    />
  );
};

export default EnhancedFilterYouplanMissionsModal;
