import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  FeatureFlag,
  FeatureFlagConfig,
  FeatureFlagsService,
  UpdateFeatureFlagConfigParams,
} from 'src/Services/API';
import { QueryKeys } from '../types';

export const useUpdateFeatureFlagConfigs = (
  featureFlagId: FeatureFlag,
  extraOptions?: UseMutationOptions<void, AxiosError, UpdateFeatureFlagConfigParams, any>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async params => {
      await FeatureFlagsService.featureFlagsControllerUpdateFeatureFlagConfig({
        featureFlag: featureFlagId,
        body: params,
      });
    },
    {
      ...extraOptions,
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData<FeatureFlagConfig[]>([QueryKeys.fetchFeatureFlagConfigs], data => {
          if (!data) return [{ featureFlagId, ...variables }];
          return data.map(featureFlag => {
            if (featureFlag.featureFlagId !== featureFlagId) return featureFlag;
            return { featureFlagId, ...variables };
          });
        });
        queryClient.invalidateQueries([QueryKeys.fetchFeatureFlagConfigs]);
        queryClient.invalidateQueries([QueryKeys.fetchFeatureFlags]);
        extraOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};
