import { binocular } from 'src/Assets_DEPRECATED';
import styles from './EmptyDetail.module.scss';
import { Props } from './EmptyDetail.types';

const EmptyDetail = ({ agencyId, qualification, company, service, startDate, endDate }: Props) => (
  <div className={styles.container}>
    <img src={binocular} alt="no-result" className={styles.binocular} />
    <p className={styles.title}>Pas de résultats sur l'agence {agencyId} pour...</p>
    <p className={styles.description}>
      - la période du {startDate.format('DD/MM/YYYY')} au {endDate.format('DD/MM/YYYY')}
    </p>
    {qualification && <p className={styles.description}>- la qualification {qualification}</p>}
    {company && <p className={styles.description}>- le compte {company}</p>}
    {service && <p className={styles.description}>- le service {service}</p>}
  </div>
);

export default EmptyDetail;
