import styles from './../Context.module.scss';
import { useEffect } from 'react';
import {
  DatePickerInputDeprecated,
  ToggleSwitch,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { UserCompanyProps } from '../Context.types';
import { useController } from 'react-hook-form';
import SelectCompanyContacts from './SelectCompanyContacts/SelectCompanyContacts.component';
import moment from 'moment';
import { yesNoUnknownFromBoolean } from 'src/Utils/yesNoUnknownText';

const UserCompany = ({
  isEditable,
  companyId,
  name,
  siret,
  control,
  handleSubmit,
}: UserCompanyProps) => {
  const welcomeDate = useController({ control, name: 'welcomeDate' });
  const trainingDate = useController({ control, name: 'trainingDate' });
  const positionStudyDate = useController({ control, name: 'positionStudyDate' });
  const existingRisk = useController({ control, name: 'existingRisk' });
  const registeredRisk = useController({ control, name: 'registeredRisk' });
  const trainingEvaluation = useController({ control, name: 'trainingEvaluation' });
  const referents = useController({ control, name: 'referents' });

  useEffect(() => {
    window.addEventListener('beforeunload', handleSubmit);
    return () => {
      window.removeEventListener('beforeunload', handleSubmit);
    };
  }, [handleSubmit]);

  useEffect(() => {
    if (referents.formState.isDirty) handleSubmit()();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referents.field.value]);

  return (
    <div>
      <div className={styles.subTitle}>entreprise utilisatrice</div>
      <div className={styles.subSection} onBlur={handleSubmit()}>
        <WithLightTitle
          title="nom de l'entreprise"
          className={styles.lightTitle}
          children={<div>{name}</div>}
        />
        <WithLightTitle title="Siret" className={styles.lightTitle} children={<div>{siret}</div>} />
      </div>

      {isEditable ? (
        <div className={styles.switches}>
          existence de la liste des postes à risques
          <ToggleSwitch
            checked={existingRisk.field.value}
            onCheckStatusChange={value => existingRisk.field.onChange(value)}
          />
        </div>
      ) : (
        <WithLightTitle
          title="existence de la liste des postes à risques"
          className={styles.lightTitle}
          children={<div>{yesNoUnknownFromBoolean(existingRisk.field.value)}</div>}
        />
      )}
      {isEditable ? (
        <div className={styles.switches}>
          poste inscrit sur la liste des postes à risques
          <ToggleSwitch
            checked={registeredRisk.field.value}
            onCheckStatusChange={value => registeredRisk.field.onChange(value)}
          />
        </div>
      ) : (
        <WithLightTitle
          title="poste inscrit sur la liste des postes à risques"
          className={styles.lightTitle}
          children={<div>{yesNoUnknownFromBoolean(registeredRisk.field.value)}</div>}
        />
      )}
      <div className={styles.dateContainer}>
        <WithLightTitle
          title="date de l'accueil au poste de travail"
          className={styles.lightTitleWithFullWidth}
          children={
            isEditable ? (
              <DatePickerInputDeprecated
                date={welcomeDate.field.value}
                onSelectDate={date => welcomeDate.field.onChange(date)}
              />
            ) : (
              <div>
                {welcomeDate.field.value
                  ? moment(welcomeDate.field.value).format('DD/MM/Y')
                  : 'non renseignée'}
              </div>
            )
          }
        />
        <WithLightTitle
          title="date de la formation renforcée"
          className={styles.lightTitleWithFullWidth}
          children={
            isEditable ? (
              <DatePickerInputDeprecated
                date={trainingDate.field.value}
                onSelectDate={date => trainingDate.field.onChange(date)}
              />
            ) : (
              <div>
                {trainingDate.field.value
                  ? moment(trainingDate.field.value).format('DD/MM/Y')
                  : 'non renseignée'}
              </div>
            )
          }
        />
      </div>
      <WithLightTitle
        title="date de l'étude de poste"
        className={styles.lightTitleWithFullWidth}
        children={
          isEditable ? (
            <DatePickerInputDeprecated
              date={positionStudyDate.field.value}
              onSelectDate={date => positionStudyDate.field.onChange(date)}
            />
          ) : (
            <div>
              {positionStudyDate.field.value
                ? moment(positionStudyDate.field.value).format('DD/MM/Y')
                : 'non renseignée'}
            </div>
          )
        }
      />

      {isEditable ? (
        <div className={styles.switches}>
          une évaluation a-t-elle réalisée après les formations sécurité au poste ?
          <ToggleSwitch
            checked={trainingEvaluation.field.value}
            onCheckStatusChange={value => trainingEvaluation.field.onChange(value)}
          />
        </div>
      ) : (
        <WithLightTitle
          title="une évaluation a-t-elle réalisée après les formations sécurité au poste ?"
          className={styles.lightTitle}
          children={<div>{yesNoUnknownFromBoolean(trainingEvaluation.field.value)}</div>}
        />
      )}

      <div className={styles.subSection}>
        <SelectCompanyContacts
          isEditable={isEditable}
          control={control}
          name="referents"
          show={true}
          title="interlocuteurs présents lors de l’analyse AT"
          contactLabel="interlocuteur"
          companyId={companyId}
          companyName={name}
          handleSubmit={handleSubmit}
        />
      </div>

      <div className={styles.divider} />
    </div>
  );
};

export default UserCompany;
