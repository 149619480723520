import { useSelector } from 'react-redux';

import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { PositionstudiesService } from 'src/Services/API';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useAuthenticatedUser } from '../Authentication/useAuthenticatedUser';
import { MutationKeys } from '../types';

export const useGeneratePreventionSheet = (
  extraOptions?: UseMutationOptions<Buffer, unknown, unknown>
) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const user = useAuthenticatedUser();

  return useMutation(
    [MutationKeys.generatePreventionSheet],
    async (edpId: string) => {
      return await PositionstudiesService.positionStudiesControllerGetPreventionSheetPdf(
        { id: edpId, body: { brandCode, user } },
        { responseType: 'arraybuffer' }
      );
    },
    {
      ...extraOptions,
    }
  );
};
