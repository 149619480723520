import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../RootReducer';
import { AgencyConsultantState } from './Types';

const getAgencyConsultantState = (state: RootState) => state.agencyConsultant;

export const getSelectedAgencyConsultant = createSelector(
  [getAgencyConsultantState],
  (state: AgencyConsultantState) => state.currentAgencyConsultant
);
