import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { MutationKeys, QueryKeys } from '../types';
import { CandidatesService } from 'src/Services/API';

export const useDeleteCandidateMemo = (
  memoId: string,
  candidateId: string,
  extraOptions?: UseMutationOptions<void, unknown, void, unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.deleteCandidateMemo],
    async () => {
      await CandidatesService.candidatesControllerDeleteCandidateMemo({
        candidateId,
        memoId,
      });
    },
    {
      ...extraOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([QueryKeys.fetchCandidateMemos, candidateId]);
        extraOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};
