import {
  ServerErrorRed,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import { Button, Checkbox, Loader } from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';
import { useFetchAgenciesDetails } from 'src/Hooks/Agencies/useFetchAgenciesDetails';
import { useFetchAgenciesDistances } from 'src/Hooks/Agencies/useFetchAgenciesDistances';
import { useFetchAgenciesInfoFromCGC } from 'src/Hooks/SkillsManagementCenters/useFetchAgenciesInfoFromCGC';
import { AgencyDetails } from 'src/Services/API';
import { CGCAgencyPropositionModal } from './CGCAgencyPropositionModal';
import { AgencyCard } from './AgencyCard';
import styles from './RepositioningInternPotentialOffers.module.scss';
import { Props } from './RepositioningInternPotentialOffers.types';

export const RepositioningInternPotentialOffers = ({ candidateData }: Props) => {
  const agencies = useFetchAgenciesInfoFromCGC();
  const agenciesDetailsQuery = useFetchAgenciesDetails(
    agencies.data?.map(agency => agency.id) ?? []
  );

  const { data: agenciesDistances, isLoading } = useFetchAgenciesDistances({
    zipCode: candidateData.candidateAddress?.candidatePostalCode ?? '',
    agencies:
      agenciesDetailsQuery.data?.map((agencyDetail: AgencyDetails) => ({
        agencyId: agencyDetail.id,
        zipCode: agencyDetail.postalCode,
      })) ?? [],
  });

  const [selectedAgencies, setSelectedAgencies] = useState<AgencyDetails[]>([]);
  const [isAgencySuggestionOpen, setIsAgencySuggestionOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Checkbox
          checked={selectedAgencies.length !== 0}
          halfChecked={selectedAgencies.length > 0}
          onClick={() => {
            selectedAgencies.length
              ? setSelectedAgencies([])
              : setSelectedAgencies(agenciesDetailsQuery.data ?? []);
          }}
        />
        <Button
          size="medium"
          variant="secondary"
          disabled={selectedAgencies.length === 0}
          onClick={() => setIsAgencySuggestionOpen(true)}
        >
          {selectedAgencies.length > 1 ? 'contacter les agences' : "contacter l'agence"}
        </Button>
      </div>
      {isLoading ? (
        <div className={styles.illustration}>
          <Loader size="large" />
          chargement des clients
        </div>
      ) : !agenciesDistances ? (
        <div className={styles.illustration}>
          <ServerErrorRed />
          désolé, une erreur s'est produite
        </div>
      ) : !agenciesDistances.length ? (
        <div className={styles.illustration}>
          <VidePasteque />
          Il n'y a aucun agence correspondant aux critères
        </div>
      ) : (
        <AgencyCard
          agenciesDetails={agenciesDetailsQuery.data}
          candidateData={candidateData}
          selectedAgencies={selectedAgencies}
          setSelectedAgencies={setSelectedAgencies}
          agenciesDistances={agenciesDistances}
        />
      )}
      <CGCAgencyPropositionModal
        candidateData={candidateData}
        selectedAgencies={selectedAgencies}
        open={isAgencySuggestionOpen}
        onClose={() => setIsAgencySuggestionOpen(false)}
      />
    </div>
  );
};
