import { CandidatesService } from 'src/Services/API';
import { QueryKeys } from '../types';
import { useQuery } from '@tanstack/react-query';
import invariant from 'invariant';

export const useFetchBlacklistedCompaniesByCandidate = (candidateId?: string) => {
  return useQuery(
    [QueryKeys.fetchBlacklistedCompanies],
    async () => {
      invariant(candidateId, 'candidateId is missing');
      return CandidatesService.candidatesControllerGetBlacklistedCompaniesByCandidate({
        id: candidateId,
      });
    },
    {
      enabled: Boolean(candidateId),
    }
  );
};
