import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import invariant from 'invariant';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { CandidateCDIContract, CandidatesService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchCandidateCDIContract = (
  candidateId?: string,
  extraOptions?: UseQueryOptions<CandidateCDIContract, unknown, CandidateCDIContract, any>
) => {
  const brandCode = useSelector(getCurrentBrandCode);

  return useQuery(
    [QueryKeys.fetchCandidateCDIContracts, candidateId, brandCode],
    async () => {
      invariant(candidateId, 'candidateId is missing');
      return await CandidatesService.candidatesControllerGetCandidateCurrentContract({
        id: candidateId,
        brandCode,
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(candidateId),
      staleTime: 3600000,
      ...extraOptions,
    }
  );
};
