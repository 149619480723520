import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTypedHash, useTypedParams } from 'react-router-typesafe-routes/dom';
import { TopBar } from 'src/Components/TopBar';
import { useIsCGC } from 'src/Hooks/SkillsManagementCenters/useIsCGC';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { dashboardMenuLabel } from 'src/Routes/Talents/Cdii/Cdii.component';
import { CdiiDetailScreen } from 'src/Screens/CdiiDetail';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import styles from './CdiiDetail.module.scss';

export const CdiiDetailRoute = () => {
  const navigate = useNavigate();
  const { candidateId } = useTypedParams(ROUTES.TALENTS.CDII.CDII_DETAIL);
  const dashboardSection = useTypedHash(ROUTES.TALENTS.CDII.CDII_DETAIL);

  const { data: isCGC } = useIsCGC();

  const userInfo = useSelector(getAnalyticsUserInfo);

  return (
    <div className={styles.container}>
      <TopBar title="détails talent" goBackTo={ROUTES.TALENTS.CDII.buildPath({})} />
      <div className={styles.detail}>
        <CdiiDetailScreen candidateId={candidateId}>
          {isCGC !== undefined &&
            (isCGC ? (
              <Button
                className={styles.repositioningButton}
                onClick={() => {
                  ReactGA.event(ANALYTICS_EVENT.CLICK_SUGGEST, {
                    ...userInfo,
                  });

                  navigate(
                    ROUTES.TALENTS.CDII.CDII_DETAIL.REPOSITIONING.buildPath(
                      { candidateId },
                      {},
                      dashboardSection
                    )
                  );
                }}
              >
                suggérer
              </Button>
            ) : (
              <Button
                className={styles.repositioningButton}
                onClick={() => {
                  ReactGA.event(ANALYTICS_EVENT.CLICK_REPOSITIONING, {
                    ...userInfo,
                    toggle: dashboardSection ? dashboardMenuLabel[dashboardSection] : 'deeplink',
                  });

                  navigate(
                    ROUTES.TALENTS.CDII.CDII_DETAIL.REPOSITIONING.buildPath(
                      { candidateId },
                      {},
                      dashboardSection
                    )
                  );
                }}
              >
                repositionner
              </Button>
            ))}
        </CdiiDetailScreen>
      </div>
    </div>
  );
};
