import { Moment } from 'moment';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { QualificationsService } from 'src/Services/API';

import { QueryKeys } from '../types';
import invariant from 'invariant';

const GET_MISSIONS_SEARCH_LIMIT = 20;

export const useFetchMissionsBySkillMatchingForRepositioning = ({
  mainQualifications,
  departmentIds,
  startDate,
  endDate,
}: {
  mainQualifications?: string[];
  departmentIds?: string[];
  startDate: Moment;
  endDate: Moment;
}) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);

  return useInfiniteQuery(
    [
      QueryKeys.fetchMissionsBySkillMatchingForRepositioning,
      mainQualifications,
      departmentIds,
      startDate,
      endDate,
    ],
    ({ pageParam: offset = 0 }) => {
      invariant(mainQualifications, 'mainQualifications is missing');
      return QualificationsService.qualificationsControllerGetMissionsBySkillMatching({
        body: {
          agencyId,
          offset,
          limit: GET_MISSIONS_SEARCH_LIMIT,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          brandCode,
          mainQualifications,
          departmentIds,
          salesphases: ['AS', 'SP'],
        },
      });
    },
    {
      enabled: mainQualifications !== undefined && departmentIds !== undefined,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) =>
        lastPage.length === GET_MISSIONS_SEARCH_LIMIT
          ? (pages.length - 1) * GET_MISSIONS_SEARCH_LIMIT + lastPage.length
          : undefined,
    }
  );
};
