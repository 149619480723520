import { createMigrate } from 'redux-persist';
import { RootState } from './RootReducer';

type PersistedRootStateV2 = RootState;

type PersistedRootStateV1 = RootState;

const persistMigrations = {
  '2': (state: PersistedRootStateV1): PersistedRootStateV2 => {
    return state;
  },
};

// the current createMigrate typings do not allow different incoming and outgoing state types
export const persistMigrate = createMigrate(persistMigrations as any);

export const persistVersion = 2;
