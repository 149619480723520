import {
  ServerErrorRed,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import { Loader } from '@randstad-lean-mobile-factory/react-components-core';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useFetchCandidateQualifications } from 'src/Hooks/Candidate/useFetchCandidateQualifications';
import useFetchDepartments from 'src/Hooks/Departments/useFetchDepartments';
import { useFetchPotentialQualificationsClientsForRepositioning } from 'src/Hooks/Qualifications/useFetchPotentialQualificationsClientsForRepositioning';
import { Department } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { DepartmentsPopover } from '../DepartmentsPopover';
import { QualificationsPopover } from '../QualificationsPopover';
import { PpaClientCard } from './PpaClientCard';
import styles from './RepositioningPotentialOffers.module.scss';
import { Props } from './RepositioningPotentialOffers.types';

export const RepositioningPotentialOffers = ({ candidateData }: Props) => {
  const { inView, ref } = useInView();

  const [selectedDepartments, setSelectedDepartments] = useState<Department[]>();
  const [selectedQualifications, setSelectedQualifications] = useState<string[]>();

  const {
    data: potentialQualificationsClientsPages,
    fetchNextPage: fetchNextPageQualif,
    isFetchingNextPage: isFetchingNextPageQualif,
    isLoading,
  } = useFetchPotentialQualificationsClientsForRepositioning({
    mainQualifications: selectedQualifications,
    departmentIds: selectedDepartments?.map(dep => dep.id).filter(Boolean),
  });

  const departmentsQuery = useFetchDepartments();
  const qualificationQuery = useFetchCandidateQualifications(candidateData.id);

  useEffect(() => {
    setSelectedDepartments(
      departmentsQuery.data?.filter(
        dep => dep.id === candidateData.candidateAddress?.candidatePostalCode?.slice(0, 2)
      ) ?? undefined
    );
  }, [candidateData.candidateAddress?.candidatePostalCode, departmentsQuery.data]);
  useEffect(() => {
    setSelectedQualifications(
      qualificationQuery.data?.map(qualification => qualification.id).filter(Boolean)
    );
  }, [qualificationQuery.data]);

  const potentialQualificationsClients = useMemo(
    () => potentialQualificationsClientsPages?.pages.flat(),
    [potentialQualificationsClientsPages?.pages]
  );

  useEffect(() => {
    if (inView && !isFetchingNextPageQualif) {
      fetchNextPageQualif();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <DepartmentsPopover
          allDepartments={departmentsQuery.data ?? []}
          departmentsFetchStatus={toFetchStatus(departmentsQuery)}
          selectedDepartments={selectedDepartments ?? []}
          setSelectedDepartments={setSelectedDepartments}
        />
        <QualificationsPopover
          allQualifications={qualificationQuery.data ?? []}
          setSelectedQualifications={setSelectedQualifications}
          selectedQualifications={selectedQualifications ?? []}
        />
      </div>

      {isLoading ? (
        <div className={styles.illustration}>
          <Loader size="large" />
          chargement des clients
        </div>
      ) : !potentialQualificationsClients ? (
        <div className={styles.illustration}>
          <ServerErrorRed />
          désolé, une erreur s'est produite
        </div>
      ) : !potentialQualificationsClients.length ? (
        <div className={styles.illustration}>
          <VidePasteque />
          Il n'y a aucun poste potentiel correspondant aux critères
        </div>
      ) : (
        <div className={styles.content}>
          {potentialQualificationsClients.map(potentialQualif => (
            <PpaClientCard
              key={potentialQualif.client?.companyId}
              companyName={potentialQualif.client.companyName ?? ''}
              qualificationName={potentialQualif?.potentialQualification?.label ?? ''}
              city={potentialQualif.client?.companyAddress?.city ?? ''}
              postCode={potentialQualif.client?.companyAddress?.postalCode ?? ''}
              contact={potentialQualif.client?.contact}
            />
          ))}
          <div ref={ref} />
        </div>
      )}
    </div>
  );
};
