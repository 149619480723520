import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { GetWorkAccidents, WorkAccidentAnalysisService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchWorkAccidents = (
  extraOptions?: Omit<
    UseQueryOptions<GetWorkAccidents, unknown, GetWorkAccidents, any>,
    'queryKey' | 'queryFn'
  >
) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency);

  return useQuery(
    [QueryKeys.fetchWorkAccidents],
    async () => {
      return WorkAccidentAnalysisService.workAccidentAnalysisControllerGetWorkAccidents({
        agencies: [agencyId],
        brandCode,
      });
    },
    { ...extraOptions }
  );
};
