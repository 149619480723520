import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import invariant from 'invariant';

import { Candidate, CandidatesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCandidateDetails = (
  candidateId?: string,
  extraOptions?: UseQueryOptions<Candidate, unknown, Candidate, any>
) => {
  return useQuery(
    [QueryKeys.fetchCandidateDetails, candidateId],
    async () => {
      invariant(candidateId, 'candidateId is missing');
      const candidate = await CandidatesService.candidatesControllerGetCandidate({
        id: candidateId,
      });
      return candidate;
    },
    {
      enabled: Boolean(candidateId),
      staleTime: Infinity,
      ...extraOptions,
    }
  );
};
