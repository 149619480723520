import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { CandidateHabilitation, CandidatesService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchCandidateHabilitations = (
  candidateId: string,
  extraOptions?: UseQueryOptions<CandidateHabilitation[], unknown, CandidateHabilitation[], any>
) => {
  return useQuery(
    [QueryKeys.fetchCandidateHabilitations, candidateId],
    async () => {
      return await CandidatesService.candidatesControllerGetCandidateHabilitations({
        id: candidateId,
      });
    },
    {
      staleTime: Infinity,
      ...extraOptions,
    }
  );
};
