import { useEffect, useMemo, useState } from 'react';

import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';
import throttle from 'lodash.throttle';
import { useFetchQualifications } from 'src/Hooks/Qualifications/useFetchQualifications';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/Async';
import { Props } from './SelectMultipleQualifications.types';
import { useFetchQualificationsDetails } from 'src/Hooks/Qualifications/useFetchQualificationsDetails';
import { Qualification } from 'src/Services/API';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';

const SelectMultipleQualification = ({
  selectedQualifications,
  setSelectedQualifications,
  canBeReset,
}: Props) => {
  const [keyword, setKeyword] = useState('');
  const throttledSetKeyword = useMemo(() => throttle(setKeyword, 500), [setKeyword]);
  const brandCode = useSelector(getCurrentBrandCode);
  const { data, isSuccess, isError, isLoading } = useFetchQualifications({
    keyword,
    brandCode,
  });

  const [selectedData, setSelectedData] = useState<Qualification[]>([]);
  const { data: newSelectedData } = useFetchQualificationsDetails(selectedQualifications);
  useEffect(() => {
    if (newSelectedData) setSelectedData(newSelectedData);
  }, [newSelectedData]);

  const filteredData = useMemo(() => {
    return (
      data?.filter(qualification => !selectedQualifications?.includes(qualification.id!)) ?? []
    );
  }, [data, selectedQualifications]);

  return (
    <ComboBox
      id="SelectQualifications"
      disableClearable={!canBeReset}
      value={selectedData}
      searchResults={filteredData}
      onSearch={keyword => throttledSetKeyword(keyword)}
      keyValueExtractor={({ id, label }) => ({
        key: id ?? 'unknown',
        value: label ?? 'Qualification inconnue',
      })}
      onChange={qualifications => {
        if (qualifications && qualifications.length < 11) {
          setSelectedQualifications(qualifications.map(q => q.id!));
          setSelectedData(qualifications);
        }
      }}
      multiple
      showSelected
      fetchStatus={
        keyword.length > 0
          ? toFetchStatus({ isError, isLoading, isSuccess })
          : FETCH_STATUS.FULFILLED
      }
      minLengthToSearch={1}
    />
  );
};

export default SelectMultipleQualification;
