export enum ANALYTICS_EVENT {
  ACTION_CREATION = 'creation-action',
  LOGIN = 'login',
  VARIABLE_SIMULATOR_SIMULATE = 'variable-simulator-simulate',
  CONTRACT_EXTENSION = 'contract_extension',
  YOUPLAN_CONTRACT_EXTENSION = 'youplan_contract_extension',
  COMMAND_CREATION = 'command_creation',
  YOUPLAN_COMMAND_CREATION = 'youplan_command_creation',
  REPOSITIONING_COMMAND_CREATION = 'repositioning_command_creation',
  YOUPLAN_MARK_AS_DONE = 'youplan_mark_as_done',
  COUNT_ORDER_SUGGEST = 'count_order_suggest',
  CLICK_ORDER_SUGGEST = 'click_order_suggest',
  CLICK_SUGGEST = 'click_suggest',
  CLICK_REPOSITIONING = 'click_repositioning',
  CLICK_TOGGLE_REPOSITIONING = 'click_toggle_repositioning',
  CLICK_CDII_SCREEN = 'click_cdii_screen',
  CLICK_SOS = 'click_sos',
  CLICK_SOS_CONFIRMATION = 'click_sos_confirmation',
  COUNT_ORDER_RATTACHEMENT = 'count_order_rattachement',
  CLICK_ORDER_SUGGEST_CONFIRMATION = 'click_order_suggest_confirmation',
  CLICK_ORDER_SUGGEST_REFUSAL = 'click_order_suggest_refusal',
  FINALISE_WORK_ACCIDENT_ANALYSIS = 'finalise_work_accident_analysis',
  CREATE_WORK_ACCIDENT_ANALYSIS = 'create_work_accident_analysis',
  CLICK_TOGGLE_FROM_MY_REGION = 'click_toggle_from_my_region',
  CLICK_TOGGLE_REPOSITIONED = 'click_toggle_repositioned',
  FROM_MY_REGION_COMMAND_CREATION = 'from_my_region_command_creation',
  COMMAND_RATTACHEMENT_FROM_MY_REGION_DURING_CREATION = 'command_rattachement_from_my_region_during_creation',
  MEMO_CREATION = 'memo_creation',
  DISCIPLINARY_MEMO_CREATION = 'disciplinary_memo_creation',
  PPA_INTERN_SUGGESTION = 'ppa_intern_suggestion',
  CLICK_TALENT_SCREEN = 'click_talent_screen',
  CLICK_TOGGLE_MYTT = 'click_toggle_myTT',
  CLICK_TOGGLE_TTMYREGION = 'click_toggle_TTmyregion',
  CLICK_TT_REPOSITIONING = 'click_TTrepositioning',
  COUNT_ORDER_TT_RATTACHEMENT = 'count_order_TTrattachement',
}
