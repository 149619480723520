import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './ContractDetail.module.scss';
import ContactCard from 'src/Components/ContactCard';
import CandidateCard from 'src/Components/CandidateCard';
import { FETCH_STATUS } from 'src/Redux/Types';
import EmptyDetail from './EmptyDetail';
import LastCompanyAction from 'src/Components/LastCompanyAction';
import PopOverActions from 'src/Components/PopOverActions';
import { isCDIWarning } from 'src/Services/Contracts';
import moment from 'moment';
import { isRenewalEndorsement } from 'src/Utils/contracts';
import {
  BadgeDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { clock } from 'src/Assets_DEPRECATED';
import { useSelector } from 'react-redux';
import {
  getCurrentContract,
  getCurrentContractFetchStatus,
  getContractsFetchStatus,
} from 'src/Redux/Contracts/Selectors';
import { mergeFetchStatus } from 'src/Utils/fetch-status';
import { useFetchCandidateCDIContract } from 'src/Hooks/Candidate/useFetchCandidateCDIContracts';

function formatDateRanges(startDate?: moment.Moment, endDate?: moment.Moment) {
  return `du ${startDate?.format('DD/MM/Y') ?? '--/--/--'} au ${
    endDate?.format('DD/MM/Y') ?? '--/--/--'
  }`;
}

const LoadingContractDetail = () => (
  <div className={styles.container} style={{ marginTop: '1rem' }}>
    <ContentLoader uniqueKey="loadingContractDetail" height="20rem" width="100%">
      {[
        { width: '30%', height: '2rem', y: '0' },
        { width: '15%', height: '0.65rem', y: '3.5rem' },
        { width: '30%', height: '0.75rem', y: '4.65rem' },
        { width: '15%', height: '0.65rem', x: '85%', y: '3.5rem' },
        { width: '30%', height: '0.75rem', x: '70%', y: '4.65rem' },
        { width: '30%', height: '0.65rem', y: '6.4rem' },
        { width: '100%', height: '3rem', y: '7.55rem' },
        { width: '30%', height: '2rem', y: '13.05rem' },
        { width: '10%', height: '0.65rem', y: '16.05rem' },
        { width: '100%', height: '3rem', y: '17.2rem' },
      ].map((props, idx) => (
        <rect key={idx} x="0" rx="4" ry="4" {...props} />
      ))}
    </ContentLoader>
  </div>
);

const ContractDetail = () => {
  const contract = useSelector(getCurrentContract);
  const currentContractFetchStatus = useSelector(getCurrentContractFetchStatus);
  const contractsFetchStatus = useSelector(getContractsFetchStatus);
  const cdiContractQuery = useFetchCandidateCDIContract(contract?.candidate?.candidateId);
  const cdiContract = cdiContractQuery.data;
  const isStillCDI =
    !cdiContract?.endDate ||
    (cdiContract?.endDate && moment(cdiContract?.endDate).isAfter(moment()));
  const fetchStatus = mergeFetchStatus(currentContractFetchStatus, contractsFetchStatus);
  const daysLeft = moment(contract?.effectiveEndDate).diff(moment(), 'days');
  if (fetchStatus === FETCH_STATUS.PENDING) {
    return <LoadingContractDetail />;
  }
  if (contract === null) {
    return <EmptyDetail />;
  }
  const latestEndorsement = contract.endorsements?.[contract.endorsements.length - 1];
  const renewalContractCount = contract.endorsements?.filter(endorsement =>
    isRenewalEndorsement(endorsement.modalityType?.id ?? '')
  ).length;
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.sectionTitle}>
          <p>{contract.company?.name}</p>
        </div>
        <p className={styles.siret}>{`num SIRET : ${contract.company?.siret}`}</p>

        <div className={styles.spaceBetweenLine} style={{ marginBottom: '1rem' }}>
          <div className={styles.leftSubtitle}>
            <WithLightTitle
              title={formatDateRanges(contract.effectiveStartDate, contract.effectiveEndDate)}
              children={`service: ${contract.service?.name || 'aucun service renseigné'}`}
            />
            {isCDIWarning(daysLeft, contract.isDerogatoryMissionLetter) && isStillCDI ? (
              <BadgeDeprecated
                value={`J-${daysLeft}`}
                leftIcon={<img src={clock} alt="clock" />}
                className={styles.timeGellule}
              />
            ) : null}
          </div>
          <LastCompanyAction contactingInfo={contract.lastClientContact} />
        </div>
        <div
          className={styles.spaceBetweenLine}
          style={{ display: 'flex', flexDirection: 'row', alignContent: 'stretch' }}
        >
          <p className={styles.subtitle}>contact du compte</p>
          <p className={styles.subtitle}>poste client</p>
          <p className={styles.subtitle}>dernière action client</p>
          <p className={styles.subtitle} />
        </div>
        <ContactCard contact={contract.contact} />
      </div>
      <div style={{ height: '2rem' }} />
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <p className={styles.sectionTitle}>
            {contract.qualification?.label}{' '}
            <span className={styles.sectionSubtitle}>/ en délégation</span>
          </p>
          <PopOverActions />
        </div>
        <div className={styles.spaceBetweenLine}>
          <p className={styles.subtitle}>nom intérimaire</p>
          <p className={styles.subtitle}>dernière action TT</p>
          <p style={{ width: '33%' }} />
        </div>
        <div className={styles.separator} />
        <CandidateCard
          candidate={contract.candidate}
          hideAcceptance
          cdiContractId={isStillCDI ? cdiContract?.contractId : undefined}
        />
        <div className={styles.separator} />
      </div>
      <div className={styles.latestEndorsementContainer}>
        <div style={{ flex: 1 }}>
          <WithLightTitle
            title="motif de recours"
            children={
              contract.missionRequestReason
                ? `${contract.missionRequestReason?.id} - ${contract.missionRequestReason?.label}`
                : 'Aucun motif de recours renseigné'
            }
          />
        </div>
        <div style={{ flex: 1 }}>
          {renewalContractCount && renewalContractCount > 0 ? (
            <WithLightTitle
              title="nombre de renouvellement"
              children={`${renewalContractCount.toString()}e${
                renewalContractCount === 1 ? 'r' : ''
              } renouvellement`}
            />
          ) : undefined}
        </div>
      </div>
      <div style={{ height: '1rem' }} />
      <div className={styles.latestEndorsementContainer}>
        <div style={{ flex: 1 }}>
          <WithLightTitle title="type de modalité">
            {latestEndorsement
              ? `${latestEndorsement.modalityType?.id} - ${latestEndorsement.modalityType?.label}`
              : 'Contrat initial'}
          </WithLightTitle>
        </div>
        <div style={{ flex: 1 }}>
          {latestEndorsement && (
            <WithLightTitle title="date d'effet">
              {formatDateRanges(
                moment(latestEndorsement.startDate),
                moment(latestEndorsement.endDate)
              )}
            </WithLightTitle>
          )}
        </div>
      </div>
      <div style={{ height: '1rem' }} />
      <WithLightTitle title="justificatif" className={styles.justifyContainer}>
        {contract.missionRequestReason?.receipt ?? 'Aucun justificatif renseigné'}
      </WithLightTitle>
    </div>
  );
};

export default ContractDetail;
