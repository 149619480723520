import { useState } from 'react';

import { SortDescending } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  AscendingOrder,
  DescendingOrder,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useSelector } from 'react-redux';
import { getCDISortOrder } from 'src/Redux/CDICandidates/Selectors';
import { CDISortBy, SortOrder } from 'src/Redux/CDICandidates/Types';
import { getRepositioningMissionsSortBy } from 'src/Redux/Missions/Selectors';
import { RepositioningMissionsSortBy } from 'src/Redux/Missions/Types';
import FiltersMenuContent from './FiltersMenuContent/FiltersMenuContent.component';

export const sortingChoicesCDII = [
  {
    mode: CDISortBy.AGENCY,
    name: 'agence',
  },
  {
    mode: CDISortBy.CONTRACT_END_DATE,
    name: 'date',
  },
  {
    mode: CDISortBy.STATUT,
    name: 'statut',
  },
  {
    mode: CDISortBy.NEXT_AVAILABILITY,
    name: 'prochaine dispo',
  },
];

export const sortingChoicesMission = [
  {
    mode: RepositioningMissionsSortBy.DISTANCE,
    name: 'distance',
  },
  {
    mode: RepositioningMissionsSortBy.NB_POSITIONS,
    name: 'nombre de postes',
  },
  {
    mode: RepositioningMissionsSortBy.START_DATE,
    name: 'date',
  },
  {
    mode: RepositioningMissionsSortBy.STATUT,
    name: 'statut',
  },
];

export const sortOrderChoices = [
  {
    mode: SortOrder.ASC,
    name: 'croissant',
    icon: <AscendingOrder />,
  },
  {
    mode: SortOrder.DESC,
    name: 'décroissant',
    icon: <DescendingOrder />,
  },
];

/** @deprecated stop storing the filters in redux */
export const FiltersMenu = () => {
  const sortByMission = useSelector(getRepositioningMissionsSortBy);
  const sortOrderCDI = useSelector(getCDISortOrder);

  const [localSortBy, setLocalSortBy] = useState(sortByMission);
  const [localSortOrder, setLocalSortOrder] = useState(sortOrderCDI);

  return (
    <PopupMenu
      position="bottom left"
      contentStyle={{ width: '15.25rem' }}
      trigger={
        <Button variant="tertiary" size="medium">
          <SortDescending variant="line" color="blue" />
        </Button>
      }
      selectedIds={[
        sortingChoicesMission.find(choice => choice.mode === localSortBy)?.name ?? '',
        sortOrderChoices.find(dpm => dpm.mode === localSortOrder)?.name ?? '',
      ]}
    >
      <FiltersMenuContent
        localSortBy={localSortBy}
        localSortOrder={localSortOrder}
        setLocalSortOrder={setLocalSortOrder}
        setLocalSortBy={setLocalSortBy}
      />
    </PopupMenu>
  );
};
