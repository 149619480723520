import { SortDown, SortUp } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Menu, MenuItem } from '@randstad-lean-mobile-factory/react-components-core';
import { SortTypeEnum, FetchTemporaryWorkersSortCriteria } from 'src/Services/API';
import { TemporaryWorkersSource } from '../../TemporaryWorkers.types';
import styles from './TemporaryWorkersSortingMenu.module.scss';
import { TemporaryWorkersSortingMenuProps } from './TemporaryWorkersSortingMenu.types';

export const TemporaryWorkersSortingMenu = ({
  selectedSource,
  trigger,
  agencySort,
  setAgencySort,
  regionalSort,
  setRegionalSort,
}: TemporaryWorkersSortingMenuProps) => (
  <Menu
    trigger={trigger}
    anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
  >
    <MenuItem
      selected={
        selectedSource === TemporaryWorkersSource.agency
          ? agencySort.sortType === SortTypeEnum.ASC
          : regionalSort.sortType === SortTypeEnum.ASC
      }
      onClick={() => {
        if (selectedSource === TemporaryWorkersSource.agency)
          setAgencySort(sort => ({ ...sort, sortType: SortTypeEnum.ASC }));
        else setRegionalSort(sort => ({ ...sort, sortType: SortTypeEnum.ASC }));
      }}
      keepOpenOnClick
    >
      <SortUp size="xsmall" />
      ascendant
    </MenuItem>
    <MenuItem
      selected={
        selectedSource === TemporaryWorkersSource.agency
          ? agencySort.sortType === SortTypeEnum.DESC
          : regionalSort.sortType === SortTypeEnum.DESC
      }
      onClick={() => {
        if (selectedSource === TemporaryWorkersSource.agency)
          setAgencySort(sort => ({ ...sort, sortType: SortTypeEnum.DESC }));
        else setRegionalSort(sort => ({ ...sort, sortType: SortTypeEnum.DESC }));
      }}
      keepOpenOnClick
    >
      <SortDown size="xsmall" />
      descendant
    </MenuItem>

    <div className={styles.menuGroup}>trier par</div>

    {selectedSource === TemporaryWorkersSource.agency ? (
      <>
        <MenuItem
          selected={agencySort.sortCriteria === FetchTemporaryWorkersSortCriteria.ZipCode}
          onClick={() => {
            setAgencySort(sort => ({
              ...sort,
              sortCriteria: FetchTemporaryWorkersSortCriteria.ZipCode,
            }));
          }}
          keepOpenOnClick
        >
          lieu de résidence
        </MenuItem>
        <MenuItem
          selected={
            agencySort.sortCriteria ===
            FetchTemporaryWorkersSortCriteria.AvailabilityForConsultantDate
          }
          onClick={() => {
            setAgencySort(sort => ({
              ...sort,
              sortCriteria: FetchTemporaryWorkersSortCriteria.AvailabilityForConsultantDate,
            }));
          }}
          keepOpenOnClick
        >
          prochaine dispo
        </MenuItem>
      </>
    ) : (
      <>
        <MenuItem
          selected={regionalSort.sortCriteria === FetchTemporaryWorkersSortCriteria.MainAgency}
          onClick={() =>
            setRegionalSort(sort => ({
              ...sort,
              sortCriteria: FetchTemporaryWorkersSortCriteria.MainAgency,
            }))
          }
          keepOpenOnClick
        >
          agence
        </MenuItem>
        <MenuItem
          selected={regionalSort.sortCriteria === FetchTemporaryWorkersSortCriteria.ZipCode}
          onClick={() =>
            setRegionalSort(sort => ({
              ...sort,
              sortCriteria: FetchTemporaryWorkersSortCriteria.ZipCode,
            }))
          }
          keepOpenOnClick
        >
          lieu de résidence
        </MenuItem>
        <MenuItem
          selected={
            regionalSort.sortCriteria ===
            FetchTemporaryWorkersSortCriteria.AvailabilityForConsultantDate
          }
          onClick={() =>
            setRegionalSort(sort => ({
              ...sort,
              sortCriteria: FetchTemporaryWorkersSortCriteria.AvailabilityForConsultantDate,
            }))
          }
          keepOpenOnClick
        >
          prochaine dispo
        </MenuItem>
      </>
    )}
  </Menu>
);
