export enum MutationKeys {
  updateCandidateStatus = 'updateCandidateStatus',
  fetchMissionDetailsFromEDP = 'fetchMissionDetailsFromEDP',
  addCandidateToMissions = 'addCandidateToMissions',
  updateAction = 'updateAction',
  createContractAction = 'createContractAction',
  finaliseWorkAccidentAnalysis = 'finaliseWorkAccidentAnalysis',
  createMissionAction = 'createMissionAction',
  createWorkAccidentAnalysis = 'createWorkAccidentAnalysis',
  updateWorkAccidentAnalysis = 'updateWorkAccidentAnalysis',
  fetchWorkAccidentFileData = 'fetchWorkAccidentFileData',
  fetchCities = 'fetchCities',
  uploadWorkAccidentFiles = 'uploadWorkAccidentFiles',
  deleteWorkAccidentFiles = 'deleteWorkAccidentFiles',
  generateATPdf = 'generateATPdf',
  generatePreventionSheet = 'generatePreventionSheet',
  closeATActionPlan = 'closeATActionPlan',
  sendToCGC = 'sendToCGC',
  updateWorkAccidentAction = 'updateWorkAccidentAction',
  sendSuggestionToAgencies = 'sendSuggestionToAgencies',
  refuseSuggestedCCdii = 'refuseSuggestedCCdii',
  changeManuallyRepoStatus = 'changeManuallyRepoStatus',
  suggestCandidateToAgencies = 'suggestCandidateToAgencies',
  openOSMResume = 'openOSMResume',
  createCandidateMemo = 'createCandidateMemo',
  archiveWorkAccidentAnalysis = 'archiveWorkAccidentAnalysis',
  deleteCandidateMemo = 'deleteCandidateMemo',
  editCandidateMemo = 'editCandidateMemo',
  replayExternalDemands = 'replayExternalDemands',
  updateMailParameters = 'updateMailParameters',
  updateExternalDemands = 'updateExternalDemands',
  exportExternalDemands = 'exportExternalDemands',
  fetchAgencyBrandCode = 'fetchAgencyBrandCode',
  createExternalDemandsJournal = 'createExternalDemandsJournal',
  updateSubscription = 'updateSubscription',
  addBlacklistedCompany = 'addBlacklistedCompany',
  updateWorkAccidentAnalysisConclusionSignature = 'updateWorkAccidentAnalysisConclusionSignature',
}

export enum QueryKeys {
  fetchCandidateLastMedicalVisit = 'fetchCandidateLastMedicalVisit',
  fetchWorkAccidentById = 'fetchWorkAccidentById',
  fetchWorkAccidentsByAgencies = 'fetchWorkAccidentsByAgencies',
  fetchWorkAccidentActionPlanById = 'fetchWorkAccidentActionPlanById',
  fetchWorkAccidents = 'fetchWorkAccidents',
  fetchWorkAccidentActionPlans = 'fetchWorkAccidentActionPlans',
  fetchAccidentsUnderMinStoppageDuration = 'fetchAccidentsUnderMinStoppageDuration',
  fetchSIID = 'fetchSIID',
  fetchCompanies = 'fetchCompanies',
  fetchQualifications = 'fetchQualifications',
  fetchDomains = 'fetchDomains',
  fetchJobQualifications = 'fetchJobQualifications',
  fetchPerimeter = 'fetchPerimeter',
  fetchCompanyContacts = 'fetchCompanyContacts',
  fetchAgencyConsultants = 'fetchAgencyConsultants',
  fetchYouplanMissions = 'fetchYouplanMissions',
  fetchContractDetail = 'fetchContractDetail',
  fetchCurrentContractOfCandidate = 'fetchCurrentContractOfCandidate',
  fetchYouplanMissionDetail = 'fetchYouplanMissionDetail',
  fetchCandidateCDIContracts = 'fetchCandidateCDIContracts',
  fetchCandidateLastContractEndDate = 'fetchCandidateLastContractEndDate',
  fetchCandidateTesting = 'fetchCandidateTesting',
  fetchConsultantDetails = 'fetchConsultantDetails',
  fetchMissionDetails = 'fetchMissionDetails',
  fetchMissionsDetails = 'fetchMissionsDetails',
  fetchMissionsCompanies = 'fetchMissionsCompanies',
  fetchCandidatesPlanning = 'fetchCandidatesPlanning',
  fetchCandidateDetails = 'fetchCandidateDetails',
  fetchMissionsForRepositioning = 'fetchMissionsForRepositioning',
  fetchPotentialQualificationsClientsForRepositioning = 'fetchPotentialQualificationsClientsForRepositioning',
  fetchMissionsBySkillMatchingForRepositioning = 'fetchMissionsBySkillMatchingForRepositioning',
  fetchCandidateQualifications = 'fetchCandidateQualifications',
  fetchDepartments = 'fetchDepartments',
  fetchActionDetail = 'fetchActionDetail',
  fetchWorkAccidentFiles = 'fetchWorkAccidentFiles',
  fetchRisks = 'fetchRisks',
  fetchInjuryNature = 'fetchInjuryNature',
  fetchInjuryLocation = 'fetchInjuryLocation',
  fetchInjuryLocationDetails = 'fetchInjuryLocationDetails',
  fetchProtectionEquipments = 'fetchProtectionEquipments',
  fetchPositionStudiesCount = 'fetchPositionStudiesCount',
  fetchFunctionCategories = 'fetchFunctionCategories',
  fetchCandidatesActivityPlanning = 'fetchCandidatesActivityPlanning',
  fetchCandidateActivityPlanning = 'fetchCandidateActivityPlanning',
  fetchCandidateFirstContract = 'fetchCandidateFirstContract',
  fetchRepositioningMissions = 'fetchRepositioningMissions',
  fetchCGC = 'fetchCGC',
  fetchRelatedCGC = 'fetchRelatedCGC',
  isCGC = 'isCGC',
  fetchCgcId = 'fetchCgcId',
  fetchAgenciesInfoFromCGC = 'fetchAgenciesInfoFromCGC',
  fetchAgenciesDetails = 'fetchAgenciesDetails',
  fetchAgenciesDistances = 'fetchAgenciesDistances',
  fetchCandidateCurrentCDIContract = 'fetchCandidateCurrentCDIContract',
  fetchCompanyActions = 'fetchCompanyActions',
  fetchCandidateHabilitations = 'fetchCandidateHabilitations',
  fetchCandidateMemos = 'fetchCandidateMemos',
  fetchCandidateContracts = 'fetchCandidateContracts',
  fetchSuggestedCandidates = 'fetchSuggestedCandidates',
  fetchExternalDemands = 'fetchExternalDemands',
  fetchMailParametersBySiret = 'fetchMailParametersBySiret',
  fetchAgencyTalents = 'fetchAgencyTalents',
  fetchCompanyServices = 'fetchCompanyServices',
  fetchCompanyBySiret = 'fetchCompanyBySiret',
  fetchTalentDetails = 'fetchTalentDetails',
  fetchAgencyBrandCode = 'fetchAgencyBrandCode',
  fetchRegionalTemporaryWorkers = 'fetchRegionalTemporaryWorkers',
  fetchMissions = 'fetchMissions',
  fetchMissionsCandidate = 'fetchMissionsCandidate',
  fetchAgencyCandidates = 'fetchAgencyCandidates',
  fetchQualificationsDetails = 'fetchQualificationsDetails',
  fetchAgenciesByCandidate = 'fetchAgenciesByCandidate',
  fetchUniqueColumnValues = 'fetchUniqueColumnValues',
  fetchUniqueSirets = 'fetchUniqueSirets',
  countExternalDemands = 'countExternalDemands',
  fetchBlacklistedCompanies = 'fetchBlacklistedCompanies',
  fetchFeatureFlagConfigs = 'fetchFeatureFlagConfigs',
  fetchEstablishments = 'fetchEstablishments',
  isAdmin = 'isAdmin',
  fetchFeatureFlags = 'fetchFeatureFlags',
}
