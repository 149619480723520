import classnames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { eye } from 'src/Assets_DEPRECATED';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { EnumCreateActionBodyActionTarget } from 'src/Services/API';
import { formatName } from 'src/Utils/formatName';
import PopOverContact from '../PopOverContact';
import styles from './ContactCard.module.scss';
import { Props } from './ContactCard.types';

const ContactCard = ({ contact }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <p className={styles.fullName}>{formatName(contact)}</p>
      </div>
      <div className={styles.item}>
        <p className={styles.subtitle}>{contact?.position}</p>
      </div>
      <div className={styles.item}>
        <div className={styles.lastContact}>
          <span className={styles.subtitle}>
            {contact?.lastContact?.at.format('[le] DD/MM/Y à HH[h]mm') ?? '--'}
          </span>
          {contact?.lastContact && (
            <button
              className={styles.lastCRButton}
              onClick={() => {
                navigate(MODAL_ROUTES.ACTION_PREVIEW_MODAL.route.buildPath({}), {
                  state: {
                    modalParameters: {
                      target: {
                        firstName: contact.firstName,
                        lastName: contact.name,
                      },
                      actionId: contact.lastContact?.actionId,
                    },
                    background: location,
                  },
                });
              }}
            >
              <img src={eye} alt="eye" />
              <span className={styles.lastCRButtonText}>dernier CR</span>
            </button>
          )}
        </div>
      </div>
      {contact && (
        <div className={classnames(styles.item, styles.contactButton)}>
          <PopOverContact
            target={EnumCreateActionBodyActionTarget.CONTACT}
            contact={contact}
            enableEditMissionStatus={false}
          />
        </div>
      )}
    </div>
  );
};

export default ContactCard;
