import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import invariant from 'invariant';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';

import { CandidatesService, TemporaryWorkerDetail } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchTemporaryWorkerDetail = (
  candidateId?: string,
  extraOptions?: UseQueryOptions<
    TemporaryWorkerDetail,
    unknown,
    TemporaryWorkerDetail,
    (string | undefined)[]
  >
) => {
  const brandCode = useSelector(getCurrentBrandCode);

  return useQuery(
    [QueryKeys.fetchCandidateDetails, candidateId],
    async () => {
      invariant(candidateId, 'candidateId is missing');
      return await CandidatesService.candidatesControllerGetTemporaryWorkerDetail({
        id: candidateId,
        brandCode: brandCode,
      });
    },
    {
      enabled: Boolean(candidateId),
      ...extraOptions,
    }
  );
};
