import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AppelMedicalEventSourceDto, ExternalDemandsService } from 'src/Services/API';
import { MutationKeys } from '../types';
import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import {
  ExternalDemandElement,
  isDemandEvent,
} from 'src/Routes/ExternalDemands/DemandsList/ExternalDemandsList/ExternalDemandsList.types';

export const useReplayExternalDemands = (
  source: AppelMedicalEventSourceDto,
  extraOptions?: UseMutationOptions<void, unknown, ExternalDemandElement, unknown>
) => {
  const agencyId = useSelector(getCurrentAgency);

  return useMutation(
    [MutationKeys.replayExternalDemands],
    async element => {
      if (isDemandEvent(element)) {
        if (!(element.parent.service && element.parent.qualification && element.parent.domain)) {
          toast.warning('veuillez vérifier les correpondances avant de relancer cette demande');
          throw new Error();
        }
        return await ExternalDemandsService.externalDemandsControllerReplayCommandsError({
          body: { source, sourceIds: [element.sourceId], agencyId },
        });
      } else {
        if (!(element.service && element.qualification && element.domain)) {
          toast.warning('veuillez vérifier les correpondances avant de relancer cette demande');
          throw new Error();
        }
        return await ExternalDemandsService.externalDemandsControllerReplayCommandsError({
          body: {
            source,
            sourceIds: Array.from(new Set(element.events.map(item => item.sourceId))),
            agencyId,
          },
        });
      }
    },
    {
      ...extraOptions,
      onSuccess: () => {
        toast.success(
          "la modification du roulement a bien été enregistrée, elle peut prendre jusqu'à 30 minutes à être prise en compte"
        );
      },
    }
  );
};
