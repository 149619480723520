import {
  Button,
  DatePickerInput,
  ModalContent,
  ToggleMenu,
  ToggleMenuItem,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';
import { BrandDetailsProps } from './BrandDetails.types';

export const BrandDetails = ({ item: brand, close }: BrandDetailsProps) => {
  const [localActivationDateEnabled, setLocalActivationDateEnabled] = useState(
    Boolean(brand.activationDate)
  );
  const [localActivationDate, setLocalActivationDate] = useState(brand.activationDate);

  return (
    <ModalContent title={`${brand.brandTitle} (${brand.brandCode})`}>
      <WithLightTitle title="date d'activation">
        <ToggleMenu
          value={localActivationDateEnabled.toString()}
          onChange={value => setLocalActivationDateEnabled(value === 'true')}
        >
          <ToggleMenuItem value={'false'}>activer maintenant</ToggleMenuItem>
          <ToggleMenuItem value={'true'}>activer dans le futur</ToggleMenuItem>
        </ToggleMenu>
      </WithLightTitle>
      <DatePickerInput
        disabled={!localActivationDateEnabled}
        selected={localActivationDate}
        onChange={setLocalActivationDate}
        placeholderText="date d'activation"
        showTimeSelect
        minDate={new Date()}
      />
      <Button
        size="medium"
        onClick={() => {
          brand.setActivationDate(localActivationDateEnabled ? localActivationDate : undefined);
          close();
        }}
      >
        valider
      </Button>
    </ModalContent>
  );
};
