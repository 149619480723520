import { CDICandidatesState, CDISortBy, SortOrder } from './Types';
import { startOfWeek } from 'date-fns';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
const initialState: CDICandidatesState = {
  planningStartDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
  sortBy: CDISortBy.STATUT,
  sortOrder: SortOrder.ASC,
  currentCandidate: undefined,
};

export const { reducer: CDICandidatesReducer, actions: CDICandidatesActions } = createSlice({
  name: 'CDICandidates',
  initialState,
  reducers: {
    setCurrentCandidate: (state, action: PayloadAction<CDICandidatesState['currentCandidate']>) => {
      state.currentCandidate = action.payload;
    },
    setPlanningStartDate: (state, action: PayloadAction<Date>) => {
      state.planningStartDate = action.payload;
    },
    setCDISortBy: (state, action: PayloadAction<CDISortBy>) => {
      state.sortBy = action.payload;
    },
    setCDISortOrder: (state, action: PayloadAction<SortOrder>) => {
      state.sortOrder = action.payload;
    },
    reset: state => {
      state.planningStartDate = startOfWeek(new Date(), { weekStartsOn: 1 });
    },
  },
});
